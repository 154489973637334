<template>
  <Head :title="__('free-action.title')" />

  <Breadcrumbs :current-page="__('free-action.title')" />

  <section class="container mb-6 md:mb-12">
    <h1 class="text-3xl font-bold text-center mb-6">
      {{ __('free-action.title') }}
    </h1>

    <div class="flex flex-col items-center">
      <template v-if="availableFreeActions.length > 0">
        <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <FreeActionBlock
            v-for="freeAction in availableFreeActions"
            :key="`free-action-${freeAction.id}`"
            :free-action="freeAction"
          />
        </div>
      </template>
      <template v-else>
        <div class="mb-12">
          <p class="font-medium">
            <i>{{ __('free-action.no-free-actions') }}</i>
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import FreeActionBlock from '../../Components/FreeActionBlock.vue';

export default {
  components: {
    FreeActionBlock,
  },

  props: {
    availableFreeActions: {
      type: Array,
      required: true,
    },
  },
};
</script>
