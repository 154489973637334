<template>
  <Head :title="__('payout.successful.title')" />

  <div class="container">
    <Breadcrumbs
      :pages="[
        { name: __('balance.title'), href: route('frontend.balance') },
        { name: __('payout.overview.title'), href: route('frontend.payout') },
      ]"

      :current-page="__('payout.successful.title')"
    />

    <div class="flex flex-wrap md:bg-white md:p-4 md:pt-2 lg:p-8 lg:pt-4 rounded-br-md rounded-bl-md mb-10">
      <section class="md:pl-6 w-full md:w-3/4 mb-6">
        <h1 class="font-bold font-serif mb-4 mt-4 text-center md:text-3xl md:text-left">
          {{ __('payout.successful.title') }}
        </h1>

        <div class="bg-white shadow-box rounded p-3 mb-2 md:mb-0  flex flex-wrap">
          {{ __('payout.successful.text') }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
};
</script>
