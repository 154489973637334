<template>
  <Head :title="category ? category.name : __('webshop-category.index.title')" />

  <Breadcrumbs
    :current-page="category ? category.name : __('webshop-category.index.title')"
  />

  <section class="container mb-4">
    <div class="container flex flex-col items-center">
      <h1 class="text-2xl md:text-3xl font-bold">
        {{ category ? category.name : __('webshop-category.index.title') }}
      </h1>
    </div>
  </section>

  <section class="container mb-6">
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="w-1/2 md:w-1/4 mr-2.5 md:mr-8">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('webshops.filter.sort-on') }}
          </span>
          <select
            v-model="form.sort"
            class="rounded-lg"
            @change="filter"
          >
            <option
              v-for="(value, key) in sortOptions"
              :key="`sort-option-${key}`"
              :value="key"
            >
              {{ value }}
            </option>
          </select>
        </label>
      </div>
    </form>
  </section>

  <section class="container mb-6 md:mb-12">
    <div class="container flex flex-col items-center">
      <template v-if="webshops.meta.pagination.total > 0">
        <div class="grid">
          <div class="grid gap-2.5 md:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-4">
            <template
              v-for="webshop in webshops.data"
              :key="`webshop-${webshop.id}`"
            >
              <WebshopBlock :webshop="webshop" />
            </template>
          </div>
        </div>

        <Pagination
          v-if="webshops.meta.pagination.links.length > 3"
          :pagination="webshops.meta.pagination"
        />
      </template>
      <template v-else>
        <p class="text-sm md:text-base text-center mb-6">
          {{ __('webshop-category.index.no-webshops') }}
        </p>
      </template>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import WebshopBlock from '../../Components/Webshop/Block.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    WebshopBlock,
    Pagination,
  },

  props: {
    sortOptions: {
      type: Object,
      required: true,
    },

    categories: {
      type: Object,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },

    category: {
      type: Object,
      required: false,
      default: null,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      sort: props.filters.sort,
    });

    return {
      form,
    };
  },

  methods: {
    filter() {
      let url = this.route('frontend.webshop-category.index');

      this.$inertia.get(url, {
          sort: this.form.sort,
        },
        {
          only: ['webshops', 'category', 'filters'],
        });
    },
  },
};
</script>
