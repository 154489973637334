<template>
  <Head :title="__('account.profile.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"
    :current-page="__('account.profile.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.profile.title') }}
          </h1>
          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('account.profile.subtitle') }}
            </p>

            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/profile_settings.svg"
            >
          </div>
        </div>

        <div class="w-full lg:w-8/12">
          <div class="md:bg-white md:shadow rounded md:p-6 mb-6">
            <form
              class="form mt-4"
              @submit.prevent="form.post(route('frontend.account.profile.store'))"
            >
              <div class="flex flex-col space-y-4">
                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('account.profile.profile-picture') }}:
                  </label>
                  <div class="w-full md:w-5/6 lg:w-2/4 flex flex-wrap">
                    <div class="w-1/3 md:w-1/6 flex items-center justify-center">
                      <img
                        v-if="member.picture"
                        :src="member.picture"
                      >
                      <span
                        v-else
                        class="material-symbols-outlined text-4xl"
                      >
                        person
                      </span>
                    </div>
                    <div class="w-2/3 pl-4 flex items-center flex-wrap md:w-5/6">
                      <div class="text-sm">
                        <Link
                          :href="route('frontend.account.picture')"
                          class="block underline hover:no-underline w-full mb-2 text-orange"
                        >
                          {{ __('account.profile.profile-picture-edit') }}
                        </Link>
                        <Modal
                          v-if="member.picture"
                          ref="modal"
                          :title="__('account.profile.profile-picture-delete')"
                        >
                          <template #modal-button="{ openModal }">
                            <div
                              class="block underline hover:no-underline w-full text-orange cursor-pointer"
                              @click="openModal"
                            >
                              {{ __('account.profile.profile-picture-delete') }}
                            </div>
                          </template>

                          <template #modal-content="{ closeModal }">
                            <p class="mb-4">
                              {{ __('account.profile.profile-picture-delete-confirm') }}
                            </p>

                            <div class="flex justify-end">
                              <button
                                type="button"
                                class="text-blue underline hover:no-underline"
                                @click="closeModal"
                              >
                                {{ __('account.profile.profile-picture-delete-confirm-no') }}
                              </button>
                              <Link
                                :href="route('frontend.account.picture.delete')"
                                as="button"
                                method="delete"
                                class="flex justify-center items-center text-white font-bold no-underline rounded-full py-2 px-6 ml-4 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button md:text-lg"
                              >
                                {{ __('account.profile.profile-picture-delete-confirm-yes') }}
                              </Link>
                            </div>
                          </template>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('general.username') }}:
                  </label>
                  <input
                    class="md:w-2/3"
                    type="text"
                    :value="member.username"
                    disabled
                  >
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('general.gender') }}:
                  </label>
                  <div class="md:w-2/3">
                    <select
                      v-model="form.gender"
                      required
                    >
                      <option
                        disabled
                        value=""
                      >
                        {{ __('general.gender-placeholder') }}
                      </option>
                      <option
                        v-for="(gender, i) in genders"
                        :key="`gender-${i}`"
                        :value="gender.id"
                      >
                        {{ gender.name }}
                      </option>
                    </select>
                    <p
                      v-if="form.errors.first_name"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.first_name }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('general.first_name') }}:
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.first_name"
                      type="text"
                      name="first_name"
                      autocomplete="given-name"
                      required
                    >
                    <p
                      v-if="form.errors.first_name"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.first_name }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-baseline">
                  <label>
                    {{ __('general.emailaddress') }}:
                  </label>
                  <div class="md:w-2/3">
                    <input
                      type="email"
                      name="email"
                      autocomplete="email"
                      :placeholder="member.email"
                      class="placeholder-black"
                      disabled
                    >
                    <div class="w-full text-sm mt-1">
                      <Link
                        class="inline-block underline hover:no-underline text-orange"
                        :href="route('frontend.account.change-email')"
                      >
                        {{ __('account.profile.change_email') }}
                      </Link>
                    </div>
                  </div>
                </div>

                <BirthdateField
                  v-model:day="form.birth_date_day"
                  v-model:month="form.birth_date_month"
                  v-model:year="form.birth_date_year"
                  v-model:parental-approval="form.parental_approval"
                  :day-options="days"
                  :month-options="months"
                  :year-options="years"
                  :minimum-age="minimumAge"
                  :errors="form.errors"
                  :disabled="!member.can_set_birth_date"
                />

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('general.zipcode') }}:
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.zipcode"
                      type="text"
                      name="zipcode"
                      autocomplete="postal-code"
                    >
                    <p
                      v-if="form.errors.zipcode"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.zipcode }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('general.education') }}:
                  </label>
                  <div class="md:w-2/3">
                    <select v-model="form.education">
                      <option
                        disabled
                        :value="null"
                      >
                        {{ __('general.education-placeholder') }}
                      </option>
                      <option
                        v-for="(education, i) in educationLevels"
                        :key="`education-${i}`"
                        :value="education"
                      >
                        {{ education }}
                      </option>
                    </select>
                    <p
                      v-if="form.errors.first_name"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.first_name }}
                    </p>
                  </div>
                </div>

                <div class="flex justify-end">
                  <div class="w-full md:w-auto">
                    <Blue
                      :disabled="form.processing"
                    >
                      {{ __('account.profile.button') }}
                    </Blue>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="text-sm">
            <i18n-t
              keypath="account.profile.unsubscribe-text"
              scope="global"
            >
              <template #unsubscribeLink>
                <Link
                  :href="route('frontend.account.delete')"
                >
                  {{ __('account.profile.unsubscribe-link') }}
                </Link>
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import BirthdateField from '../../../Components/Form/BirthdateField.vue';
import Blue from '../../../Components/Buttons/Blue.vue';
import Modal from '../../../Components/Modal.vue';

export default {
  components: {
    BirthdateField,
    Blue,
    Modal,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    days: {
      type: Object,
      required: true,
    },

    months: {
      type: Object,
      required: true,
    },

    years: {
      type: Object,
      required: true,
    },

    genders: {
      type: Array,
      required: true,
    },

    minimumAge: {
      type: Number,
      required: true,
    },

    educationLevels: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      language_id: props.member.language_id,
      timezone: props.member.timezone,
      first_name: props.member.first_name,
      last_name: props.member.last_name,
      gender: props.member.gender ?? '',
      birth_date_year: props.member.birth_date_year?.toString() ?? '',
      birth_date_month: props.member.birth_date_month?.toString() ?? '',
      birth_date_day: props.member.birth_date_day?.toString() ?? '',
      zipcode: props.member.zipcode,
      education: props.member.education,
    });

    return {
      form,
    };
  },
};
</script>
