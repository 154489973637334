<template>
  <section
    id="reviews"
    class="container mb-6"
  >
    <div class="bg-white shadow rounded p-4">
      <div class="mx-auto lg:grid lg:grid-cols-12">
        <div class="lg:col-span-4">
          <h4 class="text-xl font-bold mb-4">
            {{ __('webshop.review.title') }}
          </h4>
          <div class="mt-3 flex items-center">
            <div>
              <div class="flex items-center">
                <Stars
                  :full-stars="rating.fullStars"
                  :half-stars="rating.halfStars"
                  :empty-stars="rating.emptyStars"
                />
              </div>
            </div>
            <p class="ml-2 text-sm ">
              {{ __('webshop.review.total-reviews', {count: rating.count}) }}
            </p>
          </div>

          <div class="mt-6">
            <dl class="space-y-3">
              <div class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                  <p class="w-3">
                    5
                  </p>
                  <div
                    aria-hidden="true"
                    class="ml-1 flex flex-1 items-center"
                  >
                    <span
                      class="material-symbols-outlined fill md-36 star_rate text-orange"
                    >
                      star_rate
                    </span>
                    <div class="relative ml-3 flex-1">
                      <div class="h-3 rounded-full border border-gray-700 bg-gray-500" />
                      <div
                        v-if="rating.five_stars_count > 0"
                        class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                        :style="{ width: `calc(${rating.five_stars_count} / ${rating.count} * 100%)` }"
                      />
                    </div>
                  </div>
                </dt>
                <dd class="ml-3 w-10 text-right text-sm tabular-nums">
                  {{ calculatePercentage(rating.five_stars_count, rating.count) }}%
                </dd>
              </div>

              <div class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                  <p class="w-3">
                    4
                  </p>
                  <div
                    aria-hidden="true"
                    class="ml-1 flex flex-1 items-center"
                  >
                    <span
                      class="material-symbols-outlined fill md-36 star_rate text-orange"
                    >
                      star_rate
                    </span>
                    <div class="relative ml-3 flex-1">
                      <div class="h-3 rounded-full border border-gray-700 bg-gray-500" />
                      <div
                        v-if="rating.four_stars_count > 0"
                        class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                        :style="{ width: `calc(${rating.four_stars_count} / ${rating.count} * 100%)` }"
                      />
                    </div>
                  </div>
                </dt>
                <dd class="ml-3 w-10 text-right text-sm tabular-nums">
                  {{ calculatePercentage(rating.four_stars_count, rating.count) }}%
                </dd>
              </div>

              <div class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                  <p class="w-3">
                    3
                  </p>
                  <div
                    aria-hidden="true"
                    class="ml-1 flex flex-1 items-center"
                  >
                    <span
                      class="material-symbols-outlined fill md-36 star_rate text-orange"
                    >
                      star_rate
                    </span>
                    <div class="relative ml-3 flex-1">
                      <div class="h-3 rounded-full border border-gray-700 bg-gray-500" />
                      <div
                        v-if="rating.three_stars_count > 0"
                        class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                        :style="{ width: `calc(${rating.three_stars_count} / ${rating.count} * 100%)` }"
                      />
                    </div>
                  </div>
                </dt>
                <dd class="ml-3 w-10 text-right text-sm tabular-nums">
                  {{ calculatePercentage(rating.three_stars_count, rating.count) }}%
                </dd>
              </div>

              <div class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                  <p class="w-3">
                    2
                  </p>
                  <div
                    aria-hidden="true"
                    class="ml-1 flex flex-1 items-center"
                  >
                    <span
                      class="material-symbols-outlined fill md-36 star_rate text-orange"
                    >
                      star_rate
                    </span>
                    <div class="relative ml-3 flex-1">
                      <div class="h-3 rounded-full border border-gray-700 bg-gray-500" />
                      <div
                        v-if="rating.two_stars_count > 0"
                        class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                        :style="{ width: `calc(${rating.two_stars_count} / ${rating.count} * 100%)` }"
                      />
                    </div>
                  </div>
                </dt>
                <dd class="ml-3 w-10 text-right text-sm tabular-nums ">
                  {{ calculatePercentage(rating.two_stars_count, rating.count) }}%
                </dd>
              </div>

              <div class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                  <p class="w-3">
                    1
                  </p>
                  <div
                    aria-hidden="true"
                    class="ml-1 flex flex-1 items-center"
                  >
                    <span
                      class="material-symbols-outlined fill md-36 star_rate text-orange"
                    >
                      star_rate
                    </span>
                    <div class="relative ml-3 flex-1">
                      <div class="h-3 rounded-full border border-gray-700 bg-gray-500" />
                      <div
                        v-if="rating.one_star_count > 0"
                        class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                        :style="{ width: `calc(${rating.one_star_count} / ${rating.count} * 100%)` }"
                      />
                    </div>
                  </div>
                </dt>
                <dd class="ml-3 w-10 text-right text-sm tabular-nums ">
                  {{ calculatePercentage(rating.one_star_count, rating.count) }}%
                </dd>
              </div>
            </dl>
          </div>

          <div
            id="review-items"
            class="mt-10 hashtag-link"
          >
            <h3 class="text-lg">
              {{ __('webshop.review.share') }}
            </h3>
            <p class="mt-1 text-sm -600">
              {{ __('webshop.review.share-text') }}
            </p>

            <button
              v-if="!$page.props.auth.member"
              class="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-600 bg-white py-2 px-8 text-sm hover:bg-gray-100 sm:w-auto lg:w-full text-blue no-underline"
              @click="$parent.$parent.$refs.loginModal.doOpenModal()"
            >
              {{ __('webshop.review.write-review') }}
            </button>
            <template v-else-if="!eligibleForReview">
              <Modal :title="__('webshop.review.not-eligible-modal.title')">
                <template #modal-button="{ openModal }">
                  <button
                    class="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-600 bg-white py-2 px-8 text-sm hover:bg-gray-100 sm:w-auto lg:w-full text-blue no-underline"
                    @click="openModal"
                  >
                    {{ __('webshop.review.write-review') }}
                  </button>
                </template>

                <template #modal-content="{ closeModal }">
                  {{ __('webshop.review.not-eligible-modal.text') }}

                  <button
                    class="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-600 bg-white py-2 px-8 text-sm hover:bg-gray-100 sm:w-auto lg:w-full text-blue no-underline"
                    @click="closeModal"
                  >
                    {{ __('webshop.review.not-eligible-modal.button') }}
                  </button>
                </template>
              </Modal>
            </template>
            <a
              v-else
              :href="route('frontend.webshop-reviews.create', {webshop: slug})"
              class="mt-6 inline-flex w-full items-center justify-center rounded-md border border-gray-600 bg-white py-2 px-8 text-sm hover:bg-gray-100 sm:w-auto lg:w-full text-blue no-underline"
            >
              {{ __('webshop.review.write-review') }}
            </a>
          </div>
        </div>

        <div
          class="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0"
        >
          <div class="flow-root">
            <div class="divide-y divide-gray-300">
              <div
                v-for="review in reviews.data"
                :key="review.id"
                class="py-12"
              >
                <div class="flex items-center">
                  <img
                    v-if="review.picture_url"
                    :src="review.picture_url"
                    class="h-12 w-12 rounded-full"
                  >
                  <span
                    v-else
                    class="material-symbols-outlined md-64 text-orange"
                  >
                    account_circle
                  </span>

                  <div class="ml-4">
                    <h4 class="text-sm font-bold">
                      {{ review.first_name ?? review.username ?? __('webshop.review.anonymous') }}
                    </h4>
                    <p> {{ review.date }} </p>
                    <div class="mt-1 flex items-center">
                      <Stars
                        :full-stars="review.fullStars"
                        :half-stars="review.halfStars"
                        :empty-stars="review.emptyStars"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="mt-4 space-y-6 text-base italic -600"
                >
                  {{ review.text }}
                </div>
              </div>

              <div
                v-if="reviews.meta.pagination.prev_page_url || reviews.meta.pagination.next_page_url"
                class="md:hidden flex justify-between text-md text-blue py-3"
              >
                <div
                  v-if="!reviews.meta.pagination.prev_page_url"
                  class="underline opacity-50"
                >
                  {{ __('pagination.previous') }}
                </div>
                <Link
                  v-else
                  :href="reviews.meta.pagination.prev_page_url"
                  :only="['reviews']"
                  class="underline hover:no-underline cursor-pointer"
                  preserve-scroll
                  :on-success="scrollToReviews"
                >
                  {{ __('pagination.previous') }}
                </Link>
                <div
                  v-if="!reviews.meta.pagination.next_page_url"
                  class="underline opacity-50"
                >
                  {{ __('pagination.next') }}
                </div>
                <Link
                  v-else
                  :href="reviews.meta.pagination.next_page_url"
                  :only="['reviews']"
                  class="underline hover:no-underline cursor-pointer"
                  preserve-scroll
                  :on-success="scrollToReviews"
                >
                  {{ __('pagination.next') }}
                </Link>
              </div>

              <div
                v-if="reviews.meta.pagination.links.length > 3"
                class="hidden md:flex justify-center items-center text-md text-blue py-3 space-x-4"
              >
                <span
                  v-for="(link, i) in reviews.meta.pagination.links"
                  :key="i"
                >
                  <span
                    v-if="!link.url"
                    class="underline opacity-50"
                  >
                    {{ link.label }}
                  </span>
                  <span
                    v-else-if="link.url && link.active"
                    class="font-bold"
                  >
                    {{ link.label }}
                  </span>
                  <Link
                    v-else-if="link.url"
                    :href="link.url"
                    :only="['reviews']"
                    class="underline hover:no-underline cursor-pointer"
                    preserve-scroll
                    :on-success="scrollToReviews"
                  >
                    {{ link.label }}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Stars from '../../../Components/Stars.vue';
import Modal from '../../../Components/Modal.vue';

export default {
  components: {
    Stars,
    Modal,
  },

  props: {
    reviews: {
      type: Object,
      required: false,
      default: null,
    },

    rating: {
      type: Object,
      required: false,
      default: null,
    },

    slug: {
      type: String,
      required: true,
    },

    eligibleForReview: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    calculatePercentage(amount, total) {
      if (total) {
        return Math.round((amount / total) * 100);
      }

      return 0;
    },

    scrollToReviews() {
      document.getElementById('review-items').scrollIntoView({behavior: 'smooth'});
    },
  },
};
</script>
