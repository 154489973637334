<template>
  <Head :title="__('report-incorrect-cashback.title')" />

  <Breadcrumbs :current-page="__('report-incorrect-cashback.title')" />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('report-incorrect-cashback.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('report-incorrect-cashback.text') }}
            </p>
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <div
            v-if="form.wasSuccessful"
            class="font-bold my-4"
          >
            {{ __('report-incorrect-cashback.success-message') }}
          </div>
          <div v-else>
            <span class="font-bold">{{ __('report-incorrect-cashback.terms_title') }}</span>
            <ul class="container list-disc text-orange mb-6">
              <li
                v-for="(term, value) in $tm('report-incorrect-cashback.terms')"
                :key="value"
                :value="value"
              >
                <span class="text-black">
                  {{
                    $rt(term, {
                      'missingMinDays': missingMinDays,
                      'missingMaxDays': missingMaxDays,
                      'approvedMaxDays': approvedMaxDays,
                      'disapprovedMaxDays': disapprovedMaxDays
                    })
                  }}
                </span>
              </li>
            </ul>

            <form
              @submit.prevent="form.post(route('frontend.report-incorrect-cashback.store'), {
                onSuccess: () => form.reset(),
              })"
            >
              <div class="flex flex-col space-y-4">
                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.what_went_wrong') }}
                  </label>
                  <div class="md:w-2/3">
                    <select
                      v-model="form.what_went_wrong"
                      required
                    >
                      <option disabled />
                      <option
                        v-for="(option, value) in $tm('report-incorrect-cashback.what_went_wrong-options')"
                        :key="value"
                        :value="$rt(option)"
                      >
                        {{ $rt(option) }}
                      </option>
                    </select>
                    <p
                      v-if="form.errors.what_went_wrong"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.what_went_wrong }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.webshop_name') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.webshop_name"
                      type="text"
                      required
                    >
                    <p
                      v-if="form.errors.webshop_name"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.webshop_name }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.purchase_value') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.purchase_value"
                      type="text"
                      required
                    >
                    <p
                      v-if="form.errors.purchase_value"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.purchase_value }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.expect_cashback') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.expect_cashback"
                      type="text"
                      required
                    >
                    <p
                      v-if="form.errors.expect_cashback"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.expect_cashback }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.purchase_date') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.purchase_date"
                      type="datetime-local"
                      required
                    >
                    <p
                      v-if="form.errors.purchase_date"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.purchase_date }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.purchased_device_type') }}
                  </label>
                  <div class="md:w-2/3">
                    <select
                      v-model="form.purchased_device_type"
                      required
                    >
                      <option disabled />
                      <option
                        v-for="(option, value) in $tm('report-incorrect-cashback.purchased_device_type-options')"
                        :key="value"
                        :value="$rt(option)"
                      >
                        {{ $rt(option) }}
                      </option>
                    </select>
                    <p
                      v-if="form.errors.purchased_device_type"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.purchased_device_type }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.voucher_code') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.voucher_code"
                      type="text"
                    >
                    <p
                      v-if="form.errors.voucher_code"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.voucher_code }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __('report-incorrect-cashback.add_invoice') }}
                  </label>
                  <div class="md:w-2/3">
                    <input
                      type="file"
                      @input="setAttachment($event)"
                    >
                    <p
                      v-if="attachmentExceedsSize"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ __('general.max_file_size', {'limit': attachmentLimit + 'MB'}) }}
                    </p>
                    <p
                      v-if="form.errors.attachment"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.attachment }}
                    </p>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:items-start">
                  <label>
                    {{ __('report-incorrect-cashback.comments') }}
                  </label>
                  <div class="md:w-2/3">
                    <textarea
                      v-model="form.comments"
                      class="h-48"
                    />
                    <p
                      v-if="form.errors.comments"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.comments }}
                    </p>
                  </div>
                </div>

                <div class="text-xs mt-3">
                  <div class="flex flex-col">
                    <label class="flex w-full">
                      <input
                        v-model="form.agree_info"
                        class="self-start mt-1"
                        type="checkbox"
                        required
                      >
                      <span class="ml-2 !text-base">{{ __('report-incorrect-cashback.agree_info') }}</span>
                    </label>
                    <p
                      v-if="form.errors.agree_info"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.agree_info }}
                    </p>
                  </div>
                </div>

                <div class="text-xs mt-3">
                  <div class="flex flex-col">
                    <label class="flex w-full">
                      <input
                        v-model="form.agree_correct"
                        class="self-start mt-1"
                        type="checkbox"
                        required
                      >
                      <span class="ml-2 !text-base">{{ __('report-incorrect-cashback.agree_correct') }}</span>
                    </label>
                    <p
                      v-if="form.errors.agree_correct"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.agree_correct }}
                    </p>
                  </div>
                </div>

                <div class="flex justify-end">
                  <div class="w-full md:w-auto">
                    <Blue
                      :disabled="form.processing"
                    >
                      {{ __('report-incorrect-cashback.button') }}
                    </Blue>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Blue from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Blue,
  },

  props: {
    attachmentLimit: {
      type: Number,
      required: true,
    },

    missingMinDays: {
      type: String,
      required: true,
    },

    missingMaxDays: {
      type: String,
      required: true,
    },

    approvedMaxDays: {
      type: String,
      required: true,
    },

    disapprovedMaxDays: {
      type: String,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      what_went_wrong: '',
      webshop_name: '',
      purchase_value: '',
      expect_cashback: '',
      purchase_date: '',
      purchased_device_type: '',
      voucher_code: '',
      attachment: null,
      comments: '',
      agree_info: false,
      agree_correct: false,
    });

    return {
      form,
      attachmentExceedsSize: ref(false),
    };
  },

  methods: {
    setAttachment(e) {
      const field = e.target.files[0];
      this.form.attachment = field;

      const fileLimitBytes = this.attachmentLimit * 1000000;
      this.attachmentExceedsSize = false;
      if (field.size > fileLimitBytes) {
        this.attachmentExceedsSize = true;
        this.form.attachment = null;
      }
    },
  },
};
</script>
