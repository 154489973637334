<template>
  <Head :title="__('webshop-reviews.create.title', {webshop: webshop.name})" />

  <Breadcrumbs
    :pages="[
      {name: 'Webshops', href: route('frontend.webshop-category.index')},
      {name: webshop.name, href: route('frontend.webshop.show', webshop.slug)},
    ]"
    :current-page="__('webshop-reviews.create.title', {webshop: webshop.name})"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('webshop-reviews.create.title', {webshop: webshop.name}) }}
    </h1>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white rounded-l shadow p-4">
      <p class="font-medium mb-6">
        <i18n-t
          keypath="webshop-reviews.create.faq_contact"
          scope="global"
        >
          <template #faq_contact_link>
            <Link
              :href="route('frontend.faq')"
              class="text-blue underline hover:no-underline"
            >
              {{ __('webshop-reviews.create.faq_contact_link') }}
            </Link>
          </template>
        </i18n-t>
      </p>

      <form
        class="form lg:w-3/5"
        @submit.prevent="form.post(route('frontend.webshop-reviews.store', {webshop: webshop.slug}), {
          onSuccess: () => form.reset(),
        })"
      >
        <div class="flex flex-wrap items-center mb-3">
          <label class="w-full text-sm block mb-1 md:mb-0 md:w-1/3 md:font-bold">
            {{ __('webshop-reviews.create.form.general-score') }}:
          </label>
          <div class="w-full md:w-2/3">
            <select
              v-model="form.general_score"
              required
            >
              <option
                v-for="i in 5"
                :key="i"
                :value="i"
              >
                {{ i }}
              </option>
            </select>
          </div>
          <p
            v-if="form.errors.general_score"
            class="text-orange text-xs italic"
          >
            {{ form.errors.general_score }}
          </p>
        </div>

        <div class="flex flex-wrap mb-3 ">
          <label class="w-full text-sm block mb-1 md:mb-0 md:mt-2 md:w-1/3 md:font-bold">
            {{ __('webshop-reviews.create.form.text') }}:
          </label>
          <div class="w-full md:w-2/3">
            <textarea
              v-model="form.text"
              class="h-48"
              required
            />
          </div>
          <p
            v-if="form.errors.text"
            class="text-orange text-xs italic"
          >
            {{ form.errors.text }}
          </p>
        </div>

        <div class="flex justify-end">
          <div class="w-full md:w-auto">
            <Blue
              :disabled="form.processing"
            >
              {{ __('webshop-reviews.create.form.button') }}
            </Blue>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import {defineProps} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Blue from '../../Components/Buttons/Blue.vue';

const props = defineProps({
  webshop: {
    type: Object,
    required: true,
  },

  cashback: {
    type: Object,
    required: true,
  },
});

const form = useForm({
  cashback_id: props.cashback.id,
  general_score: '',
  text: '',
});
</script>
