<template>
  <Head :title="__('content.impressum.title')" />

  <Breadcrumbs
    :current-page="__('content.impressum.title')"
  />

  <section class="container my-6 md:my-12">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('content.impressum.title') }}
    </h1>

    <p class="mb-4">
      {{ __('content.impressum.text-header') }}
    </p>

    <p class="mb-4 whitespace-pre-line">
      {{ __('content.impressum.contact-information') }}
    </p>

    <p class="mb-4">
      {{ __('content.impressum.chamber_commerce_number', {'number': cocNumber}) }}
      <br>
      {{ __('content.impressum.vat_number', {'number': vatNumber}) }}
    </p>

    <p class="mb-4">
      {{ __('content.impressum.ceo', {'ceo': ceo}) }}
    </p>

    <div>
      <Link
        :href="route('frontend.contact')"
        class="underline"
      >
        {{ __('content.impressum.contact') }}
      </Link>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },

  props: {
    cocNumber: {
      type: String,
      required: true,
    },

    vatNumber: {
      type: String,
      required: true,
    },

    ceo: {
      type: String,
      required: true,
    },
  },
};
</script>
