<template>
  <Head :title="`${__('faq.title')} - ${item.question}`" />

  <Breadcrumbs
    :pages="[
      { name: __('faq.title'), href: route('frontend.faq') },
      { name: item.category.name, href: route('frontend.faq.category', {category: item.category.id}) },
    ]"
    :current-page="item.question"
  />

  <section class="flex flex-col container mb-12">
    <h2 class="text-2xl md:text-3xl font-bold mb-2">
      {{ item.question }}
    </h2>

    <!-- eslint-disable vue/no-v-html -->
    <div
      class="content text-base mb-4 md:mb-8"
      v-html="item.answer"
    />
    <!-- eslint-enable -->

    <Link
      :href="route('frontend.faq')"
      class="text-base underline hover:no-underline"
    >
      {{ __('faq.item.back') }}
    </Link>

    <div class="flex flex-col w-full md:w-4/12 self-center mt-56">
      <p class="self-center text-base mb-4">
        {{ __('faq.item.not_found') }}
      </p>

      <Blue
        :href="route('frontend.contact')"
        target="_self"
        class="text-base"
      >
        {{ __('faq.item.contact_us') }}
      </Blue>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Blue from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Breadcrumbs,
    Blue,
  },

  props: {
    searchItems: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
