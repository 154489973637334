<template>
  <Head :title="__('profile-questions.completed.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
    ]"
    :current-page="__('profile-questions.completed.title')"
  />

  <section class="container md:w-3/4 lg:w-1/2 flex flex-col mb-6">
    <div class="flex flex-col">
      <h1 class="text-2xl md:text-3xl font-bold mb-4">
        {{ __('profile-questions.completed.title') }}
      </h1>

      <form
        class="w-full flex md:justify-end"
        @submit.prevent="filter(true)"
      >
        <div class="relative w-full mb-4">
          <input
            v-model="filterForm.q"
            type="text"
            class="pl-4 pr-10 rounded-full"
            :placeholder="__('profile-questions.completed.search')"
            @keyup="filter(false)"
          >

          <button
            type="submit"
            class="absolute inset-y-0 right-0 pr-4 flex items-center"
          >
            <span
              v-if="filterForm.processing"
              class="text-orange material-symbols-outlined animate-spin"
            >
              autorenew
            </span>
            <img
              v-else
              src="/qassa/images/search-loupe.svg"
            >
          </button>
        </div>
      </form>
    </div>
  </section>

  <section
    v-if="questions.data && questions.data.length > 0"
    class="container md:w-3/4 lg:w-1/2 flex flex-col mb-6 space-y-4"
  >
    <div
      v-for="question in questions.data"
      :key="question.id"
      class="bg-white rounded shadow p-4"
    >
      <ProfileQuestionsFactory
        v-model="memberAnswers[question.id]"
        :question="question"
        @validation-error="validationError(question.id, $event)"
      />

      <div
        v-show="validationErrorMessages[question.id]"
        class="mt-2 text-orange text-sm"
      >
        *{{ validationErrorMessages[question.id] }}
      </div>

      <BlueButton
        classes="w-auto mt-4"
        :disabled="updating.includes(question.id) || validationErrorMessages[question.id]"
        :loading="updating.includes(question.id)"
        @click="updateQuestion(question.id, memberAnswers[question.id])"
      >
        {{ __('profile-questions.questions.edit') }}
      </BlueButton>
    </div>

    <div
      v-if="questions.meta.pagination.links.length > 3"
      class="pt-4"
    >
      <Pagination :pagination="questions.meta.pagination" />
    </div>
  </section>

  <section
    v-else
    class="container md:w-3/4 lg:w-1/2 mb-6"
  >
    <p class="text-sm md:text-base text-center py-2">
      {{ __('profile-questions.completed.no-data') }}
    </p>
  </section>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import axios from 'axios';
import ProfileQuestionsFactory from '../../Components/ProfileQuestions/Factory.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    ProfileQuestionsFactory,
    Breadcrumbs,
    BlueButton,
    Pagination,
  },

  props: {
    questions: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const updateMemberAnswers = function(questions) {
      let memberAnswers = [];
      if (questions.data) {
        questions.data.forEach(question => {
          memberAnswers[question.id] = null;
          if (question.memberAnswers) {
            memberAnswers[question.id] = question.memberAnswers.map(memberAnswer => {
              return memberAnswer.profile_answer_id;
            });
          }
        });
      }

      return memberAnswers;
    };

    let memberAnswers = updateMemberAnswers(props.questions);

    return {
      filterTimer: null,
      filterForm: useForm({
        q: props.filters.q ?? null,
      }),

      memberAnswers: ref(memberAnswers),
      updating: ref([]),
      validationErrorMessages: ref({}),
      updateMemberAnswers: updateMemberAnswers,
    };
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => {
          this.updating.splice(this.updating.indexOf(questionId), 1);
        });
    },

    filter(direct) {
      if (this.filterTimer) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(() => {
        this.filterForm.get(this.route('frontend.profile-questions.completed'), {
          preserveScroll: true,
          preserveState: true,
          only: ['questions', 'filters'],
          onSuccess: () => this.memberAnswers = this.updateMemberAnswers(this.questions),
        });
      }, direct ? 0 : 800);
    },
  },
};
</script>

