<template>
  <Head :title="__('balance.referrals.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.overview.title'), href: route('frontend.balance') },
    ]"
    :current-page="__('balance.referrals.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('balance.referrals.title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="flex w-1/2 md:w-1/4">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('balance.filter.status') }}
          </span>
          <select
            v-model="form.status"
            @change="filter"
          >
            <option :value="null">{{ __('balance.filter.statuses.all') }}</option>
            <option value="pending">{{ __('balance.filter.statuses.pending') }}</option>
            <option value="approved">{{ __('balance.filter.statuses.approved') }}</option>
            <option value="disapproved">{{ __('balance.filter.statuses.disapproved') }}</option>
          </select>
        </label>
      </div>
    </form>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4 divide-solid divide-y">
      <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm font-bold w-full">
        <div class="w-4/12 md:w-8/12">
          {{ __('balance.member') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.date') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.qoins') }}
        </div>
      </div>

      <div
        v-if="form.processing"
        class="flex justify-center p-4"
      >
        <span class="material-symbols-outlined animate-spin">autorenew</span>
      </div>

      <template v-else-if="referrals.meta.pagination.total > 0">
        <div
          v-for="referral in referrals.data"
          :key="referral.id"
          class="py-2"
        >
          <div class="flex w-full mb-1 text-sm md:text-base">
            <div class="w-4/12 md:w-8/12">
              {{ referral.name }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ referral.registration_date }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ referral.value }}
            </div>
          </div>
          <div
            class="flex items-center w-full text-xs"
            :class="{
              'text-green-500': referral.status === 'approved',
              'text-red-500': referral.status === 'disapproved',
              'text-gray-600': referral.status === 'pending',
            }"
          >
            <span
              class="h-2 w-2 rounded-full mr-2"
              :class="{
                'bg-green-500': referral.status === 'approved',
                'bg-red-500': referral.status === 'disapproved',
                'bg-gray-600': referral.status === 'pending',
              }"
            />

            {{ __(`balance.referrals.status.${referral.status}`, {approvedDate: referral.approvedDate, updatedDate: referral.updatedDate}) }}
          </div>
        </div>
      </template>

      <p
        v-else
        class="text-sm md:text-base text-center py-2"
      >
        {{ __('balance.referrals.no-data') }}
      </p>

      <div
        v-if="referrals.meta.pagination.links.length > 3"
        class="pt-4"
      >
        <Pagination :pagination="referrals.meta.pagination" />
      </div>
    </div>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4">
      <h2 class="text-lg font-bold mb-4">
        {{ __('balance.referrals.tell-a-friend.title') }}
      </h2>

      <p class="text-sm whitespace-pre-line">
        <i18n-t
          keypath="balance.referrals.tell-a-friend.text"
          scope="global"
        >
          <template #conditionsLink>
            <Link
              :href="route('frontend.tell-a-friend.conditions')"
              class="text-blue underline hover:no-underline"
            >
              {{ __('balance.referrals.tell-a-friend.conditions-link-text') }}
            </Link>
          </template>
          <template #shareBonus>
            {{ shareBonus }}
          </template>
          <template #shareUrl>
            <a
              :href="shareUrl"
              target="_blank"
              class="text-blue underline hover:no-underline"
            >
              {{ shareUrl }}
            </a>
          </template>
        </i18n-t>
      </p>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Faq from './Components/Faq.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    Faq,
    Breadcrumbs,
    Pagination,
  },

  props: {
    referrals: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    shareUrl: {
      type: String,
      required: true,
    },

    shareBonus: {
      type: String,
      required: true,
    },

    faqItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data(props) {
    return {
      filterTimer: null,
      form: useForm({
        status: props.filters.status ?? null,
      }),
    };
  },

  methods: {
    filter() {
      this.form.get(this.route('frontend.balance.referrals'), {
        preserveScroll: true,
        preserveState: true,
        only: ['referrals', 'filters'],
      });
    },
  },
};
</script>
