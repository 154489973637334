<template>
  <div class="flex flex-col items-center text-center bg-white p-2 md:p-4 shadow rounded">
    <a
      :href="route('frontend.clickout.free-action', freeAction.slug)"
      target="_blank"
      class="w-full block mb-3"
    >
      <div class="flex justify-center">
        <img
          v-if="freeAction.logo"
          :src="freeAction.logo.url"
          :alt="freeAction.name"
          :title="freeAction.name"
        >
        <span v-else>
          {{ freeAction.name }}
        </span>
      </div>
    </a>

    <span class="block text-sm font-bold w-full">
      {{ __cashback(freeAction.usePoints, 'cashback.max.single', {value: freeAction.cashback}) }}
    </span>
    <span class="grow text-sm leading-1 block w-full mb-4">
      {{ freeAction.categories[0].name }}
    </span>

    <BlueOpenButton
      :href="route('frontend.clickout.free-action', freeAction.slug)"
      :internal="false"
    >
      {{ __('free-action.block.button') }}
    </BlueOpenButton>
  </div>
</template>

<script>
import BlueOpenButton from './Buttons/BlueOpen.vue';

export default {
  components: {
    BlueOpenButton,
  },

  props: {
    freeAction: {
      type: Object,
      required: true,
    },
  },
};
</script>
