<template>
  <Head :title="`${__('mailings.title')} - ${mailing.subject}`" />

  <section>
    <div class="container flex justify-between my-6">
      <Link
        class="btn-white"
        :href="route('frontend.mailings.index')"
      >
        <span class="material-symbols-outlined mr-2">
          chevron_left
        </span>

        {{ __('mailings.show.go-back') }}
      </Link>

      <button
        v-if="mailing.webshop_id"
        type="button"
        class="btn-white"
        @click="addWebshopToFavorites"
      >
        <template v-if="loadingFavoriteWebshop">
          <span class="material-symbols-outlined animate-spin mr-2">
            autorenew
          </span>

          {{ __('mailings.show.favorite-webshop.loading') }}
        </template>
        <template v-else-if="webshopIsFavorite">
          <span class="material-symbols-outlined text-red-600 mr-2">
            favorite
          </span>

          {{ __('mailings.show.favorite-webshop.remove') }}
        </template>
        <template v-else-if="!webshopIsFavorite">
          <span class="material-symbols-outlined  mr-2">
            favorite
          </span>

          {{ __('mailings.show.favorite-webshop.add') }}
        </template>
      </button>
    </div>
  </section>

  <section class="container mb-2.5 md:mb-4 md:text-center">
    <div class="text-xs text-gray-500 mb-2.5 md:mb-4">
      {{ mailing.sent_at }}
    </div>
    <div class="text-lg md:text-2xl font-bold">
      {{ mailing.subject }}
    </div>
  </section>

  <section>
    <div class="md:container flex flex-col min-h-screen md:min-h-full">
      <template v-if="loadingMailPreview">
        <div class="flex items-center justify-center z-1 my-4 text-red px-4 md:px-0">
          {{ __('mailings.show.loading-mail') }}

          <span class="material-symbols-outlined animate-spin ml-2">
            autorenew
          </span>
        </div>
      </template>
      <iframe
        :src="route('frontend.mailings.preview', mailing.id)"
        class="grow"
        :class="{ 'hidden': loadingMailPreview }"
        :style="{ 'height': `${iframeHeight}px` }"
        @load="iframeLoaded($event)"
      />
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    mailing: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingMailPreview: true,
      iframeHeight: 0,

      webshopIsFavorite: this.mailing.is_favorite_webshop,
      loadingFavoriteWebshop: false,
    };
  },

  methods: {
    iframeLoaded(e) {
      this.loadingMailPreview = false;

      this.$nextTick(() => this.iframeHeight = e.target.contentWindow.document.documentElement.scrollHeight);
    },

    addWebshopToFavorites() {
      this.loadingFavoriteWebshop = true;

      axios.post(this.route('frontend.api.webshop.favorite', {webshop: this.mailing.webshop_id}), {
        attach: !this.webshopIsFavorite,
      })
        .then(() => {
          this.webshopIsFavorite = !this.webshopIsFavorite;
          this.loadingFavoriteWebshop = false;
        });
    },
  },
};
</script>
