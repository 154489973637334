<template>
  <form
    action="."
    class="w-full"
    @submit.prevent="showWebshopOrSearchPage(null)"
  >
    <Combobox
      v-model="selectedWebshop"
      as="div"
      name="q"
      class="relative w-full"
      @update:modelValue="showWebshopOrSearchPage"
    >
      <div class="flex flex-wrap">
        <ComboboxInput
          class="peer pl-4 pr-10 rounded-full"
          :class="{ 'md:rounded-none border-none': blockStyle }"
          :placeholder="inputPlaceholder"
          :value="searchQuery"
          type="text"
          @change="searchQuery = $event.target.value"
        />
        <button
          class="absolute inset-y-0 right-12 flex items-center peer-placeholder-shown:invisible"
          @click="clear"
        >
          <span class="material-symbols-outlined">
            close
          </span>
        </button>

        <button
          type="submit"
          class="absolute inset-y-0 right-0 pr-4 flex items-center"
        >
          <img
            v-show="!isSearching"
            src="/qassa/images/search-loupe.svg"
          >
          <span
            v-show="isSearching"
            class="text-orange material-symbols-outlined animate-spin"
          >
            autorenew
          </span>
        </button>
      </div>

      <ComboboxOptions
        as="div"
        class="absolute z-10 w-full top-12"
        :class="{ 'w-[calc(100%+30px)] md:w-full -ml-4 md:-ml-0 top-[54px] md:top-[42px]': blockStyle }"
      >
        <ul class="relative flex flex-col divide-y divide-gray-300 w-full bg-white shadow">
          <ComboboxOption
            v-if="searchQuery && searchQuery.length >= minimumSearchLength"
            v-slot="{ active, selected }"
            :value="null"
          >
            <li class="flex flex-row justify-between items-center py-2 px-4">
              <div
                class="cursor-pointer no-style flex items-center"
                :class="{ '!text-orange': active || selected }"
              >
                <span v-if="!isSearching && searchedWebshops && searchedWebshops.length === 0">
                  {{ __('webshop-search.no-results', {query: searchQuery}) }}
                </span>
                <span v-else>
                  {{ __('webshop-search.search-for', {search: searchQuery}) }}
                </span>
              </div>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-for="(webshop, i) in boosterWebshops"
            :key="`booster-webshop-search-${i}`"
            v-slot="{ active, selected }"
            :value="route('frontend.webshop.show', webshop.slug)"
            as="template"
          >
            <li class="flex flex-row justify-between items-center py-2 px-4">
              <div
                class="cursor-pointer no-style flex items-center"
                :class="{ '!text-orange': active || selected }"
              >
                <img
                  src="/qassa/images/path-orange.svg"
                  class="mr-2.5"
                  alt=""
                >
                {{ webshop.name }}
                <span class="border border-orange rounded bg-orange/20 ml-3 px-1.5 text-xs text-orange">{{ __('webshop-search.tip') }}</span>
              </div>
            </li>
          </ComboboxOption>
          <ComboboxOption
            v-for="(webshop, i) in searchedWebshops"
            :key="`webshop-search-${i}`"
            v-slot="{ active, selected }"
            :value="route('frontend.webshop.show', webshop.slug)"
            as="template"
          >
            <li class="flex flex-row justify-between items-center py-2 px-4">
              <div
                class="cursor-pointer no-style flex items-center"
                :class="{ '!text-orange': active || selected }"
              >
                <img
                  src="/qassa/images/path-orange.svg"
                  class="mr-2.5"
                  alt=""
                >
                {{ webshop.name }}
              </div>
            </li>
          </ComboboxOption>
        </ul>
      </ComboboxOptions>
    </Combobox>
  </form>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import {Combobox, ComboboxInput, ComboboxOptions, ComboboxOption} from '@headlessui/vue';

export default {
  components: {
    Combobox, ComboboxInput, ComboboxOptions, ComboboxOption,
  },

  props: {
    inputPlaceholder: {
      type: String,
      required: true,
    },

    blockStyle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['navigate'],

  setup() {
    const searchQuery = ref('');
    const isSearching = ref(false);
    const searchedWebshops = ref([]);
    const boosterWebshops = ref([]);
    const minimumSearchLength = 1;

    return {searchQuery, isSearching, searchedWebshops, boosterWebshops, minimumSearchLength};
  },

  data() {
    return {
      timer: null,
      loading: [],
      selectedWebshop: null,
    };
  },

  watch: {
    searchQuery(query) {
      clearTimeout(this.timer);

      if (query && query.length >= this.minimumSearchLength) {
        this.searchedWebshops = [];
        this.boosterWebshops = [];
        this.isSearching = true;

        this.timer = setTimeout(() => {
          this.$nextTick(() => {
            this.searchWebshops(query);
          });
        }, 500);
      }
    },
  },

  methods: {
    clear() {
      this.searchQuery = '';
      this.isSearching = false;
      this.searchedWebshops = [];
    },

    searchWebshops(query) {
      axios.post(this.route('frontend.webshop.search-request', {query}))
        .then(({data}) => {
          this.searchedWebshops = data.webshops;
          this.boosterWebshops = data.booster_webshops;

          this.isSearching = false;
        });
    },

    showWebshopOrSearchPage(selectedOption) {
      this.isSearching = false;
      this.$emit('navigate');

      if (selectedOption === null) {
        // enter pressed, no webshop selected
        if (this.searchQuery.length >= this.minimumSearchLength) {
          this.$inertia.get(this.route('frontend.webshop.search', {query: this.searchQuery}));
          return;
        }

        return;
      }

      axios.post(this.route('frontend.api.webshop-search-result'), {
        query: this.searchQuery,
        webshop_count: this.searchedWebshops.length,
      });

      this.$inertia.visit(selectedOption);
    },
  },
};
</script>
