<template>
  <Head :title="__('games.index.title')" />

  <Breadcrumbs :current-page="__('games.index.title')" />

  <section class="container mb-6 md:mb-12">
    <h1 class="text-3xl font-bold text-center mb-6">
      {{ __('games.index.title') }}
    </h1>

    <div class="flex flex-col items-center">
      <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
        <div class="row-start-2 w-full col-span-2 xl:col-span-4 mb-6 md:my-12">
          <WebshopSuggestions :webshop-suggestions="webshopSuggestions" />
        </div>

        <div
          v-for="game in games"
          :key="game.id"
          class="flex flex-col items-center shadow"
        >
          <div class="flex flex-col h-full mb-2.5 md:mb-3.5">
            <Link :href="route('frontend.games.show', game.slug)">
              <img
                :src="game.media.url"
                :alt="game.name"
                :title="game.name"
                class="mb-4 md:mb-6"
              >
            </Link>

            <div class="flex items-center justify-center grow md:text-2xl font-bold text-center px-4">
              <span v-if="game.slug === 'scratch'">{{ __('games.win-max', {reward: game.reward }) }}</span>
              <span v-else>{{ __('games.win', {reward: game.reward }) }}</span>
            </div>
          </div>

          <div class="flex flex-col items-center px-2 md:px-4 mb-4 md:w-4/5">
            <div class="w-full mb-2 md:mb-3.5">
              <FullscreenModal ref="game-modal">
                <template #modal-button="{ openModal }">
                  <BlueSmallButton @click="openModal">
                    {{ __('games.play') }}
                  </BlueSmallButton>
                </template>

                <template #modal-content>
                  <iframe
                    :src="game.url"
                    class="h-[100dvh] w-full"
                  />
                </template>
              </FullscreenModal>
            </div>

            <Link
              :href="route('frontend.games.show', game.slug)"
              class="text-xs md:text-base text-center"
            >
              {{ __('games.index.detail-link') }}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import FullscreenModal from '../../Components/FullscreenModal.vue';
import BlueSmallButton from '../../Components/Buttons/BlueSmall.vue';
import WebshopSuggestions from '../../Components/WebshopSuggestions.vue';

export default {
  components: {
    WebshopSuggestions,
    Breadcrumbs,
    FullscreenModal,
    BlueSmallButton,
  },

  props: {
    games: {
      type: Array,
      required: true,
    },

    gamesUrl: {
      type: String,
      required: true,
    },

    webshopSuggestions: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    window.addEventListener('message', this.handleMessage);
  },

  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },

  methods: {
    handleMessage(event) {
      if (event.origin !== this.gamesUrl) {
        return;
      }

      if (event.data.type === 'close-modal') {
        for (const gameModal of this.$refs['game-modal']) {
          gameModal.closeModal();
        }
      }
    },
  },
};
</script>
