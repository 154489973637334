<template>
  <Head :title="__('account.change-password.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"
    :current-page="__('account.change-password.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.change-password.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/password-settings.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            @submit.prevent="form.post(route('frontend.account.change-password.store'), {
              onSuccess: () => form.reset('new_password_confirmation', 'new_password', 'password'),
            })"
          >
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.password_current') }}:
                </label>
                <Password v-model="form.password">
                  <template #errors>
                    <p
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.password }}
                    </p>
                  </template>
                </Password>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.new_password') }}:
                </label>
                <Password
                  v-model="form.new_password"
                  autocomplete="new-password"
                >
                  <template #errors>
                    <div
                      v-if="form.errors.new_password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      <p>
                        {{ __('register.errors.password_requirements') }}
                      </p>
                      <ul class="list-disc space-y-1 ml-4">
                        <li
                          v-for="error in form.errors.new_password.split('|')"
                          :key="error"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </template>
                </Password>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.new_password_confirmation') }}:
                </label>
                <Password
                  v-model="form.new_password_confirmation"
                  autocomplete="new-password"
                >
                  <template #errors>
                    <p
                      v-if="form.errors.new_password_confirmation"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.new_password_confirmation }}
                    </p>
                  </template>
                </Password>
              </div>

              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    :disabled="form.processing"
                  >
                    {{ __('account.change-password.button') }}
                  </Blue>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import Password from '../../../Components/Form/Password.vue';
import Blue from '../../../Components/Buttons/Blue.vue';

export default {
  components: {
    Password, Blue,
  },

  setup() {
    const form = useForm({
      password: '',
      new_password: '',
      new_password_confirmation: '',
    });

    return {form};
  },
};
</script>
