<template>
  <Head :title="theme.name" />

  <Breadcrumbs :current-page="theme.name" />

  <section class="container flex flex-col mb-6">
    <div class="flex flex-col text-center">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
        {{ theme.name }}
      </h1>
      <p>
        {{ theme.description }}
      </p>
    </div>
  </section>

  <section
    v-if="Object.keys(sortOptions).length > 1"
    class="container mb-6"
  >
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="flex w-1/2 md:w-1/4">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('webshops.filter.sort-on') }}
          </span>
          <select
            v-model="form.sort"
            class="block w-full rounded-full border border-blue-600 text-gray"
            @change="filter"
          >
            <option
              v-for="(value, key) in sortOptions"
              :key="`sort-option-${key}`"
              :value="key"
            >
              {{ value }}
            </option>
          </select>
        </label>
      </div>
    </form>
  </section>

  <section
    v-if="theme.media1"
    class="container flex justify-center mb-6"
  >
    <img
      :src="theme.media1.url"
      :alt="theme.name"
    >
  </section>

  <section class="container mb-6 md:mb-12">
    <div class="grid">
      <div class="grid gap-2.5 md:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-4">
        <WebshopBlock
          v-for="(webshop, i) in webshops"
          :key="`webshop-${i}`"
          :webshop="webshop"
          :show-media-type="theme.block_type"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import WebshopBlock from '../../Components/Webshop/Block.vue';

export default {
  components: {
    WebshopBlock,
  },

  props: {
    theme: {
      type: Object,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },

    sortOptions: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      sort: props.filters.sort,
      theme: props.theme ? props.theme.slug : null,
    });

    return {
      form,
    };
  },

  methods: {
    filter() {
      this.$inertia.get(this.route('frontend.webshop-theme.show', {webshopTheme: this.form.theme}), {
          sort: this.form.sort,
        });
    },
  },
};
</script>
