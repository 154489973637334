<template>
  <Head :title="__('contact.title')" />

  <Breadcrumbs
    :current-page="__('contact.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('contact.title') }}
          </h1>
          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('contact.text') }}
            </p>
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <i18n-t
            v-if="form.wasSuccessful"
            tag="div"
            keypath="contact.success-message"
            class="my-4 whitespace-pre-line"
          >
            <template
              v-if="member"
              #name
            >
              {{ member.first_name }} {{ member.last_name }}
            </template>
            <template
              v-else
              #name
            />
            <template #faqLink>
              <Link
                :href="route('frontend.faq')"
                class="text-orange underline hover:no-underline"
              >
                {{ __('contact.success-message-faq') }}
              </Link>
            </template>
          </i18n-t>

          <form
            v-else
            @submit.prevent="form.post(route('frontend.contact.store'), {
              onSuccess: () => form.reset(),
            })"
          >
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.category') }}:
                </label>
                <select v-model="form.category">
                  <option
                    v-for="(category, value) in $tm('contact.categories')"
                    :key="value"
                    :value="$rt(category)"
                  >
                    {{ $rt(category) }}
                  </option>
                </select>
                <p
                  v-if="form.errors.category"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.category }}
                </p>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.full_name') }}:
                </label>
                <input
                  v-model="form.name"
                  type="text"
                  autocomplete="name"
                  required
                >
                <p
                  v-if="form.errors.name"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.name }}
                </p>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.emailaddress') }}:
                </label>
                <input
                  v-model="form.email"
                  type="email"
                  autocomplete="email"
                  required
                >
                <p
                  v-if="form.errors.email"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.email }}
                </p>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.subject') }}:
                </label>
                <input
                  v-model="form.subject"
                  type="text"
                  required
                >
                <p
                  v-if="form.errors.subject"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.subject }}
                </p>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.attachment') }}:
                </label>
                <input
                  type="file"
                  @input="setAttachment($event)"
                >
                <p
                  v-if="attachmentExceedsSize"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ __('general.max_file_size', {'limit': attachmentLimit + 'MB'}) }}
                </p>
                <p
                  v-if="form.errors.attachment"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.attachment }}
                </p>
              </div>

              <div class="flex flex-col md:flex-row md:items-start">
                <label>
                  {{ __('general.message') }}:
                </label>
                <textarea
                  v-model="form.message"
                  rows="4"
                  required
                />
                <p
                  v-if="form.errors.message"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.message }}
                </p>
              </div>

              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <BlueButton
                    :disabled="form.processing"
                  >
                    {{ __('contact.button') }}
                  </BlueButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    BlueButton,
  },

  props: {
    attachmentLimit: {
      type: Number,
      required: true,
    },

    member: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup() {
    const form = useForm({
      category: '',
      email: '',
      name: '',
      subject: '',
      attachment: null,
      message: '',
    });

    return {
      form,
      attachmentExceedsSize: ref(false),
    };
  },

  methods: {
    setAttachment(e) {
      const field = e.target.files[0];
      this.form.attachment = field;

      const fileLimitBytes = this.attachmentLimit * 1000000;
      this.attachmentExceedsSize = false;
      if (field.size > fileLimitBytes) {
        this.attachmentExceedsSize = true;
        this.form.attachment = null;
      }
    },
  },
};
</script>
