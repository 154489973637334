<template>
  <Head :title="__('balance.surveys.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.overview.title'), href: route('frontend.balance') },
    ]"
    :current-page="__('balance.surveys.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('balance.surveys.title') }}
    </h1>
  </section>

  <section class="container mb-6 md:mb-4">
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="flex w-1/2 md:w-1/4 mr-2.5">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('balance.filter.search') }}
          </span>
          <input
            v-model="form.q"
            type="text"
            :placeholder="__('balance.filter.search-placeholder')"
            @keyup="filter"
          >
        </label>
      </div>
      <div class="flex w-1/2 md:w-1/4">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('balance.filter.date') }}
          </span>
          <DatePicker
            v-model="form.date"
            :placeholder="__('balance.filter.date-placeholder')"
          />
        </label>
      </div>
    </form>
  </section>

  <section class="container mb-8">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4 divide-solid divide-y">
      <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm font-bold w-full">
        <div class="w-4/12 md:w-8/12">
          {{ __('balance.description') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.date') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.qoins') }}
        </div>
      </div>

      <div
        v-if="form.processing"
        class="flex justify-center p-4"
      >
        <span class="material-symbols-outlined animate-spin">autorenew</span>
      </div>

      <template v-else-if="surveys.meta.pagination.total > 0">
        <div
          v-for="survey in surveys.data"
          :key="survey.id"
          class="py-2"
        >
          <div class="flex w-full mb-1 text-sm md:text-base">
            <div class="w-4/12 md:w-8/12">
              {{ survey.name }}

              <div
                class="flex items-center w-full text-xs"
                :class="{
                  'text-green-500': survey.pending === false,
                  'text-gray-600': survey.pending === true,
                }"
              >
                <span
                  class="h-2 w-2 rounded-full mr-2"
                  :class="{
                    'bg-green-500': survey.pending === false,
                    'bg-gray-600': survey.pending === true,
                  }"
                />

                {{ __(`balance.surveys.status.${survey.pending ? 'pending' : 'approved'}`, {approvedDate: survey.approvedDate}) }}
              </div>
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ survey.date }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ survey.value }}
            </div>
          </div>
        </div>
      </template>

      <p
        v-else
        class="text-sm md:text-base text-center py-2"
      >
        {{ __('balance.surveys.no-data') }}
      </p>

      <div
        v-if="surveys.meta.pagination.links.length > 3"
        class="pt-4"
      >
        <Pagination :pagination="surveys.meta.pagination" />
      </div>
    </div>
  </section>

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-0">
      {{ __('balance.surveys.manual-rewards-title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4 divide-solid divide-y">
      <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm font-bold w-full">
        <div class="w-4/12 md:w-8/12">
          {{ __('balance.description') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.date') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.qoins') }}
        </div>
      </div>

      <template v-if="manualSurveys.meta.pagination.total > 0">
        <div
          v-for="survey in manualSurveys.data"
          :key="survey.id"
          class="py-2"
        >
          <div class="flex w-full mb-1 text-sm md:text-base">
            <div class="w-4/12 md:w-8/12">
              {{ survey.name }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ survey.date }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ survey.value }}
            </div>
          </div>
        </div>
      </template>

      <p
        v-else
        class="text-sm md:text-base text-center py-2"
      >
        {{ __('balance.surveys.no-data') }}
      </p>

      <div
        v-if="manualSurveys.meta.pagination.links.length > 3"
        class="pt-4"
      >
        <Pagination :pagination="manualSurveys.meta.pagination" />
      </div>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Faq from './Components/Faq.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Pagination from '../../Components/Pagination.vue';
import DatePicker from '../../Components/Form/DatePicker.vue';

export default {
  components: {
    Faq,
    Breadcrumbs,
    Pagination,
    DatePicker,
  },

  props: {
    surveys: {
      type: Object,
      required: true,
    },

    manualSurveys: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    faqItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data(props) {
    return {
      filterTimer: null,
      form: useForm({
        q: props.filter.q ?? null,
        date: props.filters.date ?? {
          startDate: '',
          endDate: '',
        },
      }),
    };
  },

  watch: {
    'form.date': {
      handler(newDate, oldDate) {
        if (newDate.startDate !== oldDate.startDate || newDate.endDate !== oldDate.endDate) {
          this.filter();
        }
      },

      deep: true,
    },
  },

  methods: {
    filter() {
      if (this.filterTimer) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(() => {
        this.form.get(this.route('frontend.balance.surveys'), {
          preserveScroll: true,
          preserveState: true,
          only: ['surveys', 'filters'],
        });
      }, 800);
    },
  },
};
</script>
