<template>
  <div
    v-if="noItemsText && items.length === 0"
  >
    <i>{{ noItemsText }}</i>
  </div>

  <div
    v-for="item in pagedItems"
    :key="item"
  >
    <slot
      name="item"
      :item="item"
    />
  </div>

  <nav
    v-if="totalPages > 1"
    class="flex items-center"
  >
    <div
      class="flex justify-center items-center w-full text-xs rounded-lg py-3 space-x-4"
    >
      <span
        v-if="!hasPrevious"
        class="underline opacity-50"
      >
        {{ previousText }}
      </span>
      <span
        v-if="hasPrevious"
        class="underline hover:no-underline cursor-pointer"
        @click="changePage(previousPage)"
      >
        {{ previousText }}
      </span>

      <div
        v-for="page in totalPages"
        :key="page"
      >
        <span
          v-if="current === page"
          class="font-bold"
        >
          {{ page }}
        </span>
        <span
          v-if="current !== page"
          class="underline hover:no-underline cursor-pointer"
          @click="changePage(page)"
        >
          {{ page }}
        </span>
      </div>

      <span
        v-if="hasNext"
        class="underline hover:no-underline cursor-pointer"
        @click="changePage(nextPage)"
      >
        {{ nextText }}
      </span>
      <span
        v-if="!hasNext"
        class="underline opacity-50"
      >
        {{ nextText }}
      </span>
    </div>
  </nav>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    perPage: {
      type: Number,
      required: true,
    },

    previousText: {
      type: String,
      required: true,
    },

    nextText: {
      type: String,
      required: true,
    },

    noItemsText: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      current: 1,
    };
  },

  computed: {
    totalPages: function () {
      return Math.ceil(Object.keys(this.items).length / this.perPage);
    },

    pagedItems: function () {
      const start = (this.current * this.perPage) - this.perPage;
      const end = start + this.perPage;
      return this.items.slice(start, end);
    },

    nextPage: function () {
      return this.current + 1;
    },

    previousPage: function () {
      return this.current - 1;
    },

    hasPrevious: function () {
      return this.current > 1;
    },

    hasNext: function () {
      return this.current < this.totalPages;
    },
  },

  methods: {
    changePage: function (page) {
      if (page > 0 && page <= this.totalPages) {
        this.current = page;
      }
    },
  },
};
</script>
