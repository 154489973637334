<template>
  <Head :title="__('passwords.forgot-page.title')" />

  <Breadcrumbs
    :current-page="__('passwords.forgot-page.title')"
  />

  <section>
    <div class="container mb-6">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-7">
        {{ __('passwords.forgot-page.title') }}
      </h1>

      <div class="flex flex-row">
        <div class="md:bg-white md:shadow w-full lg:w-7/12 md:p-6">
          <p v-if="status">
            {{ status }}
          </p>
          <div v-else>
            <p class="mb-4">
              {{ __('passwords.forgot-page.text') }}
            </p>

            <form
              @submit.prevent="$refs.recaptchaForm.FormSubmit()"
            >
              <div class="flex flex-col space-y-4">
                <div class="flex flex-col md:flex-row md:items-center">
                  <label for="email">
                    {{ __('general.emailaddress') }}:
                  </label>
                  <div class="md:w-2/3">
                    <input
                      v-model="form.email"
                      type="text"
                      name="email"
                      autocomplete="email"
                      required
                    >
                    <p
                      v-if="form.errors.email"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.email }}
                    </p>
                  </div>
                </div>

                <HiddenRecaptcha
                  ref="recaptchaForm"
                  :form="form"
                  @success="recaptchaSuccess"
                />

                <div class="flex justify-end">
                  <BlueButton
                    :disabled="form.processing"
                  >
                    {{ __('passwords.forgot-page.button') }}
                  </BlueButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';
import HiddenRecaptcha from '../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    Breadcrumbs,
    BlueButton,
    HiddenRecaptcha,
  },

  props: {
    status: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup() {
    const form = useForm({
      email: '',
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.password-forgot.store'));
    },
  },
};
</script>
