import BugsnagJs from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export default function install(app) {
  if (!window.Bugsnag.enabled) {
    return;
  }

  BugsnagJs.start({
    apiKey: window.Bugsnag.apiKey,
    appVersion: window.Bugsnag.appVersion,
    releaseStage: window.Bugsnag.releaseStage,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
      if (event.originalError?.name === 'AxiosError') {
        return false;
      }

      if (window.Bugsnag.metadata) {
        for (let key in window.Bugsnag.metadata) {
          event.addMetadata(key, window.Bugsnag.metadata[key]);
        }
      }
    },
  });

  app.use(BugsnagJs.getPlugin('vue'));
}
