<template>
  <Modal
    ref="modalLogout"
  >
    <template #modal-content>
      <div
        class="flex flex-col"
      >
        <div class="text-2xl font-bold self-center ">
          {{ __('general.logout') }}
        </div>

        <div class="text-sm	md:text-base mb-4 text-center">
          {{ __('general.logout_text', {count: unreadEmailsCount}) }}
        </div>

        <Blue
          :href="route('frontend.mailings.index')"
          target="_self"
          class="self-center mb-2 w-10/12"
          @click="closeModal"
        >
          {{ __('general.read_emails') }}
        </Blue>

        <Link
          method="post"
          :href="route('frontend.logout')"
          class="self-center text-blue hover:underline"
          as="button"
          @click="closeModal"
        >
          {{ __('general.logout') }}
        </Link>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import Blue from './Buttons/Blue.vue';

export default {
  components: {
    Modal,
    Blue,
  },

  props: {
    unreadEmailsCount: {
      type: Number,
      required: true,
    },
  },

  methods: {
    openModal: function () {
      this.$refs.modalLogout.openModal();
    },

    closeModal: function () {
      this.$refs.modalLogout.closeModal();
    },

  },
};
</script>
