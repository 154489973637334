<template>
  <Head :title="__('profile-questions.index.title')" />

  <Breadcrumbs
    :current-page="__('profile-questions.index.title')"
  />

  <section class="container flex flex-col mb-6">
    <div class="flex flex-col">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
        {{ __('profile-questions.index.title') }}
      </h1>
      <p class="text-sm font-bold">
        {{ __('profile-questions.index.text') }}
      </p>
    </div>
  </section>

  <section class="container flex flex-col mb-6">
    <h2 class="text-lg font-bold text-center mb-4">
      {{ __('profile-questions.index.questions.title') }}
    </h2>

    <div class="grid grid-cols-1 md:grid-cols-2 space-y-4 md:space-y-0 md:space-x-4">
      <div
        v-if="hasOpenQuestions"
        class="relative bg-white rounded shadow p-4"
      >
        <div class="absolute -top-2 left-4 rounded bg-gray-600 text-white text-2xs font-bold px-2 py-0.5">
          {{ __('profile-questions.index.questions.open.tag') }}
        </div>

        <div class="absolute top-4 right-4 flex rounded-full bg-gray-100 text-gray-500 border-2 border-gray-500 p-1.5">
          <span class="material-symbols-outlined font-bold">check</span>
        </div>

        <h3 class="text-lg font-bold mb-2">
          {{ __('profile-questions.index.questions.open.title') }}
        </h3>
        <p class="mb-6">
          {{ __('profile-questions.index.questions.open.text') }}
        </p>
        <Link
          class="flex items-center"
          :href="route('frontend.profile-questions.open')"
        >
          {{ __('profile-questions.index.questions.open.link') }}
          <img src="/qassa/images/blue-arrow.svg">
        </Link>
      </div>

      <div
        v-if="hasCompletedQuestions"
        class="relative bg-white rounded shadow p-4"
      >
        <div class="absolute -top-2 left-4 rounded bg-blue text-white text-2xs font-bold px-2 py-0.5">
          {{ __('profile-questions.index.questions.completed.tag') }}
        </div>

        <div class="absolute top-4 right-4 flex rounded-full bg-blue-lighter text-blue border-2 border-blue p-1.5">
          <span class="material-symbols-outlined font-bold">check</span>
        </div>

        <h3 class="text-lg font-bold mb-2">
          {{ __('profile-questions.index.questions.completed.title') }}
        </h3>
        <p class="mb-6">
          {{ __('profile-questions.index.questions.completed.text') }}
        </p>
        <Link
          class="flex items-center"
          :href="route('frontend.profile-questions.completed')"
        >
          {{ __('profile-questions.index.questions.completed.link') }}
          <img src="/qassa/images/blue-arrow.svg">
        </Link>
      </div>
    </div>
  </section>

  <section class="container mb-6">
    <p class="text-xs">
      <i18n-t
        keypath="profile-questions.index.footer-text"
        scope="global"
      >
        <template #contactEmail>
          <a
            :href="`mailto:${contactEmail}`"
            class="text-blue underline hover:no-underline"
          >
            {{ contactEmail }}
          </a>
        </template>
      </i18n-t>
    </p>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },

  props: {
    hasOpenQuestions: {
      type: Boolean,
      required: true,
    },

    hasCompletedQuestions: {
      type: Boolean,
      required: true,
    },

    contactEmail: {
      type: String,
      required: true,
    },
  },
};
</script>

