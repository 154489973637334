<template>
  <VueTailwindDatePicker
    v-model="date"
    :placeholder="placeholder"
    :options="{
      shortcuts: {
        today: __('date-picker.shortcuts.today'),
        yesterday: __('date-picker.shortcuts.yesterday'),
        past: period => __('date-picker.shortcuts.past', {period}),
        currentMonth: __('date-picker.shortcuts.currentMonth'),
        pastMonth: __('date-picker.shortcuts.pastMonth')
      },
      footer: {
        apply: __('date-picker.footer.apply'),
        cancel: __('date-picker.footer.cancel')
      }
    }"
    :formatter="{
      date: 'YYYY-MM-DD',
      month: 'MMM'
    }"
    :i18n="$page.props.language"
  />
</template>

<script setup>
import {defineProps, defineEmits, watch, ref} from 'vue';
import VueTailwindDatePicker from 'vue-tailwind-datepicker';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  placeholder: {
    type: String,
    required: false,
    default: null,
  },
});

const date = ref(props.modelValue);

watch(
  () => date.value,
  (newDate) => {
    emit('update:modelValue', newDate);
  },
);
</script>
