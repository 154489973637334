<template>
  <Head :title="__('blog.index.title')" />

  <Breadcrumbs
    v-if="tag || q"
    :pages="[
      { name: __('blog.index.title'), href: route('frontend.blogs')},
    ]"
    :current-page="tag ? tag : q"
  />
  <Breadcrumbs
    v-else
    :current-page="__('blog.index.title')"
  />
  <div class="container mb-48">
    <section class="container flex flex-col mb-16">
      <div class="flex flex-col text-center">
        <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
          {{ __('blog.index.title') }}
        </h1>
        <p>
          {{ __('blog.index.text') }}
        </p>
      </div>
    </section>

    <section class="container mb-8 md:mb-4">
      <form
        class="w-full flex md:justify-end items-center mb-8"
        @submit.prevent="submit"
      >
        <div class="flex w-1/2 md:w-1/4 mr-2.5">
          <label class="block w-full mb-0">
            <span class="text-2xs text-blue-600 font-bold mb-1.5">
              {{ __('blog.index.search-field-label') }}
            </span>
            <input
              v-model="qRef"
              type="text"
              :placeholder="__('blog.index.search-placeholder')"
              @keyup="submit"
            >
          </label>
        </div>
        <div>
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('blog.index.tags-field-label') }}
          </span>
          <select
            id="tag"
            v-model="tagRef"
            name="tag"
            @change="submit"
          >
            <option
              selected
              value
            >
              All
            </option>
            <option
              v-for="tagItem in availableTags.reduce((product, {name}) => [...product, name], [])"
              :key="tagItem"
              :value="tagItem"
            >
              {{ tagItem }}
            </option>
          </select>
        </div>
      </form>
    </section>

    <div class="flex flex-col">
      <div class="grid md:grid-cols-2 gap-8">
        <p
          v-if="!blogs.data"
          class="text-center col-span-2 text-gray-600"
        >
          {{ __('blog.index.no-results') }}
        </p>
        <Link
          v-for="blog in blogs.data"
          :key="blog.id"
          :href="route('frontend.blogs.show', blog.slug)"
          class="w-full text-center text-black no-underline"
        >
          <img
            :src="blog.media.url"
            alt="blog image"
            class="w-full"
          >

          <div class="px-4 py-8 bg-white shadow">
            <div class="flex flex-wrap justify-center gap-1.5 mb-4">
              <Link
                v-for="tagItem in blog.blogTags"
                :key="tagItem"
                :href="route('frontend.blogs', {tag: tagItem})"
                class="py-1 px-2 bg-blue text-white rounded text-xs no-underline"
              >
                {{ tagItem }}
              </Link>
            </div>
            <div>
              <div class="text-xl font-bold mb-2">
                <span>{{ blog.subject }}</span>
              </div>
              <p class="mb-4 text-sm">
                <span>{{ __('blog.index.updated', {date: blog.updated_at}) }}</span>
              </p>
              <div class="mb-8 md:mb-4">
                <span>
                  {{ blog.pre_header }}
                </span>
              </div>
              <p class="text-blue underline hover:no-underline">
                {{ __('blog.index.detail-link') }}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {router} from '@inertiajs/vue3';

const props = defineProps({
  blogs: {
    type: Object,
    required: true,
  },
  availableTags: {
    type: Array,
    required: false,
    default: () => [],
  },
  q: {
    type: String,
    required: false,
    default: null,
  },
  tag: {
    type: String,
    required: false,
    default: null,
  },
});

const tagRef = ref(props.tag);
const qRef = ref(props.q);

const submit = () => {
  router.get(route('frontend.blogs', {q: qRef.value, tag: tagRef.value}));
};
</script>
