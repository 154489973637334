<template>
  <Head :title="__('content.cookie-policy.title')" />

  <Breadcrumbs
    :current-page="__('content.cookie-policy.title')"
  />

  <section class="container my-6 md:my-12">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('content.cookie-policy.title') }}
    </h1>
    <p>
      <i18n-t
        keypath="content.cookie-policy.text"
        scope="global"
      >
        <template #link>
          <a
            :href="link"
            target="_blank"
          >
            {{ __('content.cookie-policy.link-text') }}
          </a>
        </template>
      </i18n-t>
    </p>
  </section>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
