<template>
  <Head :title="__('error.' + errorType + '.title')" />

  <section class="flex flex-col items-center h-screen bg-gradient-to-b from-white via-white to-blue-200">
    <div class="h-fit w-fit md:bg-white md:shadow-box rounded-lg md:border border-gray-lighter mt-6">
      <div class="container flex flex-col justify-center items-center py-12">
        <span class="material-symbols-outlined text-9xl text-red-600 mb-4">cancel</span>

        <h2 class="text-center text-2xl md:text-5xl font-bold mb-4">
          {{ __('error.' + errorType + '.title') }}
        </h2>
        <div class="max-w-xl mb-4 text-center">
          {{ __('error.' + errorType + '.error') }}
        </div>

        <Blue
          :href="route('frontend.index')"
          target="_self"
          class="w-80"
        >
          {{ __('error.' + errorType + '.button') }}
        </Blue>
      </div>
    </div>
  </section>
</template>

<script>
import CleanLayout from '../../Layout/Clean.vue';
import Blue from '../../Components/Buttons/Blue.vue';

export default {

  components: {
    Blue,
  },

  layout: CleanLayout,

  props: {
    errorType: {
      type: String,
      required: true,
    },
  },
};
</script>
