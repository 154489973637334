<template>
  <Splide
    :options="options"
    :has-track="false"
  >
    <div class="container relative">
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev absolute z-10 left-1 lg:-left-8 top-1/2 -translate-y-1/2 flex justify-center items-center h-8 w-8 bg-white shadow rounded-full cursor-pointer">
          <span class="material-symbols-outlined font-bold">
            chevron_left
          </span>
        </button>
        <button class="splide__arrow splide__arrow--next absolute z-10 right-1 lg:-right-8 top-1/2 -translate-y-1/2 flex justify-center items-center h-8 w-8 bg-white shadow rounded-full cursor-pointer">
          <span class="material-symbols-outlined font-bold pl-0.5">
            chevron_right
          </span>
        </button>
      </div>

      <SplideTrack>
        <SplideSlide
          v-for="webshop in webshops"
          :key="webshop.id"
          class="flex flex-col justify-center items-center !my-0.5"
        >
          <div class="h-full w-full">
            <WebshopBlock
              :key="`webshop-block-${webshop.id}`"
              :webshop="webshop"
              :hide-favorite="hideFavorite"
              :show-media-type="blockType"
            />
          </div>
        </SplideSlide>
      </SplideTrack>
    </div>
  </Splide>
</template>

<script>

import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import WebshopBlock from './Webshop/Block.vue';

export default {
  components: {
    Splide,
    SplideSlide,
    SplideTrack,
    WebshopBlock,
  },

  props: {
    webshops: {
      type: Object,
      required: true,
    },

    hideFavorite: {
      type: Boolean,
      required: false,
      default: true,
    },

    blockType: {
      type: String,
      required: false,
      default: null,
      validator(type) {
        return ['cover', 'rectangle', 'logo', 'logo_only', 'billboard', 'billboard_only'].includes(type);
      },
    },
  },

  data(props) {
    const configPerType = this.determinConfigPerType(props.blockType);

    return {
      options: {
        gap: configPerType.gap ?? '1rem',
        autoHeight: true,
        rewind: false,
        perPage: configPerType.desktop,
        pagination: false,
        padding:  configPerType.padding ?? 2,
        arrows: Object.keys(props.webshops).length > configPerType.desktop,
        fixedWidth: configPerType.fixedWidth ?? null,
        breakpoints: {
          640: {
            perPage: configPerType.mobile,
            arrows: Object.keys(props.webshops).length > configPerType.mobile,
          },

          1024: {
            perPage: configPerType.tablet,
            arrows: Object.keys(props.webshops).length > configPerType.tablet,
          },
        },
      },
    };
  },

  methods: {
    determinConfigPerType(blockType) {
      switch (blockType) {
        case 'billboard':
        case 'billboard_only':
          return {desktop: 1, tablet: 1, mobile: 1, gap: '0rem', padding: 0};
        case 'logo_only':
          return {desktop: 6, tablet:4, mobile: 2};
        default:
          return {desktop: 5, tablet: 3, mobile: 2};
      }
    },
  },
};
</script>
