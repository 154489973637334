<template>
  <Head :title="__('click-action.title')" />

  <Breadcrumbs :current-page="__('click-action.title')" />

  <section class="container mb-6 md:mb-12">
    <h1 class="text-3xl font-bold text-center mb-6">
      {{ __('click-action.title') }}
    </h1>

    <div class="flex flex-col items-center">
      <template v-if="clickActions.length > 0">
        <div class="grid grid-cols-2 xl:grid-cols-4 gap-3">
          <ClickActionBlock
            v-for="clickAction in clickActions"
            :key="`click-action-${clickAction.id}`"
            :click-action="clickAction"
          />
        </div>
      </template>
      <template v-else>
        <div class="mb-12">
          <p class="font-medium">
            <i>{{ __('click-action.no-click-actions') }}</i>
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import ClickActionBlock from '../../Components/ClickActionBlock.vue';

export default {
  components: {
    ClickActionBlock,
  },

  props: {
    clickActions: {
      type: Array,
      required: true,
    },
  },
};
</script>
