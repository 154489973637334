<template>
  <label>
    {{ label }}:
  </label>
  <div class="md:w-2/3">
    <div class="flex flex-row space-x-2.5">
      <select
        :value="day"
        :name="`${name}_day`"
        @change="$emit('update:day', $event.target.value)"
      >
        <option
          v-for="(optionLabel, value) in dayOptions"
          :key="`${name}-day-${value}`"
          :value="value"
        >
          {{ optionLabel }}
        </option>
      </select>

      <select
        :value="month"
        :name="`${name}_month`"
        @change="$emit('update:month', $event.target.value)"
      >
        <option
          v-for="(optionLabel, value) in monthOptions"
          :key="`${name}-month-${value}`"
          :value="value"
        >
          {{ optionLabel }}
        </option>
      </select>

      <select
        :value="year"
        :name="`${name}_year`"
        @change="$emit('update:year', $event.target.value)"
      >
        <option
          v-for="(optionLabel, value) in yearOptions"
          :key="`${name}-year-${value}`"
          :value="value"
        >
          {{ optionLabel }}
        </option>
      </select>
    </div>
    <p
      v-if="errors && errors[name]"
      class="text-orange text-xs italic mt-0.5"
    >
      {{ errors[name] }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    dayOptions: {
      type: Object,
      required: true,
    },

    monthOptions: {
      type: Object,
      required: true,
    },

    yearOptions: {
      type: Object,
      required: true,
    },

    year: {
      type: [Number, String, null],
      required: true,
    },

    month: {
      type: [Number, String, null],
      required: true,
    },

    day: {
      type: [Number, String, null],
      required: true,
    },

    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: ['update:day', 'update:month', 'update:year'],
};
</script>
