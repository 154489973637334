<template>
  <Head :title="__('faq.title')" />

  <Breadcrumbs
    :current-page="__('faq.title')"
  />

  <SearchBar :items="searchItems" />

  <section class="container mb-12">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
      <div
        v-for="category in categories"
        :key="category.id"
        :href="route('frontend.faq.category', category.id)"
        class="flex flex-col justify-between bg-white shadow p-4"
      >
        <div class="flex-grow flex flex-col">
          <h2 class="text-lg md:text-xl font-bold mb-1">
            {{ category.name }}
          </h2>

          <Link
            v-for="item in category.items.slice(0, 4)"
            :key="item.id"
            :href="route('frontend.faq.item', {item: item.id})"
            class="text-base mt-2"
          >
            {{ item.question }}
          </Link>
        </div>

        <div class="md:self-start mt-5">
          <Link
            class="flex items-center"
            :href="route('frontend.faq.category', category.id)"
          >
            {{ __('faq.show_more') }}
            <img src="/qassa/images/blue-arrow.svg">
          </Link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchBar from './Components/SearchBar.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    SearchBar,
    Breadcrumbs,
  },

  props: {
    searchItems: {
      type: Object,
      required: true,
    },

    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>
