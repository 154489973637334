<template>
  <Head :title="__('account.overview.title')" />

  <Breadcrumbs :current-page="__('account.overview.title')" />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <h1 class="text-2xl md:text-3xl font-bold mb-4">
        {{ __('account.overview.title') }}
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-7">
        <div class="flex flex-row md:flex-col flex-row-reverse justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              width="48"
              height="48"
              class="md:mb-2.5"
              src="/qassa/images/profile_settings.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('account.overview.personal-settings.title') }}
            </h2>

            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('account.overview.personal-settings.text') }}
            </p>

            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.account.profile')"
              >
                {{ __('account.overview.personal-settings.button') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>

        <div class="flex flex-row md:flex-col flex-row-reverse justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              width="48"
              height="48"
              class="md:mb-2.5"
              src="/qassa/images/mail-settings.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('account.overview.mail-settings.title') }}
            </h2>

            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('account.overview.mail-settings.text') }}
            </p>

            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.account.notifications')"
              >
                {{ __('account.overview.mail-settings.button') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>

        <div class="flex flex-row md:flex-col flex-row-reverse justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              width="48"
              height="48"
              class="md:mb-2.5"
              src="/qassa/images/payout-settings.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('account.overview.payout-settings.title') }}
            </h2>

            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('account.overview.payout-settings.text') }}
            </p>

            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.account.payout')"
              >
                {{ __('account.overview.payout-settings.button') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>

        <div class="flex flex-row md:flex-col flex-row-reverse justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              width="48"
              height="48"
              class="md:mb-2.5"
              src="/qassa/images/security-settings.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('account.overview.security-settings.title') }}
            </h2>

            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('account.overview.security-settings.text') }}
            </p>

            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.account.change-password')"
              >
                {{ __('account.overview.security-settings.button') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
</script>
