<template>
  <Modal
    ref="modal"
    :title="__('login.title')"
  >
    <template #modal-button>
      <button
        class="text-xs md:text-base underline hover:no-underline md:no-underline text-orange md:hover:text-white md:hover:bg-orange md:no-underline md:ring-1 md:ring-orange md:rounded-full md:px-5 md:py-1"
        @click="doOpenModal"
      >
        {{ __('header.menu.login') }}
      </button>
    </template>

    <template #modal-content="{ closeModal }">
      <form
        @submit.prevent="$refs.recaptchaForm.FormSubmit()"
      >
        <div class="flex flex-col space-y-4">
          <div>
            <div class="flex flex-col md:flex-row md:items-center">
              <label for="email">
                {{ __('general.emailaddress') }}:
              </label>
              <div class="relative md:w-4/6">
                <div class="absolute inset-y-0 left-0 w-10 pl-2 flex items-center">
                  <img src="/qassa/images/mail.svg">
                </div>
                <input
                  id="email"
                  v-model="form.email"
                  class="pl-10 pr-8"
                  type="email"
                  autocomplete="email"
                  required
                >
              </div>
            </div>
            <p
              v-if="form.errors.email"
              class="text-red-500 text-xs italic"
            >
              {{ form.errors.email }}

              <button
                v-if="form.errors.email === __('auth.not_exists_or_not_verified')"
                class="underline hover:no-underline"
                @click.prevent="form.post(route('frontend.login.resend-email-verification'))"
              >
                {{ __('auth.verification_email_link') }}
              </button>
            </p>
          </div>

          <div>
            <div class="flex flex-col md:flex-row md:items-center">
              <label for="password">{{ __('general.password') }}:</label>
              <Password v-model="form.password" />
            </div>
            <p
              v-if="form.errors.password"
              class="text-red-500 text-xs italic"
            >
              {{ form.errors.password }}
            </p>
          </div>

          <div class="flex justify-end">
            <div class="flex justify-between items-center text-sm w-full md:w-4/6">
              <div class="flex flex-row items-center">
                <input
                  id="remember"
                  v-model="form.remember"
                  type="checkbox"
                >
                <label
                  class="w-full ml-1 !mb-0"
                  for="remember"
                >
                  {{ __('general.remember') }}
                </label>
              </div>

              <Link
                :href="route('frontend.password-forgot')"
                @click="closeModal"
              >
                {{ __('login.password_forgot') }}
              </Link>
            </div>
          </div>

          <div class="flex md:justify-end">
            <HiddenRecaptcha
              ref="recaptchaForm"
              class="invisible"
              :form="form"
              @success="recaptchaSuccess"
            />

            <div class="flex flex-row items-center w-full md:w-4/6">
              <BlueButton
                :disabled="form.processing"
              >
                {{ __('login.button') }}
              </BlueButton>
            </div>
          </div>

          <hr class="w-full h-px bg-gray-500 border-0">

          <div class="flex justify-center">
            <BlueOpenButton
              :href="route('frontend.register')"
              classes="w-full md:w-auto md:px-8 text-orange border-orange"
              @click="closeModal"
            >
              {{ __('login.register') }}
            </BlueOpenButton>
          </div>

          <span class="text-gray-500 text-xs text-center pt-5">
            <i18n-t
              keypath="login.recaptcha"
              scope="global"
            >
              <template #policy>
                <a
                  class="underline text-gray-500"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  {{ __('login.google-policy') }}
                </a>
              </template>
              <template #terms>
                <Link
                  class="underline text-gray-500"
                  href="https://policies.google.com/terms"
                  target="_blank"
                >
                  {{ __('login.google-terms') }}
                </Link>
              </template>
            </i18n-t>
          </span>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Modal from './Modal.vue';
import Password from '../Components/Form/Password.vue';
import BlueButton from '../Components/Buttons/Blue.vue';
import BlueOpenButton from '../Components/Buttons/BlueOpen.vue';
import HiddenRecaptcha from './Form/HiddenRecaptcha.vue';

export default {
  components: {
    Modal,
    Password,
    BlueButton,
    BlueOpenButton,
    HiddenRecaptcha,
  },

  setup() {
    const form = useForm({
      email: null,
      password: null,
      remember: true,
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    doCloseModal: function () {
      if (this.$refs.modal) {
        this.$refs.modal.closeModal();
      }
    },

    doOpenModal: function () {
      this.$refs.modal.openModal();
    },

    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.login.store'), {
        preserveScroll: true,
        onSuccess: (event) => {
          this.pushDataLayer(event.props.auth.member);
          this.doCloseModal();
          this.form.reset();
        },
      });
    },

  },
};
</script>
