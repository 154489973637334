<template>
  <Head :title="__('puzzle.index.title')" />

  <Breadcrumbs
    :current-page="__('puzzle.index.title')"
  />

  <section class="container flex flex-col mb-6">
    <h1 class="text-3xl font-bold text-center mb-6">
      {{ __('puzzle.index.header') }}
    </h1>

    <div
      v-if="puzzles.length === 0"
      class="flex flex-col items-center"
    >
      <div class="mb-12">
        <p class="font-medium">
          <i>{{ __('puzzle.index.no_puzzles') }}</i>
        </p>
      </div>
    </div>

    <div
      v-else
      class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-8 mt-4"
    >
      <div
        v-for="puzzle of puzzles"
        :key="`puzzle-${puzzle.id}`"
        class="bg-white shadow-box p-3 md:p-4 rounded md:h-full mb-2 md:mb-0"
      >
        <h2 class="text-lg font-bold mb-4">
          {{ puzzle.title }}
        </h2>
        <img
          :src="puzzle.media?.url"
          :alt="puzzle.title"
          class="mb-4"
        >

        <span v-if="!puzzle.has_member_answer">
          <span class="font-serif font-bold">
            {{ puzzle.question }}
          </span>
          <p v-if="!puzzle.is_multiple_choice">
            <i18n-t
              keypath="puzzle.index.open_text"
              scope="global"
            >
              <template #reward>
                <strong>{{ puzzle.main_reward }}</strong>
              </template>
            </i18n-t>
          </p>
          <p v-else>
            <i18n-t
              keypath="puzzle.index.text"
              scope="global"
            >
              <template #reward>
                <strong>{{ puzzle.reward }}</strong>
              </template>
            </i18n-t>
          </p>

          <div v-if="puzzle.is_multiple_choice">
            <div
              v-for="answer of puzzle.answers"
              :key="`answer-${answer.id}`"
            >
              <input
                v-model="puzzleAnswers[puzzle.id]"
                :value="answer.id"
                :name="`answer_${puzzle.id}`"
                type="radio"
                class="inline-block shadow-sm sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
              >
              <span class="ml-2">{{ answer.answer }}</span>
            </div>
          </div>
          <div v-else>
            <label class="mt-4">
              {{ __('puzzle.index.open_answer.label') }}:
            </label>
            <div class="w-full md:w-2/3">
              <textarea
                v-model="puzzleAnswers[puzzle.id]"
                class="h-48"
                required
              />
            </div>
          </div>

          <BlueButton
            classes="mt-4"
            @click="submitPuzzleAnswer(puzzle.id)"
          >
            {{ __('puzzle.index.button') }}
          </BlueButton>

          <p class="mt-4 text-sm">
            {{ __('puzzle.index.conditions') }}
          </p>
        </span>

        <div
          v-else
          class="block"
        >
          <div v-if="puzzle.is_multiple_choice">
            <span class="text-red font-serif font-bold mb-4 block">
              {{ __('puzzle.index.answered.title') }}
            </span>
            <span>
              <span v-if="puzzle.member_answer_is_correct">
                <i18n-t
                  keypath="puzzle.index.answered.correct"
                  scope="global"
                >
                  <template #reward>
                    <strong>{{ puzzle.reward }}</strong>
                  </template>
                </i18n-t>
              </span>
              <span v-else>
                {{ __('puzzle.index.answered.wrong') }}
              </span>
            </span>
          </div>
          <div v-else>
            <span class="text-red font-serif font-bold mb-4 block">
              {{ __('puzzle.index.answered.title') }}
            </span>
            <span>
              {{ __('puzzle.index.answered.message') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    BlueButton,
    Breadcrumbs,
  },

  props: {
    puzzles: {
      type: Array,
      required: true,
    },

    pollDifficulties: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const puzzleAnswers = {};

    for (const puzzle of props.puzzles) {
      puzzleAnswers[puzzle.id] = null;
    }

    return {
      puzzleAnswers,
    };
  },

  methods: {
    submitPuzzleAnswer(puzzleId) {
      if (!(puzzleId in this.puzzleAnswers) || this.puzzleAnswers[puzzleId] === null) {
        return;
      }

      this.$inertia.post(this.route('frontend.puzzles.store-puzzle-answer', {puzzle: puzzleId}), {
        answer: this.puzzleAnswers[puzzleId],
      });
    },
  },
};
</script>
