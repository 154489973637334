<template>
  <Link
    v-if="href && internal"
    :href="href"
    :class="twMerge('flex justify-center items-center text-white font-bold no-underline rounded-full w-full py-1 md:py-2 px-3 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button', classes)"
  >
    <slot />
    <span class="material-symbols-outlined text-white font-bold">
      chevron_right
    </span>
  </Link>

  <a
    v-else-if="href && !internal"
    :href="href"
    :target="target"
    :class="twMerge('flex justify-center items-center text-white font-bold no-underline rounded-full w-full py-1 md:py-2 px-3 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button', classes)"
  >
    <slot />
    <span class="material-symbols-outlined text-white font-bold">
      chevron_right
    </span>
  </a>

  <button
    v-else
    :class="twMerge('flex justify-center items-center text-white font-bold no-underline rounded-full w-full py-1 md:py-2 px-3 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button', classes)"
  >
    <slot />
    <span
      v-if="loading"
      class="material-symbols-outlined animate-spin text-white font-bold ml-1"
    >
      autorenew
    </span>
    <span
      v-else
      class="material-symbols-outlined text-white font-bold"
    >
      chevron_right
    </span>
  </button>
</template>

<script>
import { twMerge } from 'tailwind-merge';

export default {
  props: {
    href: {
      type: String,
      required: false,
      default: null,
    },

    internal: {
      type: Boolean,
      required: false,
      default: true,
    },

    target: {
      type: String,
      required: false,
      default: '_blank',
    },

    classes: {
      type: String,
      required: false,
      default: '',
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    twMerge,
  },
};
</script>
