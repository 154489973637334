<template>
  <section class="container flex justify-center">
    <div class="bg-white shadow rounded w-full lg:w-8/12 p-6 my-8">
      <p class="whitespace-pre-line text-center">
        {{ __('content.old-mailing-links.text', {liveDate: liveDate}) }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    liveDate: {
      type: String,
      required: true,
    },
  },
};
</script>
