<template>
  <LastEarnedCashbackSwiper :latest-earned-cashbacks="latestEarnedCashbacks" />

  <section
    v-for="webshopTheme in webshopThemes"
    :key="`webshop_theme_${webshopTheme.id}`"
    class="container mb-6 md:mb-12"
  >
    <Link
      class="no-underline w-full"
      :href="route('frontend.webshop-theme.show', webshopTheme.slug)"
    >
      <div
        v-if="webshopTheme.media2"
        class="aspect-[970/250] bg-cover w-full bg-origin-padding bg-center"
        :style="`background-image: url(${webshopTheme.media2.url})`"
      />
    </Link>
  </section>

  <WebshopSuggestions :webshop-suggestions="webshopSuggestions" />
</template>

<script>
import LastEarnedCashbackSwiper from '../../Components/LastEarnedCashbackSwiper.vue';
import WebshopSuggestions from '../../Components/WebshopSuggestions.vue';

export default {
  components: {
    WebshopSuggestions,
    LastEarnedCashbackSwiper,
  },

  props: {
    webshopSuggestions: {
      type: Array,
      required: true,
    },

    latestEarnedCashbacks: {
      type: Array,
      required: true,
    },

    webshopThemes: {
      type: Array,
      required: true,
    },
  },
};
</script>
