<template>
  <Head :title="webshop.name" />

  <Breadcrumbs
    :pages="[
      {name: 'Webshops', href: route('frontend.webshop-category.index')},
    ]"
    :current-page="webshop.name"
  />

  <section class="hidden md:inline">
    <div class="container mb-4">
      <h1 class="text-3xl font-bold">
        {{ webshop.name }}
      </h1>
    </div>
  </section>

  <section class="mb-6">
    <div class="container flex flex-col md:flex-row">
      <div class="flex-grow-0 w-full md:w-5/12 lg:w-1/3">
        <div class="flex flex-col bg-white shadow rounded  items-center space-y-2.5 md:space-y-4 p-6 mb-6 md:mr-6">
          <div
            v-if="webshop.logo"
            class="cursor-pointer"
            @click="clickout"
          >
            <img
              class="mx-auto"
              :src="webshop.logo.url"
              :alt="webshop.logo.name"
              :title="webshop.logo.name"
            >
          </div>

          <div
            v-if="webshop.rectangle"
            class="cursor-pointer"
            @click="clickout"
          >
            <img
              class="mx-auto"
              :src="webshop.rectangle.url"
              :alt="webshop.rectangle.name"
              :title="webshop.rectangle.name"
            >
          </div>

          <div class="w-full">
            <BlueButton @click="clickout">
              {{ __(`advertiser.button.${webshop.buttonType}`) }}
            </BlueButton>
          </div>

          <div
            class="group flex items-center text-xs text-blue cursor-pointer"
            @click="toggleFavorite"
          >
            <span
              v-if="savingFavorite"
              class="material-symbols-outlined animate-spin mr-2"
            >
              autorenew
            </span>
            <span
              v-else
              class="material-symbols-outlined cursor-pointer mr-2"
              :class="{'fill': isFavorite}"
            >
              favorite
            </span>
            <span
              v-if="isFavorite"
              class="underline group-hover:no-underline"
            >
              {{ __('webshop.favorite_remove') }}
            </span>
            <span
              v-else
              class="underline group-hover:no-underline"
            >
              {{ __('webshop.favorite_add') }}
            </span>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full md:w-7/12 lg:w-2/3">
        <div
          v-if="cashbackAllowed"
          class="bg-white shadow rounded mb-6"
        >
          <CommissionModels
            :webshop="webshop"
            :commission-models="commission.models"
          />
        </div>

        <div
          v-if="hasTerms"
          class="mt-6 md:mt-0"
        >
          <h2 class="text-lg font-bold">
            {{ __('webshop.terms.title') }}
          </h2>

          <div class="p-3 md:p-0 mb-4 w-full">
            <div v-if="terms['General']">
              <h3 class="font-bold mb-4">
                {{ __cashback(webshop.usePoints, 'webshop.conditions') }}
              </h3>

              <ul class="list-inside list-disc px-4 mb-4 space-y-2">
                <li
                  v-for="term in terms['General']"
                  :key="term.id"
                >
                  {{ term.description }}
                </li>
              </ul>
            </div>

            <div v-if="terms['No cashback']">
              <h3 class="font-bold mb-4">
                {{ __cashback(webshop.usePoints, 'webshop.no-cashback-information') }}
              </h3>

              <ul class="list-inside list-disc px-4 mb-4 space-y-2">
                <li
                  v-for="term in terms['No cashback']"
                  :key="term.id"
                >
                  {{ term.description }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Reviews
    :reviews="reviews"
    :rating="webshop.rating"
    :slug="webshop.slug"
    :eligible-for-review="eligibleForReview"
  />
</template>

<script>
import axios from 'axios';
import {router} from '@inertiajs/vue3';
import CommissionModels from './Components/CommissionModels.vue';
import Reviews from './Components/Reviews.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    CommissionModels,
    Reviews,
    BlueButton,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    commission: {
      type: Object,
      required: true,
    },

    terms: {
      type: Object,
      required: true,
    },

    canHaveCashback: {
      type: Boolean,
      required: true,
    },

    reviews: {
      type: Object,
      required: false,
      default: null,
    },

    cashbackAllowed: {
      type: Boolean,
      required: true,
    },

    eligibleForReview: {
      type: Boolean,
      required: true,
    },
  },

  data(props) {
    return {
      savingFavorite: false,
      loadingFavoriteCount: false,
      isFavorite: props.webshop.isFavorite,
    };
  },

  computed: {
    hasTerms() {
      return ('General' in this.terms && this.terms['General'].length > 0)
        || ('No cashback' in this.terms && this.terms['No cashback'].length > 0);
    },
  },

  methods: {
    clickout() {
      if (this.$page.props.auth.member && this.canHaveCashback) {
        window.open(this.route('frontend.clickout.webshop', {webshop: this.webshop.slug, origin: 'website'}));
      } else if (this.$page.props.auth.member && !this.canHaveCashback) {
        this.$parent.$refs.alreadyParticipated.openModal(this.route('frontend.clickout.webshop', {
          webshop: this.webshop.slug,
          origin: 'website',
        }));
      } else {
        this.$parent.$refs.loginModal.doOpenModal();
      }
    },

    toggleFavorite() {
      if (!this.$page.props.auth.member) {
        this.$parent.$refs.loginModal.doOpenModal();
        return;
      }

      this.savingFavorite = true;

      axios.post(this.route('frontend.api.webshop.favorite', {webshop: this.webshop.id}), {
        attach: !this.isFavorite,
      })
        .then(() => {
          this.isFavorite = !this.isFavorite;
          this.savingFavorite = false;


          router.reload({
            only: ['webshopFavoriteCount'],
            onBefore: () => {
              this.loadingFavoriteCount = true;
            },
            onFinish: () => {
              this.loadingFavoriteCount = false;
            },
          });
        })
        .catch(() => {
          this.savingFavorite = false;
        });
    }
    ,
  },
};
</script>
