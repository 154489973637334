<template>
  <Head :title="__('famous-webshop.title')" />

  <Breadcrumbs :current-page="__('famous-webshop.title')" />

  <section class="container mb-6 md:mb-12">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 text-center">
      {{ __('famous-webshop.title') }}
    </h1>

    <div class="grid">
      <div class="grid gap-2.5 md:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-4">
        <template
          v-for="webshop in itemsForPage"
          :key="`webshop-${webshop.id}`"
        >
          <WebshopBlock :webshop="webshop" />
        </template>
      </div>
    </div>

    <template v-if="needsPagination">
      <DynamicPagination
        :total-pages="totalPages"
        @paginate="(newPage) => page = newPage"
      />
    </template>
  </section>
</template>

<script>
import WebshopBlock from '../../Components/Webshop/Block.vue';
import DynamicPagination from '../../Components/DynamicPagination.vue';

export default {
  components: {
    WebshopBlock, DynamicPagination,
  },

  props: {
    famousWebshops: {
      type: Array,
      required: true,
    },
  },

  data() {
    const perPage = 15;
    const totalPages = Math.ceil(this.famousWebshops.length / perPage);

    return {
      page: 0,
      perPage,
      totalPages,
      needsPagination: totalPages > 1,
    };
  },

  computed: {
    itemsForPage() {
      const start = this.page * this.perPage;
      const end = start + this.perPage;

      return this.famousWebshops.slice(start, end);
    },
  },
};
</script>
