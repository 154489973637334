<template>
  <slot
    name="modal-button"
    :openModal="openModal"
  />

  <HeadlessTransitionRoot
    appear
    :show="isOpen"
    as="template"
  >
    <HeadlessDialog
      as="div"
      @close="closeModal"
    >
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <HeadlessDialogOverlay class="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
          </HeadlessTransitionChild>


          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block mt-36 align-bottom bg-white rounded text-left overflow-hidden shadow transform transition-all md:align-middle w-full md:max-w-lg"
            >
              <div class="bg-white p-7">
                <div class="absolute top-2.5 right-2.5 cursor-pointer flex flex-col items-center z-50">
                  <button @click="closeModal">
                    <img
                      src="/qassa/images/close_icon.svg"
                      alt=""
                    >
                  </button>
                </div>

                <h1 class="text-2xl font-bold mb-4">
                  {{ title }}
                </h1>

                <slot
                  name="modal-content"
                  :closeModal="closeModal"
                />
              </div>
            </div>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script>
import {ref} from 'vue';
import {Dialog as HeadlessDialog, DialogOverlay as HeadlessDialogOverlay, TransitionChild as HeadlessTransitionChild, TransitionRoot as HeadlessTransitionRoot} from '@headlessui/vue';

export default {
  components: {
    HeadlessTransitionRoot,
    HeadlessTransitionChild,
    HeadlessDialog,
    HeadlessDialogOverlay,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    open: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    const isOpen = ref(props.open);

    return {
      isOpen,
      closeModal() {
        isOpen.value = false;
      },

      openModal() {
        isOpen.value = true;
      },
    };
  },
};
</script>
