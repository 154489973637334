<template>
  <section
    v-if="faqItems.length > 0"
    class="container mb-6"
  >
    <div class="w-full bg-white mb-6 rounded-l shadow p-4">
      <h2 class="text-lg font-bold mb-4">
        {{ __('balance.faq.title') }}
      </h2>

      <ul class="list-none text-base space-y-3 mb-6">
        <li
          v-for="item in faqItems"
          :key="item.id"
        >
          <Link
            :href="route('frontend.faq.item', {item: item.id})"
            class="underline hover:no-underline"
          >
            {{ item.question }}
          </Link>
        </li>
      </ul>

      <p>
        <i18n-t
          keypath="balance.faq.footer_text"
          scope="global"
        >
          <template #link>
            <a
              :href="route('frontend.faq')"
              class="text-blue underline hover:no-underline"
            >
              {{ __('balance.faq.footer_text_link') }}
            </a>
          </template>
        </i18n-t>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    faqItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
