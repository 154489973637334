<template>
  <div class="md:w-4/6">
    <div class="relative">
      <div class="absolute inset-y-0 left-0 w-10 pl-2 flex items-center">
        <img src="/qassa/images/lock.svg">
      </div>
      <input
        ref="password"
        :value="modelValue"
        :type="showPassword ? 'text' : 'password'"
        :autocomplete="autocomplete"
        class="pl-10 pr-12"
        required
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <span
        class="material-symbols-outlined absolute top-2.5 right-4 text-blue-600 cursor-pointer"
        @click="showPassword = !showPassword"
        v-text="showPassword ? 'visibility_off' : 'visibility'"
      />
    </div>

    <slot name="errors" />
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },

    autocomplete: {
      type: String,
      required: false,
      default: 'current-password',
    },
  },

  emits: ['update:modelValue'],

  setup() {
    return {
      showPassword: ref(false),
    };
  },
};
</script>
