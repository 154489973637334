<template>
  <Head :title="__('content.cashbackplaza.title')" />

  <section class="flex flex-col items-center h-screen bg-gradient-to-b from-white via-white to-blue-200">
    <div class="h-fit w-fit md:bg-white md:shadow-box rounded-lg md:border border-gray-lighter mt-6">
      <div class="container flex flex-col justify-center items-center py-12">
        <h2 class="text-center text-2xl md:text-5xl font-bold mb-4">
          {{ __('content.cashbackplaza.title') }}
        </h2>
        <div class="max-w-xl mb-4 text-center whitespace-pre-line">
          {{ __('content.cashbackplaza.text') }}
        </div>

        <Blue
          :href="route('frontend.index')"
          target="_self"
          class="w-80"
        >
          {{ __('content.cashbackplaza.button') }}
        </Blue>
      </div>
    </div>
  </section>
</template>

<script>
import CleanLayout from '../../Layout/Clean.vue';
import Blue from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Blue,
  },

  layout: CleanLayout,
};
</script>
