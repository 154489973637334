<template>
  <div class="text-sm mb-4">
    <div class="border-gray-200 border flex flex-wrap justify-center divide-solid divide-x divide-gray-200 items-center bg-white md:mx-auto rounded">
      <button
        class="p-2 text-center"
        :class="{ 'pointer-events-none': currentPage === 0 }"
        @click="paginate(currentPage - 1)"
      >
        <span class="material-symbols-outlined align-middle">
          chevron_left
        </span>
      </button>

      <template
        v-for="page in pages"
        :key="`page-link-${page}`"
      >
        <button
          class="text-center py-2 px-3 hover:font-bold"
          :class="{ 'font-bold': currentPage === page }"
          @click="paginate(page)"
        >
          {{ page + 1 }}
        </button>
      </template>

      <button
        class="p-2 text-center"
        :class="{ 'pointer-events-none': currentPage === (totalPages - 1) }"
        @click="paginate(currentPage + 1)"
      >
        <span class="material-symbols-outlined align-middle">
          chevron_right
        </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: {
      type: Number,
      required: true,
    },
  },

  emits: ['paginate'],

  data() {
    return {
      currentPage: 0,
    };
  },

  computed: {
    pages() {
      const length = Math.min(this.totalPages, 6);

      let start = this.currentPage - Math.floor(length / 2);
      start = Math.max(start, 0);
      start = Math.min(start, this.totalPages - length);

      return Array.from({length}, (el, i) => start + i);
    },
  },

  methods: {
    paginate(newPage) {
      if (newPage < 0 || newPage > (this.totalPages - 1)) {
        return;
      }

      this.currentPage = newPage;
      this.$emit('paginate', newPage);
    },
  },
};
</script>
