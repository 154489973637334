<template>
  <Head :title="__('account.mail-settings.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"
    :current-page="__('account.mail-settings.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.mail-settings.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('account.mail-settings.subtitle') }}
            </p>

            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/mail-settings.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            class="form md:mt-4"
            @submit.prevent="form.post(route('frontend.account.notifications.store'))"
          >
            <div class="flex flex-col space-y-4">
              <template
                v-for="(types, group) in groupedTypes"
                :key="`notification_group-${group}`"
              >
                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __(`account.mail-settings.types.${group}`) }}:
                  </label>
                  <div class="md:w-2/3">
                    <select @change="(event) => setGroupValue(group, event.target.value)">
                      <option
                        v-for="type in types"
                        :key="`option-${type}`"
                        :value="type"
                        :selected="form[type] === true"
                      >
                        {{ __(`account.mail-settings.types.${type}`) }}
                      </option>
                      <option
                        value=""
                        :selected="hasNotGroupSelected(group)"
                      >
                        {{ __('account.mail-settings.types.none') }}
                      </option>
                    </select>
                  </div>
                </div>
              </template>

              <template
                v-for="type in singleTypes"
                :key="`notification-${type}`"
              >
                <div class="flex flex-col md:flex-row md:items-center">
                  <label>
                    {{ __(`account.mail-settings.types.${type}`) }}:
                  </label>
                  <div class="md:w-2/3">
                    <select v-model="form[type]">
                      <option :value="true">
                        {{ __('account.mail-settings.enabled') }}
                      </option>
                      <option :value="false">
                        {{ __('account.mail-settings.disabled') }}
                      </option>
                    </select>
                    <p
                      v-if="form.errors[type]"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors[type] }}
                    </p>
                  </div>
                </div>
              </template>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('account.mail-settings.vacation-mode') }}:
                </label>
                <div class="md:w-2/3">
                  <select
                    v-model="form.vacation_enabled"
                  >
                    <option :value="true">
                      {{ __('account.mail-settings.enabled') }}
                    </option>
                    <option :value="false">
                      {{ __('account.mail-settings.disabled') }}
                    </option>
                  </select>
                </div>
              </div>

              <template v-if="form.vacation_enabled">
                <div class="flex flex-col md:flex-row md:items-center">
                  <DateField
                    v-model:day="form.vacation_start_day"
                    v-model:month="form.vacation_start_month"
                    v-model:year="form.vacation_start_year"
                    name="vacation_start"
                    :label="__('account.mail-settings.vacation-start')"
                    :day-options="dayOptions"
                    :month-options="monthOptions"
                    :year-options="yearOptions"
                    :errors="form.errors"
                  />
                </div>

                <div class="flex flex-col md:flex-row md:items-center">
                  <DateField
                    v-model:day="form.vacation_end_day"
                    v-model:month="form.vacation_end_month"
                    v-model:year="form.vacation_end_year"
                    name="vacation_end"
                    :label="__('account.mail-settings.vacation-end')"
                    :day-options="dayOptions"
                    :month-options="monthOptions"
                    :year-options="yearOptions"
                    :errors="form.errors"
                  />
                </div>
              </template>

              <div
                v-if="Object.keys(optInAdvertisers).length"
                class="flex flex-col md:flex-row"
              >
                <label class="place-items-start">
                  {{ __('account.mail-settings.opt-in-advertisers') }}
                </label>
                <div class="md:w-2/3">
                  <div
                    v-for="(name, id) in optInAdvertisers"
                    :key="id"
                    class="flex flex-row space-x-2 items-center"
                  >
                    <input
                      v-model="form.member_advertisers"
                      :value="id"
                      type="checkbox"
                    >
                    <span class="">{{ name }}</span>
                  </div>
                </div>
              </div>
              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    class="btn text-sm md:text-base"
                    :disabled="form.processing"
                  >
                    {{ __('account.mail-settings.form.button') }}
                  </Blue>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import DateField from '../../../Components/Form/DateField.vue';
import Blue from '../../../Components/Buttons/Blue.vue';

export default {
  components: {
    DateField, Blue,
  },

  props: {
    availableTypes: {
      type: Array,
      required: true,
    },

    singleTypes: {
      type: Array,
      required: true,
    },

    groupedTypes: {
      type: Object,
      required: true,
    },

    memberTypes: {
      type: Array,
      required: true,
    },

    dayOptions: {
      type: Object,
      required: true,
    },

    monthOptions: {
      type: Object,
      required: true,
    },

    yearOptions: {
      type: Object,
      required: true,
    },

    vacationStart: {
      type: Object,
      required: false,
      default: null,
    },

    vacationEnd: {
      type: Object,
      required: false,
      default: null,
    },

    optInAdvertisers: {
      type: Object,
      required: false,
      default: null,
    },

    memberAdvertisers: {
      type: Array,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const formData = {
      vacation_enabled: !!(props.vacationStart && props.vacationEnd),
      vacation_start_day: props.vacationStart ? props.vacationStart.day : null,
      vacation_start_month: props.vacationStart ? props.vacationStart.month : null,
      vacation_start_year: props.vacationStart ? props.vacationStart.year : null,
      vacation_end_day: props.vacationEnd ? props.vacationEnd.day : null,
      vacation_end_month: props.vacationEnd ? props.vacationEnd.month : null,
      vacation_end_year: props.vacationEnd ? props.vacationEnd.year : null,
      member_advertisers: props.memberAdvertisers ? props.memberAdvertisers : null,
    };

    props.availableTypes.forEach(type => formData[type] = props.memberTypes.includes(type));

    const form = useForm(formData);

    return {form};
  },

  methods: {
    setGroupValue(group, value) {
      // unset all group values first
      this.groupedTypes[group].forEach(type => this.form[type] = false);

      if (value) {
        this.form[value] = true;
      }
    },

    hasNotGroupSelected(group) {
      for (const type of this.groupedTypes[group]) {
        if (this.form[type]) {
          return false;
        }
      }

      return true;
    },
  },
};
</script>
