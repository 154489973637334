<template>
  <div class="bg-white">
    <h2 class="text-lg md:text-xl font-bold p-4">
      {{ __cashback(webshop.usePoints, 'webshop.information') }}
    </h2>

    <div class="flex flex-wrap mt-4 mb-2 text-xs md:text-sm ">
      <span class="w-8/12 font-bold pl-3">
        {{ __('webshop.commissions-table.description') }}
      </span>
      <span class="w-4/12 font-bold pr-3">
        {{ __('webshop.commissions-table.points') }}
      </span>
    </div>

    <div class="bg-white rounded md:rounded-none divide-solid divide-y divide-gray-300 md:border-b md:border-t md:border-gray-300">
      <div
        v-for="commissionModel in itemsForPage"
        :key="`commission-model-${commissionModel.id}`"
        class="flex flex-wrap items-center py-2"
      >
        <span class="pl-3 w-8/12 leading-5 text-sm md:text-base">
          {{ commissionModel.description }}

          <span
            v-if="commissionModel.tags.length > 0"
            class="text-xs"
          >
            {{ buildCommissionModelTags(commissionModel.tags) }}
          </span>
        </span>
        <span class="w-4/12 text-xs leading-5 md:text-base pr-3">
          <span class="flex flex-col">
            <span class="font-bold">
              {{ commissionModel.formattedCashback }}
            </span>

            <span
              v-if="commissionModel.cashbackInfo"
              class="text-2xs md:text-xs"
            >
              {{ commissionModel.cashbackInfo }}
            </span>
          </span>
        </span>
      </div>
    </div>

    <div
      v-if="needsPagination"
      class="flex justify-center pt-4 mx-auto"
    >
      <DynamicPagination
        :total-pages="totalPages"
        class="!p-0"
        @paginate="(newPage) => page = newPage"
      />
    </div>
  </div>
</template>

<script>
import DynamicPagination from '../../../Components/DynamicPagination.vue';

export default {
  components: {
    DynamicPagination,
  },

  props: {
    webshop: {
      required: true,
      type: Object,
    },

    commissionModels: {
      required: true,
      type: Object,
    },
  },

  data() {
    const perPage = 4;
    const totalPages = Math.ceil(this.commissionModels.length / perPage);

    return {
      page: 0,
      perPage,
      totalPages,
      needsPagination: totalPages > 1,
    };
  },

  computed: {
    itemsForPage() {
      const start = this.page * this.perPage;
      const end = start + this.perPage;

      return this.commissionModels.slice(start, end);
    },
  },

  methods: {
    buildCommissionModelTags(commissionModelTags) {
      const commissionModelDescriptions = commissionModelTags.map((tag) => tag.description);

      return '(' + commissionModelDescriptions.join(', ') + ')';
    },
  },
};
</script>
