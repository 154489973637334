<template>
  <div class="flex flex-col md:flex-row md:items-center">
    <label>
      {{ __('general.date_of_birth') }}:
    </label>
    <div class="md:w-2/3 flex flex-col">
      <div class=" flex flex-row space-x-2.5">
        <select
          name="birth_date_day"
          autocomplete="bday-day"
          required
          :disabled="disabled"
          @change="$emit('update:day', $event.target.value)"
        >
          <option
            value=""
            disabled
            :selected="!day"
          >
            {{ __('general.day') }}
          </option>
          <option
            v-for="(label, value) in dayOptions"
            :key="`birthdate-day-${value}`"
            :value="value"
            :selected="day === value"
          >
            {{ label }}
          </option>
        </select>

        <select
          name="birth_date_month"
          autocomplete="bday-month"
          required
          :disabled="disabled"
          @change="$emit('update:month', $event.target.value)"
        >
          <option
            value=""
            disabled
            :selected="!month"
          >
            {{ __('general.month') }}
          </option>
          <option
            v-for="(label, value) in monthOptions"
            :key="`birthdate-month-${value}`"
            :value="value"
            :selected="month === value"
          >
            {{ label }}
          </option>
        </select>

        <select
          name="birth_date_year"
          autocomplete="bday-year"
          required
          :disabled="disabled"
          @change="$emit('update:year', $event.target.value)"
        >
          <option
            value=""
            disabled
            :selected="!year"
          >
            {{ __('general.year') }}
          </option>
          <option
            v-for="(label, value) in yearOptions"
            :key="`birthdate-year-${value}`"
            :value="value"
            :selected="year === value"
          >
            {{ label }}
          </option>
        </select>
      </div>

      <p
        v-if="errors && errors.birth_date"
        class="text-orange text-xs italic"
      >
        {{ errors.birth_date }}
      </p>
      <p
        v-else-if="invalidDate"
        class="text-orange text-xs italic"
      >
        {{ __('validation.date') }}
      </p>
    </div>
  </div>

  <div
    v-if="needsParentalControl"
    class="text-xs mt-3"
  >
    <div class="flex flex-col">
      <label class="flex items-center w-auto">
        <input
          :value="parentalApproval"
          class="self-start mt-1.5"
          type="checkbox"
          required
          @input="$emit('update:parental_approval', $event.target.checked ? true : null)"
        >
        <span class="ml-2">{{ __('register.parental_approval') }}</span>
      </label>

      <p
        v-if="errors && errors.parental_approval"
        class="text-orange text-xs italic"
      >
        {{ errors.parental_approval }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {ref} from 'vue';

export default {
  props: {
    dayOptions: {
      type: Object,
      required: true,
    },

    monthOptions: {
      type: Object,
      required: true,
    },

    yearOptions: {
      type: Object,
      required: true,
    },

    year: {
      type: String,
      required: true,
    },

    month: {
      type: String,
      required: true,
    },

    day: {
      type: String,
      required: true,
    },

    parentalApproval: {
      type: Boolean,
      required: false,
      default: null,
    },

    minimumAge: {
      type: Number,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    errors: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  emits: ['update:day', 'update:month', 'update:year', 'update:parental_approval'],

  setup(props) {
    let invalidDate = ref(false);

    const determineParentalControl = function(day, month, year, minimumAge) {
      invalidDate.value = false;
      if (!day || !month || !year || minimumAge <= 0) {
        return false;
      }

      day = ('0' + day).slice(-2);
      month = ('0' + month).slice(-2);
      year = '' + year;

      if (day.length < 2 || month.length < 2 || year.length < 4) {
        // prevent checkbox appearing when still typing
        return false;
      }

      const momentObject = moment('' + year + month + day, 'YYYYMMDD');
      if (!momentObject.isValid()) {
        invalidDate.value = true;
        return;
      }

      const age = moment().diff(moment('' + year + month + day, 'YYYYMMDD'), 'years');

      return age < minimumAge;
    };

    let needsParentalControl = props.errors && props.errors.parental_approval;

    return {
      needsParentalControl: ref(needsParentalControl),
      determineParentalControl: determineParentalControl,
      invalidDate: invalidDate,
    };
  },

  watch: {
    day(day) {
      this.needsParentalControl = this.determineParentalControl(day, this.month, this.year, this.minimumAge);
    },

    month(month) {
      this.needsParentalControl = this.determineParentalControl(this.day, month, this.year, this.minimumAge);
    },

    year(year) {
      this.needsParentalControl = this.determineParentalControl(this.day, this.month, year, this.minimumAge);
    },
  },
};
</script>
