<template>
  <Modal
    ref="modal"
    :title="__('account.payout-settings.bank-account.delete-title')"
  >
    <template #modal-button="{ openModal }">
      <button @click="openModal">
        <img
          class="absolute right-3 xl:right-2.5 top-7 xl:top-2.5"
          src="/qassa/images/delete-icon.svg"
          alt="x"
        >
      </button>
    </template>

    <template #modal-content="{ closeModal }">
      <p class="mb-4">
        {{ __('account.payout-settings.bank-account.confirm-delete') }}
      </p>

      <div class="flex justify-end">
        <button
          type="button"
          class="text-blue underline hover:no-underline"
          @click="closeModal"
        >
          {{ __('account.payout-settings.bank-account.cancel-delete') }}
        </button>
        <Link
          method="post"
          :data="{id: bankAccountId}"
          :href="route('frontend.account.payout.bank.destroy')"
          class="flex justify-center items-center text-white font-bold no-underline rounded-full py-2 px-6 ml-4 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button md:text-lg"
          as="button"
        >
          {{ __('account.payout-settings.bank-account.ok-delete') }}
        </Link>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    bankAccountId: {
      type: Number,
      required: true,
    },
  },
};
</script>
