<template>
  <Head :title="__('care-tip.index.title')" />

  <Breadcrumbs
    :current-page="__('care-tip.index.title')"
  />

  <section class="container flex flex-col mb-6">
    <div class="flex flex-col text-center">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
        {{ __('care-tip.index.title') }}
      </h1>
      <p>
        {{ __('care-tip.index.text') }}
      </p>
    </div>
  </section>

  <section class="container mb-6">
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="w-1/2 md:w-1/5">
        <label class="block w-full !pr-0">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('care-tip.index.sort-on') }}
          </span>
          <select
            v-model="form.sort"
            class="rounded-lg"
            @change="filter"
          >
            <option value="newest">
              {{ __('care-tip.index.sort-options.newest') }}
            </option>
            <option value="votes">
              {{ __('care-tip.index.sort-options.votes') }}
            </option>
          </select>
        </label>
      </div>
    </form>
  </section>

  <section
    v-if="tips.data && tips.data.length > 0"
    class="container flex-col mb-6"
  >
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      <div
        v-for="tip in tips.data"
        :key="tip.id"
        class="flex flex-col items-center space-y-2 bg-white rounded shadow p-4"
      >
        <p class="grow text-center whitespace-pre-wrap">
          {{ tip.text }}
        </p>

        <div class="text-gray-500">
          {{ tip.first_name }} - {{ tip.date }}
        </div>

        <div
          v-if="tip.voted"
          class="flex items-center text-orange"
        >
          <span class="material-symbols-outlined mr-2">thumb_up</span>
          <span>{{ __('care-tip.index.voted') }} ({{ tip.votesCount }})</span>
        </div>
        <Link
          v-else
          method="post"
          :href="route('frontend.care-tips.vote', [tip.id])"
          :preserve-scroll="true"
          class="group flex items-center text-orange cursor-pointer"
          as="button"
        >
          <span class="material-symbols-outlined mr-2">thumb_up</span>
          <span class="underline group-hover:no-underline">{{ __('care-tip.index.vote') }} ({{ tip.votesCount }})</span>
        </Link>
      </div>
    </div>

    <div
      v-if="tips.meta.pagination.links.length > 3"
      class="pt-4"
    >
      <Pagination :pagination="tips.meta.pagination" />
    </div>
  </section>
  <section
    v-else
    class="container mb-6"
  >
    <p class="text-sm md:text-base text-center py-2">
      {{ __('care-tip.index.no-data') }}
    </p>
  </section>

  <section class="container flex justify-center mb-6">
    <BlueButton
      :href="route('frontend.care-tips.create')"
      classes="md:w-auto"
    >
      {{ __('care-tip.index.button') }}
    </BlueButton>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Pagination from '../../Components/Pagination.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Breadcrumbs,
    Pagination,
    BlueButton,
  },

  props: {
    tips: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      sort: props.filters.sort,
    });

    return {
      form,
    };
  },

  methods: {
    filter() {
      let url = this.route('frontend.care-tips.index');

      this.$inertia.get(url, {
        sort: this.form.sort,
      });
    },
  },
};
</script>

