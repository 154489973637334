<template>
  <Head :title="__('tell-a-friend.index.title')" />

  <Breadcrumbs
    :current-page="__('tell-a-friend.index.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('tell-a-friend.index.title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <div class="mb-10 bg-white shadow p-4">
      <p class="whitespace-pre-wrap">
        <i18n-t
          keypath="tell-a-friend.index.text"
          scope="global"
        >
          <template #bonus>
            {{ bonus }}
          </template>

          <template #conditions>
            <Link
              :href="route('frontend.tell-a-friend.conditions')"
              class="text-blue underline hover:no-underline"
            >
              {{ __('tell-a-friend.index.text_conditions') }}
            </Link>
          </template>
        </i18n-t>
      </p>
    </div>

    <div class="mb-8">
      <div class="text-base font-bold mb-4">
        {{ __('tell-a-friend.index.sharing') }}
      </div>

      <div class="grid grid-cols-2 md:grid-cols-4 w-full lg:w-2/3 xl:w-1/2 gap-4 md:gap-8">
        <div
          class="group cursor-pointer flex flex-col items-center bg-white shadow p-4"
          @click="shareFacebook"
        >
          <div>
            <img
              src="/qassa/images/share/facebook.svg"
              :alt="__('tell-a-friend.index.share.facebook.name')"
            >
          </div>
          <span class="text-blue text-center underline mt-2 group-hover:no-underline">
            {{ __('tell-a-friend.index.share.facebook.name') }}
          </span>
        </div>

        <div
          class="group cursor-pointer flex flex-col items-center bg-white shadow p-4"
          @click="shareWhatsApp"
        >
          <div>
            <img
              src="/qassa/images/share/whatsapp.svg"
              :alt="__('tell-a-friend.index.share.whatsapp.name')"
            >
          </div>
          <span class="text-blue text-center underline mt-2 group-hover:no-underline cursor-pointer">
            {{ __('tell-a-friend.index.share.whatsapp.name') }}
          </span>
        </div>

        <div
          class="group cursor-pointer flex flex-col items-center bg-white shadow p-4"
          @click="shareEmail"
        >
          <div>
            <img
              src="/qassa/images/share/email.svg"
              :alt="__('tell-a-friend.index.share.email.name')"
            >
          </div>
          <span class="text-blue text-center underline mt-2 group-hover:no-underline">
            {{ __('tell-a-friend.index.share.email.name') }}
          </span>
        </div>

        <div
          class="group cursor-pointer relative flex flex-col items-center bg-white shadow p-4"
          @click="copyShareLink"
        >
          <div>
            <img
              src="/qassa/images/share/copy-link.svg"
              :alt="__('tell-a-friend.index.share.copy-link.name')"
            >
          </div>
          <span
            v-if="shareLinkCopied"
            class="absolute -bottom-6 text-orange"
          >
            {{ __('tell-a-friend.index.share.copy-link.copied') }}
          </span>
          <span class="text-blue text-center underline mt-2 group-hover:no-underline cursor-pointer">
            {{ __('tell-a-friend.index.share.copy-link.name') }}
          </span>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <div class="font-bold mb-4">
        {{ __('tell-a-friend.index.statistics') }}
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-5 gap-8">
        <div class="bg-white rounded-4xl shadow p-6 pb-3 text-center">
          <div class="text-xl font-bold mb-2">
            {{ __('tell-a-friend.index.statistics_total_earned') }}
          </div>
          <div class="flex items-center justify-center text-xl font-bold mb-4">
            {{ totalEarned }}
            <span class="material-symbols-outlined text-green-500 ml-1">check_circle</span>
          </div>
          <div class="text-sm">
            {{ __('tell-a-friend.index.statistics_approved_bonuses', {'approved': approved}) }}
          </div>
        </div>

        <div class="bg-white rounded-4xl shadow p-6 pb-3">
          <div class="flex items-center justify-between mb-6">
            <div class="font-bold">
              <span>{{ clicks }}</span>
              <span class="ml-2">{{ __('tell-a-friend.index.statistics_clicks') }}</span>
            </div>

            <div>
              <span class="material-symbols-outlined text-orange">ads_click</span>
            </div>
          </div>

          <div class="text-sm">
            {{ __('tell-a-friend.index.statistics_clicks_text') }}
          </div>
        </div>

        <div class="bg-white rounded-4xl shadow p-6 pb-3">
          <div class="flex items-center justify-between mb-6">
            <div class="font-bold">
              <span>{{ pending }}</span>
              <span class="ml-2">{{ __('tell-a-friend.index.statistics_pending') }}</span>
            </div>

            <div>
              <span class="material-symbols-outlined text-orange">flaky</span>
            </div>
          </div>

          <div class="text-sm">
            {{ __('tell-a-friend.index.statistics_pending_text') }}
          </div>
        </div>

        <div class="bg-white rounded-4xl shadow p-6 pb-3">
          <div class="flex items-center justify-between mb-6">
            <div class="font-bold">
              <span>{{ disapproved }}</span>
              <span class="ml-2">{{ __('tell-a-friend.index.statistics_disapproved') }}</span>
            </div>

            <div>
              <span class="material-symbols-outlined text-orange">highlight_off</span>
            </div>
          </div>

          <div class="text-sm">
            {{ __('tell-a-friend.index.statistics_disapproved_text') }}
          </div>
        </div>
      </div>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script>
import {ref} from 'vue';
import Faq from '../Balance/Components/Faq.vue';

export default {
  components: {
    Faq,
  },

  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },

    shareUrl: {
      type: String,
      required: true,
    },

    bonus: {
      type: String,
      required: true,
    },

    totalEarned: {
      type: String,
      required: true,
    },

    clicks: {
      type: String,
      required: true,
    },

    approved: {
      type: String,
      required: true,
    },

    pending: {
      type: String,
      required: true,
    },

    disapproved: {
      type: String,
      required: true,
    },

    faqItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      shareLinkCopied: ref(false),
    };
  },

  methods: {
    shareFacebook() {
      if (!this.isLoggedIn) {
        this.$parent.$refs.loginModal.doOpenModal();
      } else {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}&hashtag=%23qassa`, 'sharer-facebook', 'width=626,height=436');
      }
    },

    shareWhatsApp() {
      if (!this.isLoggedIn) {
        this.$parent.$refs.loginModal.doOpenModal();
      } else {
        window.open(`https://api.whatsapp.com/send?text=${this.__('tell-a-friend.index.share.whatsapp.text')}%0D%0A${this.shareUrl}`, 'sharer-whatsapp', 'width=626,height=436');
      }
    },

    shareEmail() {
      if (!this.isLoggedIn) {
        this.$parent.$refs.loginModal.doOpenModal();
      } else {
        window.open(`mailto:?subject=${this.__('tell-a-friend.index.share.email.subject')}&body=${this.__('tell-a-friend.index.share.email.body')} ${this.shareUrl}`, '_self');
      }
    },

    async copyShareLink() {
      if (!this.isLoggedIn) {
        this.$parent.$refs.loginModal.doOpenModal();
      } else {
        await navigator.clipboard.writeText(this.shareUrl).then(() => {
          this.shareLinkCopied = true;
          setTimeout(() => {
            this.$nextTick(() => {
              this.shareLinkCopied = false;
            });
          }, 3000);
        });
      }
    },
  },
};
</script>
