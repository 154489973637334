<template>
  <Head :title="__('favorite-webshop.title')" />

  <Breadcrumbs :current-page="__('favorite-webshop.title')" />

  <section class="container mb-6 md:mb-12">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 text-center">
      {{ __('favorite-webshop.title') }}
    </h1>

    <div class="relative flex justify-center items-center w-full">
      <div class="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-6">
        <SearchBar :input-placeholder="__('favorite-webshop.search-placeholder')" />
      </div>
    </div>

    <template v-if="webshops.meta.pagination.total > 0">
      <div class="grid">
        <div class="grid gap-2.5 md:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-4">
          <template
            v-for="webshop in webshopsData"
            :key="`webshop-${webshop.id}`"
          >
            <WebshopBlock
              :webshop="webshop"
              :can-remove-as-favorite="true"
              @unfavorited="removeFromWebshopsData"
            />
          </template>
        </div>
      </div>

      <Pagination
        v-if="webshops.meta.pagination.links.length > 3"
        :pagination="webshops.meta.pagination"
      />
    </template>
    <template v-else>
      <p class="text-sm md:text-base text-center mb-6">
        {{ __('favorite-webshop.no-favorites-title') }}
      </p>
      <p class="text-sm md:text-base text-center">
        {{ __('favorite-webshop.no-favorites-example', {webshop: suggestedWebshop.name}) }}
        <button
          class="text-blue underline hover:no-underline"
          @click="addSuggestedWebshop"
        >
          {{ __('favorite-webshop.no-favorites-add-button') }}
        </button>
        .
      </p>
    </template>
  </section>
</template>

<script>
import axios from 'axios';
import WebshopBlock from '../../Components/Webshop/Block.vue';
import SearchBar from '../../Components/Webshop/SearchBar.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    WebshopBlock, SearchBar, Pagination,
  },

  props: {
    suggestedWebshop: {
      type: Object,
      required: true,
    },

    webshops: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      removedWebshopsFromFavorites: [],
    };
  },

  computed: {
    webshopsData() {
      return this.webshops.data.filter((webshop) => !this.removedWebshopsFromFavorites.includes(webshop.id));
    },
  },

  methods: {
    addSuggestedWebshop() {
      axios.post(this.route('frontend.api.webshop.favorite', {webshop: this.suggestedWebshop.id}), {attach: true})
        .then(() => {
          this.$inertia.reload({
            preserveScroll: true,
            preserveState: true,
            only: ['webshops'],
          });
        });
    },

    removeFromWebshopsData(webshopId) {
      this.removedWebshopsFromFavorites.push(webshopId);
    },
  },
};
</script>
