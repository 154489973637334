<template>
  <Head :title="__('payout.payout-options.giftcard.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.title'), href: route('frontend.balance') },
      { name: __('payout.overview.title'), href: route('frontend.payout') },
    ]"
    :current-page="__('payout.payout-options.giftcard.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('payout.payout-options.giftcard.title') }}
          </h1>
          <div class="hidden lg:flex flex-col">
            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/payout-voucher.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            @submit.prevent="form.post(route('frontend.payout.giftcard.store'))"
          >
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('payout.payout-options.giftcard.shop') }}
                </label>
                <div class="md:w-2/3">
                  <select
                    v-model="form.shop"
                    :required="true"
                  >
                    <option
                      disabled
                      :value="null"
                    >
                      {{ __('payout.payout-options.giftcard.make_choice') }}
                    </option>
                    <option
                      v-for="shop in shops"
                      :key="`shop-${shop.id}`"
                      :value="shop.id"
                    >
                      {{ shop.name }}
                    </option>
                  </select>
                  <p
                    v-if="form.errors.shop"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.shop }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('payout.payout-options.giftcard.value') }}
                </label>
                <div class="md:w-2/3">
                  <select
                    v-model="form.giftcard"
                    :disabled="!form.shop"
                    :required="true"
                  >
                    <option
                      disabled
                      :value="null"
                    >
                      {{ __('payout.payout-options.giftcard.make_choice') }}
                    </option>
                    <option
                      v-for="giftcard in giftcards"
                      :key="`giftcard-${giftcard.value}`"
                      :value="giftcard.value"
                      :disabled="parseFloat(member.currentBalanceValue) < parseFloat(giftcard.value)"
                    >
                      {{ giftcard.text }}
                    </option>
                  </select>
                  <p
                    v-if="form.errors.giftcard"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.giftcard }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.password_account') }}:
                </label>
                <Password v-model="form.password">
                  <template #errors>
                    <p
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.password }}
                    </p>
                  </template>
                </Password>
              </div>

              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    :disabled="form.processing"
                  >
                    {{ __('payout.payout-options.giftcard.button') }}
                  </Blue>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Blue from '../../Components/Buttons/Blue.vue';
import Password from '../../Components/Form/Password.vue';

export default {
  components: {
    Password,
    Breadcrumbs,
    Blue,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    shops: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      shop: null,
      giftcard: null,
      password: '',
    });

    return {
      form,
    };
  },

  computed: {
    giftcards() {
      if (!this.form.shop) {
        return [];
      }

      return this.shops.find((shop) => shop.id === this.form.shop).giftcards;
    },
  },

};
</script>
