<template>
  <Head :title="__('tell-a-friend.conditions_page.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('tell-a-friend.index.title'), href: route('frontend.tell-a-friend.index') },
    ]"
    :current-page="__('tell-a-friend.conditions_page.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('tell-a-friend.conditions_page.title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <div class="flex flex-col md:flex-row md:justify-between">
      <div class="w-full mb-6 md:pr-8">
        <ul class="container list-disc text-blue-600">
          <li
            v-for="(condition, i) in conditions"
            :key="i"
          >
            <span class="text-gray">{{ condition }}</span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },

  props: {
    conditions: {
      type: Object,
      required: true,
    },
  },
};
</script>
