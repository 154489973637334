<template>
  <TransitionRoot
    :show="show"
    enter="transition-opacity duration-150"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-300"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <div
      v-if="$page.props.flash.success && show"
      class="container relative cursor-pointer z-50"
      @click="show = false"
    >
      <div class="flex absolute top-2 right-3 bg-white rounded-lg shadow p-3 min-w-1/2 md:min-w-1/4">
        <div class="shrink-0 mt-0.5">
          <CheckCircleIcon
            class="h-5 w-5 text-orange"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3">
          <h3
            v-if="$page.props.flash.success.title"
            class="text-orange font-bold"
          >
            {{ $page.props.flash.success.title }}
          </h3>
          <p>
            {{ $page.props.flash.success.message ?? $page.props.flash.success }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="$page.props.flash.error && show"
      class="container relative cursor-pointer z-50"
      @click="show = false"
    >
      <div class="flex absolute top-2 right-3 bg-white rounded-lg shadow p-3 min-w-1/2 md:min-w-1/4">
        <div class="shrink-0 mt-0.5">
          <XCircleIcon
            class="h-5 w-5 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3">
          <h3
            v-if="$page.props.flash.error.title"
            class="text-red-600 font-bold"
          >
            {{ $page.props.flash.error.title }}
          </h3>
          <p>
            {{ $page.props.flash.error.message ?? $page.props.flash.error }}
          </p>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot } from '@headlessui/vue';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/solid';

export default {
  components: {
    TransitionRoot,
    CheckCircleIcon,
    XCircleIcon,
  },

  data() {
    return {
      show: true,
    };
  },

  watch: {
    '$page.props.flash': {
      handler() {
        this.show = true;
      },

      deep: true,
    },
  },
};
</script>
