<template>
  <Head :title="__('mailings.advertiser-check.title')" />

  <section class="flex flex-col items-center h-screen bg-gradient-to-b from-white via-white to-blue-200">
    <div class="h-fit w-fit md:bg-white md:shadow-box rounded-lg md:border border-gray-lighter mt-6">
      <div class="container flex flex-col justify-center items-center py-12">
        <span
          v-if="hasError"
          class="material-symbols-outlined text-9xl text-red-600 mb-4"
        >cancel</span>

        <h2 class="text-center text-2xl md:text-5xl font-bold mb-4">
          {{ __('mailings.advertiser-check.title') }}
        </h2>
        <div class="max-w-xl mb-4 text-center">
          <span
            v-if="success"
            class="text-green-600"
          >
            {{ message }}
          </span>
          <span
            v-else
            class="text-red-600"
          >
            {{ message }}
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CleanLayout from '../../Layout/Clean.vue';

export default {
  layout: CleanLayout,

  props: {
    message: {
      type: String,
      required: true,
    },

    success: {
      type: Boolean,
      required: true,
    },

    hasError: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
