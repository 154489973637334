<template>
  <Modal :title="__('balance.payout_modal.title')">
    <template #modal-button="scope">
      <button
        class="text-blue underline hover:no-underline"
        @click="scope.openModal()"
      >
        {{ payout.value_points }}
      </button>
    </template>
    <template #modal-content="scope">
      <template v-if="payout.is_giftcard">
        <p v-if="payout.status === 'disapproved'">
          {{ __('balance.payout_modal.giftcard.disapproved') }}
        </p>
        <p v-else-if="payout.status === 'approved'">
          <span class="font-bold">{{ __('balance.payout_modal.giftcard.shop') }}:</span> {{ payout.giftcard.shop }}<br>
          <br>
          {{ __('balance.payout_modal.giftcard.approved') }}<br>
          <br>
          <Link
            :href="route('frontend.balance.payouts.resend-giftcard', {payout: payout.id})"
            method="post"
            as="button"
            class="flex justify-center items-center text-white font-bold no-underline rounded-full w-full py-2 px-6 bg-blue hover:bg-blue-dark focus:bg-blue-light border-b-2 border-blue-dark shadow-button md:text-lg md:py-3 disabled:opacity-60 disabled:hover:bg-blue"
            @click="scope.closeModal()"
          >
            {{ __('balance.payout_modal.giftcard.resend') }}
            <span class="material-symbols-outlined text-white font-bold">
              chevron_right
            </span>
          </Link>
        </p>
        <p v-else>
          <span class="font-bold">{{ __('balance.payout_modal.giftcard.shop') }}:</span> {{ payout.giftcard.shop }}<br>
          <br>
          {{ __('balance.payout_modal.giftcard.pending') }}
        </p>
      </template>
      <template v-else>
        <span class="font-bold">{{ __('balance.payout_modal.type') }}:</span> {{ payout.type }}<br>
        <span class="font-bold">{{ __('balance.payout_modal.receiver_account') }}:</span> {{ payout.receiver_account }}<br>
        <span class="font-bold">{{ __('balance.payout_modal.receiver_name') }}:</span> {{ payout.receiver_name }}<br>
        <span class="font-bold">{{ __('balance.payout_modal.amount') }}:</span> {{ payout.value_money }}<br>
        <br>
        <span v-if="payout.paid_at.length > 1">{{ __('balance.payout_modal.paid_at') }}: {{ payout.paid_at }}</span>
      </template>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../../Components/Modal.vue';

export default {
  components: {
    Modal,
  },

  props: {
    payout: {
      type: Object,
      required: true,
    },
  },

};
</script>
