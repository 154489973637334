<template>
  <Head :title="__('mailings.title')" />

  <Modal ref="modal">
    <template #modal-content>
      <p v-if="mailingSelected">
        {{ __('mailings.mail.route-outside-platform-text', {sent_at: mailingSelected.sent_at}) }}
      </p>
    </template>
  </Modal>

  <section>
    <div class="container mt-6 mb-4 md:mb-6">
      <div class="mb-0.5 flex justify-between items-center">
        <h1 class="text-2xl md:text-3xl font-bold">
          {{ __('mailings.title') }}
        </h1>

        <Link
          class="text-xs md:text-base text-blue no-underline hover:underline"
          :href="route('frontend.account.notifications')"
        >
          {{ __('mailings.settings-link') }}
        </Link>
      </div>

      <p class="text-sm md:text-base">
        {{ __('mailings.subtitle', {first_name: $page.props.auth.member.first_name}) }}
      </p>
    </div>
  </section>

  <WebshopSuggestions :webshop-suggestions="webshopSuggestions" />

  <section>
    <div class="sm:container lg:mb-6">
      <div class="container flex items-center bg-gray-200 py-3.5">
        <h2 class="text-lg md:text-xl font-bold">
          {{ __('mailings.unread-title') }}
        </h2>
      </div>

      <div class="bg-white divide-y divide-gray-300 border-b sm:border-l sm:border-r border-gray-300">
        <template
          v-for="mailing in unreadMailings"
          :key="`unread-mailing-${mailing.id}`"
        >
          <Mail
            :mailing="mailing"
            :is-read="false"
          />
        </template>
        <template v-if="unreadMailings.length === 0">
          <div class="container text-center py-4">
            {{ __('mailings.table.no-results') }}
          </div>
        </template>
      </div>
    </div>
  </section>

  <section>
    <div class="sm:container mb-6 lg:mb-12">
      <div class="container flex items-center bg-gray-200 py-3.5">
        <h2 class="text-lg md:text-xl font-bold">
          {{ __('mailings.read-title') }}
        </h2>
      </div>

      <div class="bg-white divide-y divide-gray-300 border-b sm:border-l sm:border-r border-gray-300">
        <template
          v-for="mailing in readMailings"
          :key="`unread-mailing-${mailing.id}`"
        >
          <Mail
            :mailing="mailing"
            :is-read="true"
          />
        </template>
        <template v-if="readMailings.length === 0">
          <div class="container text-center py-4">
            {{ __('mailings.table.no-results') }}
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import Modal from '../../Components/Modal.vue';
import Mail from './Mail.vue';
import WebshopSuggestions from '../../Components/WebshopSuggestions.vue';

export default {
  components: {
    WebshopSuggestions,
    Modal,
    Mail,
  },

  props: {
    unreadMailings: {
      type: Array,
      required: true,
    },

    readMailings: {
      type: Array,
      required: true,
    },

    webshopSuggestions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      mailingSelected: null,
    };
  },
};
</script>
