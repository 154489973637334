<template>
  <footer class="bg-white w-full">
    <div class="container grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-5 py-7 text-xs md:text-base">
      <div
        v-if="$page.props.instagramLink"
        class="text-center md:text-right order-first md:order-last mb-4 md:mb-0 flex flex-col items-center"
      >
        <a
          class="flex no-underline hover:underline decoration-gray-900"
          :href="$page.props.instagramLink"
          target="_blank"
        >
          <p class="flex items-center flex-row-reverse text-gray-900">
            {{ __('footer.follow_us') }}
          </p>

          <img
            class="ml-1 md:ml-2 h-6 md:h-8"
            src="/qassa/images/instagram.svg"
            alt=""
          >
        </a>
      </div>
      <div :class="{ [`text-center md:col-span-5 mt-1 md:mt-0`] : !$page.props.instagramLink, [`text-center md:text-left md:col-span-4 mt-1`] : $page.props.instagramLink}">
        <Link
          class="order-last md:order-first text-gray-900 no-underline hover:underline"
          :href="route('frontend.faq')"
        >
          {{ __('footer.faq_contact') }}
        </Link>
        -
        <Link
          class="text-gray-900 no-underline hover:underline"
          :href="route('frontend.terms')"
        >
          {{ __('footer.terms') }}
        </Link>
        -
        <Link
          class="text-gray-900 no-underline hover:underline"
          :href="route('frontend.privacy-policy')"
        >
          {{ __('footer.privacy_policy') }}
        </Link>
        -
        <Link
          class="text-gray-900 no-underline hover:underline"
          :href="route('frontend.cookie-policy')"
        >
          {{ __('footer.cookie_policy') }}
        </Link>
        <template v-if="country === 'de'">
          -
          <Link
            v-if="country === 'de'"
            class="text-gray-900 no-underline hover:underline"
            :href="route('frontend.impressum')"
          >
            {{ __('footer.impressum') }}
          </Link>
        </template>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    country: {
      type: String,
      required: true,
    },
  },
};
</script>
