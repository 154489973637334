<template>
  <Head :title="__('register.register')" />

  <Breadcrumbs :current-page="__('register.register')" />

  <section>
    <div class="container mb-6">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-7">
        {{ __('register.create_account') }}
      </h1>

      <div class="flex flex-row">
        <div class="md:bg-white md:shadow w-full lg:w-7/12 md:p-6">
          <form @submit.prevent="$refs.recaptchaForm.FormSubmit()">
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col md:flex-row md:items-center">
                <label
                  for="gender"
                >
                  {{ __('general.gender') }}:
                </label>

                <div class="md:w-2/3">
                  <select
                    v-model="form.gender"
                    required
                  >
                    <option
                      disabled
                      value=""
                    >
                      {{ __('general.gender-placeholder') }}
                    </option>
                    <option
                      v-for="(gender, i) in genders"
                      :key="`gender-${i}`"
                      :value="gender.id"
                    >
                      {{ gender.name }}
                    </option>
                  </select>

                  <p
                    v-if="form.errors.gender"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.gender }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.first_name') }}:
                </label>

                <div class="md:w-2/3">
                  <input
                    v-model="form.first_name"
                    type="text"
                    required
                  >
                  <p
                    v-if="form.errors.first_name"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.first_name }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label for="firstname">
                  {{ __('general.emailaddress') }}:
                </label>
                <div class="md:w-2/3">
                  <input
                    v-model="form.email"
                    type="email"
                    required
                  >
                  <p
                    v-if="form.errors.email"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.email }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center place-items-start">
                <label class="self-start mt-3">
                  {{ __('general.password') }}
                </label>
                <Password
                  v-model="form.password"
                  autocomplete="new-password"
                >
                  <template
                    v-if="form.errors.password"
                    #errors
                  >
                    <p
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ __('register.errors.password_requirements') }}
                    </p>
                    <ul class="list-disc space-y-1 ml-4 text-orange text-xs italic">
                      <li
                        v-for="error in form.errors.password.split('|')"
                        :key="error"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </template>
                </Password>
              </div>

              <BirthdateField
                v-model:year="form.birth_date_year"
                v-model:month="form.birth_date_month"
                v-model:day="form.birth_date_day"
                v-model:parental_approval="form.parental_approval"
                :day-options="days"
                :month-options="months"
                :year-options="years"
                :errors="form.errors"
                :minimum-age="minimumAge"
              />

              <div class="flex justify-end">
                <p class="text-sm md:text-base md:w-2/3">
                  <i18n-t
                    keypath="register.terms"
                    scope="global"
                  >
                    <template #participation>
                      <Link
                        :href="route('frontend.terms')"
                        class="underline"
                        target="_self"
                      >
                        {{ __('register.terms-participation') }}
                      </Link>
                    </template>
                    <template #privacy>
                      <Link
                        :href="route('frontend.privacy-policy')"
                        class="underline"
                        target="_self"
                      >
                        {{ __('register.terms-privacy') }}
                      </Link>
                    </template>
                  </i18n-t>
                </p>
              </div>

              <HiddenRecaptcha
                ref="recaptchaForm"
                :form="form"
                @success="recaptchaSuccess"
              />

              <div class="flex justify-end">
                <BlueButton
                  :disabled="form.processing"
                >
                  {{ __('register.button') }}
                </BlueButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BirthdateField from '../../Components/Form/BirthdateField.vue';
import HiddenRecaptcha from '../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    Password,
    BlueButton,
    Breadcrumbs,
    BirthdateField,
    HiddenRecaptcha,
  },

  props: {
    genders: {
      type: Array,
      required: true,
    },

    days: {
      type: Object,
      required: true,
    },

    months: {
      type: Object,
      required: true,
    },

    years: {
      type: Object,
      required: true,
    },

    minimumAge: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      email: '',
      first_name: '',
      password: '',
      gender: '',
      birth_date_year: '',
      birth_date_month: '',
      birth_date_day: '',
      parental_approval: null,
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.register.store'));
    },
  },
};
</script>
