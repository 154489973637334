<template>
  <Head :title="__('webshop-search.title', {count: webshops.meta.pagination.total, query})" />

  <Breadcrumbs
    :current-page="__('webshop-search.title', {count: webshops.meta.pagination.total, query})"
  />

  <section class="mb-6 md:mb-12">
    <div class="container flex flex-col items-center">
      <h1 class="text-2xl md:text-3xl font-bold mb-4">
        {{ __('webshop-search.results', {count: webshops.meta.pagination.total, query}) }}
      </h1>

      <template v-if="webshops.meta.pagination.total > 0">
        <div class="grid">
          <div class="grid gap-2.5 md:gap-6 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-4">
            <template
              v-for="webshop in webshops.data"
              :key="`webshop-${webshop.id}`"
            >
              <WebshopBlock :webshop="webshop" />
            </template>
          </div>
        </div>

        <Pagination
          v-if="webshops.meta.pagination.links.length > 3"
          :pagination="webshops.meta.pagination"
        />
      </template>
      <template v-else>
        <p class="text-sm md:text-base text-center mb-6">
          {{ __('webshop-search.no-results', {query}) }}
        </p>
      </template>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import WebshopBlock from '../../Components/Webshop/Block.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    WebshopBlock,
    Pagination,
  },

  props: {
    webshops: {
      type: Object,
      required: true,
    },

    query: {
      type: String,
      required: true,
    },
  },

  mounted() {
    axios.post(this.route('frontend.api.webshop-search-result'), {
      query: this.query,
      webshop_count: this.webshops.meta.pagination.total,
    });
  },
};
</script>
