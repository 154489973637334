<template>
  <Head :title="__('account.picture.title')" />

  <Breadcrumbs
    :pages="[
      {name: __('account.overview.title'), href: route('frontend.account')},
      {name: __('account.profile.title'), href: route('frontend.account.profile')},
    ]"
    :current-page="__('account.picture.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.picture.title') }}
          </h1>
          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('account.picture.subtitle') }}
            </p>

            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/profile_settings.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            class="form mt-4"
            @submit.prevent="form.post(route('frontend.account.picture.store'))"
          >
            <div class="mb-8">
              <div class="flex justify-between items-center">
                <div class="grow">
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    class="hidden"
                    @change="selectImage"
                  >
                  <label
                    for="file-upload"
                    class="text-base"
                  >
                    <span class="material-symbols-outlined text-orange">add_a_photo</span>
                    <span class="ml-2 underline group-hover:no-underline text-orange">{{ __('account.picture.select') }}</span>
                  </label>
                </div>

                <div>
                  <button
                    v-if="form.picture"
                    :disabled="form.processing"
                    class="md:hidden text-orange underline hover:no-underline"
                  >
                    {{ __('account.picture.button') }}
                  </button>
                </div>
              </div>
              <p
                v-if="form.errors.picture"
                class="text-orange text-xs italic mt-0.5"
              >
                {{ form.errors.picture }}
              </p>

              <div class="my-4">
                <img
                  ref="image"
                  :src="pictureUrl"
                  class="max-w-full"
                >
              </div>
            </div>

            <div class="hidden md:flex justify-end">
              <div class="w-full md:w-auto">
                <Blue
                  :disabled="form.processing"
                >
                  {{ __('account.picture.button') }}
                </Blue>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Cropper from 'cropperjs';
import {getCanvasResultForCroppedCanvas} from '../../../../../global/js/cropHelpers';
import Blue from '../../../Components/Buttons/Blue.vue';

export default {
  components: {Blue},
  props: {
    pictureUrl: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ['change'],

  setup() {
    const form = useForm({
      picture: null,
    });

    return {
      form,
    };
  },

  data() {
    return {
      cropper: null,
    };
  },

  mounted() {
    this.resetCropper();
  },

  methods: {
    resetCropper() {
      this.cropper?.destroy();

      this.cropper = new Cropper(this.$refs.image, {
        aspectRatio: 1,
        zoomable: false,
        crop: () => {
          const canvas = getCanvasResultForCroppedCanvas(this.cropper?.getCroppedCanvas({ maxHeight: 250, maxWidth: 250 }));

          if (canvas) {
            this.form.picture = canvas.toDataURL().split(';base64,')[1];
          }
        },
      });
    },

    selectImage(e) {
      if (e.target.files.length !== 1) {
        return;
      }

      const file = e.target.files[0];
      this.$refs.image.src = URL.createObjectURL(file);

      this.resetCropper();
    },
  },
};
</script>
