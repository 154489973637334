<template>
  <Link
    v-if="href && internal"
    :href="href"
    :class="twMerge('flex justify-center items-center text-sm text-blue font-bold no-underline rounded-full w-full py-1 border border-blue bg-white hover:bg-gray-100 md:py-2 md:text-base', classes)"
  >
    <slot />
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </Link>

  <a
    v-else-if="href && !internal"
    :href="href"
    :target="target"
    :class="twMerge('flex justify-center items-center text-sm text-blue font-bold no-underline rounded-full w-full py-1 border border-blue bg-white hover:bg-gray-100 md:py-2 md:text-base', classes)"
  >
    <slot />
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </a>

  <button
    v-else
    :class="twMerge('flex justify-center items-center text-sm text-blue font-bold no-underline rounded-full w-full py-1 border border-blue bg-white hover:bg-gray-100 md:py-2 md:text-base', classes)"
  >
    <slot />
    <span class="material-symbols-outlined">
      chevron_right
    </span>
  </button>
</template>

<script>
import {twMerge} from 'tailwind-merge';

export default {
  props: {
    href: {
      type: String,
      required: false,
      default: null,
    },

    internal: {
      type: Boolean,
      required: false,
      default: true,
    },

    target: {
      type: String,
      required: false,
      default: '_blank',
    },

    classes: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    twMerge,
  },
};
</script>
