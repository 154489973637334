<template>
  <section class="container text-xs text-gray-600 mt-3 md:mt-4 mb-6">
    <ul class="list-inside">
      <li class="inline-block mr-1">
        <Link
          :href="route('frontend.index')"
          class="text-orange underline hover:no-underline"
        >
          {{ __('tenant.name') }}
        </Link>
      </li>

      <li
        v-for="(page, i) in pages"
        :key="`breadcrumbs-${i}`"
        class="inline-block mr-1"
      >
        <span class="inline-block mr-1">
          &#187;
        </span>

        <Link
          :href="page.href"
          class="text-orange underline hover:no-underline"
        >
          {{ page.name }}
        </Link>
      </li>

      <li
        v-if="currentPage"
        class="inline-block mr-1"
      >
        <span class="inline-block mr-1">
          &#187;
        </span>

        <span>
          {{ currentPage }}
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      required: false,
      default: null,
    },

    currentPage: {
      type: String,
      required: true,
    },
  },
};
</script>
