<template>
  <Head :title="__('payout.overview.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.title'), href: route('frontend.balance') },
    ]"
    :current-page="__('payout.overview.title')"
  />
  <section class="mb-6 md:mb-12">
    <div class="container">
      <h1 class="text-2xl md:text-3xl font-bold mb-4">
        {{ __('payout.select_option') }}
      </h1>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-7">
        <div class="flex flex-row-reverse md:flex-col justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              class="md:mb-2.5"
              src="/qassa/images/payout-bank.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('payout.payout-options.bank.title') }}
            </h2>
            <span class="italic mb-1">
              {{ __('payout.minimum_balance', {minimum_balance: minimumPayoutValueBank}) }}
            </span>
            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('payout.payout-options.bank.info') }}
            </p>
            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.payout.bank')"
              >
                {{ __('payout.payout-options.bank.title') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>

        <div
          v-if="hasGiftcards"
          class="flex flex-row-reverse md:flex-col justify-between bg-white shadow p-4"
        >
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              class="md:mb-2.5"
              src="/qassa/images/payout-voucher.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('payout.payout-options.giftcard.title') }}
            </h2>
            <span class="italic mb-1">
              {{ __('payout.minimum_balance', {minimum_balance: minimumPayoutValueGiftcards}) }}
            </span>
            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('payout.payout-options.giftcard.info') }}
            </p>
            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.payout.giftcard')"
              >
                {{ __('payout.payout-options.giftcard.title') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>

        <div class="flex flex-row-reverse md:flex-col justify-between bg-white shadow p-4">
          <div class="flex-shrink-0 ml-1 mt-1 md:m-0">
            <img
              class="md:mb-2.5"
              src="/qassa/images/payout-charity.svg"
            >
          </div>
          <div class="flex-grow flex flex-col">
            <h2 class="text-lg md:text-xl font-bold mb-1">
              {{ __('payout.payout-options.charity.title') }}
            </h2>
            <span class="italic mb-1">
              {{ __('payout.minimum_balance', {minimum_balance: minimumPayoutValueCharity}) }}
            </span>
            <p class="mb-2 md:mb-4 flex-grow">
              {{ __('payout.payout-options.charity.info') }}
            </p>
            <div class="flex">
              <Link
                class="flex items-center"
                :href="route('frontend.payout.charity')"
              >
                {{ __('payout.payout-options.charity.title') }}
                <img src="/qassa/images/blue-arrow.svg">
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },

  props: {
    minimumPayoutValueBank: {
      type: String,
      required: true,
    },

    handlingCostsBank: {
      type: String,
      required: true,
    },

    minimumPayoutValueGiftcards: {
      type: String,
      required: true,
    },

    minimumPayoutValueCharity: {
      type: String,
      required: true,
    },

    hasGiftcards: {
      type: Boolean,
      required: true,
    },
  },

};
</script>
