<template>
  <QassaHead />

  <header class="bg-white py-4">
    <div class="container flex justify-center space-x-4">
      <Link :href="route('frontend.index')">
        <img
          class="h-full"
          src="/qassa/images/logo.svg"
        >
      </Link>

      <template v-if="$page.component === 'Mailing/Survey/Index'">
        <img
          class="h-[50px]"
          src="/qassa/images/panel-inzicht.png"
        >
      </template>
    </div>
  </header>

  <section class="grow">
    <slot />
  </section>


  <QassaFooter :country="country" />
</template>

<script>
import QassaHead from './Components/Head.vue';
import QassaFooter from './Components/Footer.vue';

export default {
  components: {
    QassaHead,
    QassaFooter,
  },

  props: {
    country: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
html, body, #app {
  @apply h-full;
}

#app {
  @apply flex flex-col;
}
</style>
