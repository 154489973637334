<template>
  <Head :title="__('account.overview.delete-account-settings.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"

    :current-page="__('account.overview.delete-account-settings.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.overview.delete-account-settings.title') }}
          </h1>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            @submit.prevent="$refs.recaptchaForm.FormSubmit()"
          >
            <div class="flex flex-col space-y-2">
              <p class="mb-5">
                {{ __('account.overview.delete-account-settings.text', {amount: $page.props.auth.member.balance}) }}
              </p>

              <div>
                <label class="w-full">
                  {{ __('account.delete.reason') }}:
                </label>

                <textarea
                  v-model="form.reason"
                  rows="4"
                />
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.password_confirmation') }}:
                </label>

                <Password v-model="form.password">
                  <template #errors>
                    <p
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.password }}
                    </p>
                  </template>
                </Password>
              </div>

              <HiddenRecaptcha
                ref="recaptchaForm"
                :form="form"
                @success="recaptchaSuccess"
              />


              <div class="flex flex-col justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    :disabled="form.processing"
                  >
                    {{ __('account.overview.delete-account-settings.button') }}
                  </Blue>
                </div>
                <span class="block mt-3 text-center text-red-600 font-bold text-sm">
                  {{ __('account.overview.delete-account-settings.warning') }}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../../Components/Form/Password.vue';
import Blue from '../../../Components/Buttons/Blue.vue';
import Breadcrumbs from '../../../Components/Breadcrumbs.vue';
import HiddenRecaptcha from '../../../Components/Form/HiddenRecaptcha.vue';

export default {
  components: {
    Password,
    Blue,
    Breadcrumbs,
    HiddenRecaptcha,
  },

  props: {
    recaptchaSiteKey: {
      type: String,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      reason: null,
      password: '',
      recaptcha: '',
    });

    return {
      form,
    };
  },

  methods: {
    recaptchaSuccess: function (mutatedForm) {
      mutatedForm.post(this.route('frontend.account.delete.store'), {
        onSuccess: () => this.form.reset('password'),
      });
    },
  },
};
</script>

