<template>
  <Head :title="__('welcome.title')" />

  <section class="container mt-3 md:mt-4 mb-6">
    <h1 class="text-2xl md:text-3xl font-bold text-center mb-4 md:mb-2.5">
      {{ __('welcome.title') }}
    </h1>
  </section>

  <section
    v-if="webshops.length > 0"
    class="mb-6 md:mb-12"
  >
    <div class="container">
      <h2 class="text-lg md:text-2xl font-bold mb-6 text-center">
        {{ __('welcome.top_webshops.title', {amount: webshops.length}) }}
      </h2>

      <WebshopSimpleSwiper :webshops="webshops" />

      <div class="flex justify-center">
        <Link
          class="text-sm"
          :href="route('frontend.webshop-category.index')"
        >
          {{ __('welcome.top_webshops.link') }}
        </Link>
      </div>
    </div>
  </section>

  <section class="flex items-center justify-center mb-6 md:mb-12">
    <div class="container max-w-md">
      <p class="text-center mb-4">
        {{ __('welcome.profile-questions.text') }}
      </p>

      <BlueButton :href="route('frontend.profile-questions.index')">
        {{ __('welcome.profile-questions.button') }}
      </BlueButton>
    </div>
  </section>
</template>

<script>
import WebshopSimpleSwiper from '../../Components/WebshopSimpleSwiper.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    WebshopSimpleSwiper,
    BlueButton,
  },

  props: {
    topWebshops: {
      type: Object,
      required: true,
    },

    famousWebshops: {
      type: Object,
      required: true,
    },

    faqItems: {
      type: Array,
      required: true,
    },

    minimumPayoutValue: {
      type: String,
      required: true,
    },

    payoutOptions: {
      type: String,
      required: true,
    },

    webshopCount: {
      type: String,
      required: true,
    },
  },

  computed: {
    webshops() {
      return JSON.parse(JSON.stringify(this.famousWebshops)).splice(0, 5);
    },
  },
};
</script>

