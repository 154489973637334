<template>
  <Modal
    ref="modal"
    :title="__('global.already-participated.title')"
  >
    <template #modal-content="{ closeModal }">
      <div class="w-full">
        {{ __('global.already-participated.text') }}

        <BlueButton
          v-if="url"
          class="mt-4"
          :href="url"
          :internal="false"
          target="_blank"
          @click="closeModal"
        >
          {{ __('global.already-participated.button') }}
        </BlueButton>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import BlueButton from './Buttons/Blue.vue';

export default {
  components: {
    Modal,
    BlueButton,
  },

  setup() {
    return {
      url: null,
    };
  },

  methods: {
    openModal: function (url) {
      this.url = url;
      this.$refs.modal.openModal();
    },
  },
};
</script>
