<template>
  <div class="pt-8">
    <div class="container">
      <div class="bg-white shadow rounded-sm p-4 lg:w-2/5 lg:mx-auto text-cente mb-8">
        <img
          src="/qassa/images/panel-inzicht.png"
          class="h-[50px] mx-auto mb-8"
        >

        <div class="text-2xl font-bold mb-4">
          {{ __(`mailing-survey.response.${statusTranslationKey}.title`) }}
        </div>

        <p
          v-for="(_, i) in $tm(`mailing-survey.response.${statusTranslationKey}.paragraphs`)"
          :key="i"
          class="mb-4"
        >
          <i18n-t
            scope="global"
            :keypath="`mailing-survey.response.${statusTranslationKey}.paragraphs.${i}`"
          >
            <template #survey_name>
              {{ survey.name }}
            </template>
            <template #profile_link>
              <Link
                :href="route('frontend.profile-questions.index')"
              >
                {{ __('mailing-survey.response.profile-link') }}
              </Link>
            </template>
            <template #faq_link>
              <Link
                :href="route('frontend.faq')"
              >
                {{ __('mailing-survey.response.faq-link') }}
              </Link>
            </template>
          </i18n-t>
        </p>

        <p
          v-if="hasTooManyScreenOuts"
          class="mb-4"
        >
          {{ __('mailing-survey.response.screen_out.too_many_screen_outs', {count: maxScreenOuts}) }}
        </p>

        <BlueButton
          :href="route('frontend.index')"
          classes="w-3/4 mx-auto"
        >
          {{ __('mailing-survey.response.button') }}
        </BlueButton>
      </div>
    </div>
  </div>
</template>

<script>
import CleanLayout from '../../../Layout/Clean.vue';
import BlueButton from '../../../Components/Buttons/Blue.vue';

export default {
  components: {
    BlueButton,
  },

  layout: CleanLayout,

  props: {
    status: {
      required: true,
      type: String,
    },

    statusTranslationKey: {
      required: true,
      type: String,
    },

    survey: {
      required: true,
      type: Object,
    },

    hasTooManyScreenOuts: {
      required: true,
      type: Boolean,
    },

    maxScreenOuts: {
      required: true,
      type: Number,
    },
  },
};
</script>
