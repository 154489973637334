<template>
  <template v-if="showMediaType === 'logo_only'">
    <button
      class="flex items-center h-[38px] w-full no-underline"
      @click="goToWebshop()"
    >
      <div
        v-if="webshop.logo"
        class="h-full w-full aspect-webshop-logo bg-no-repeat bg-contain bg-center"
        :style="`background-image: url(${webshop.logo.url})`"
      />
      <div
        v-else
        class="block w-full text-orange text-base md:text-xl text-center truncate"
      >
        {{ webshop.name }}
      </div>
    </button>
  </template>

  <template v-else-if="showMediaType === 'billboard_only'">
    <button
      class="no-underline w-full"
      @click="goToWebshop()"
    >
      <div
        v-if="webshop.billboard"
        class="aspect-[970/250] bg-cover w-full bg-origin-padding bg-center"
        :style="`background-image: url(${webshop.billboard.url})`"
      />
      <div
        v-else
        class="block w-full text-orange text-base md:text-xl text-center truncate"
      >
        {{ webshop.name }}
      </div>
    </button>
  </template>

  <template v-else-if="showMediaType === 'rectangle'">
    <div class="flex flex-col items-center h-full bg-white rounded shadow relative p-2 md:p-4">
      <div
        v-if="canRemoveAsFavorite"
        class="absolute -top-1 -right-1 flex bg-blue rounded-full p-1 text-white cursor-pointer"
        :title="__('webshop.block.delete-favorite')"
        @click.prevent="deleteFavorite"
      >
        <span class="material-symbols-outlined md-16">
          delete
        </span>
      </div>

      <div class="flex-grow flex flex-col items-center space-y-2 md:space-y-4 w-full">
        <template v-if="webshop.logo">
          <button @click="goToWebshop()">
            <img
              :src="webshop.logo.url"
              :alt="webshop.name"
              :title="webshop.name"
              class="h-[38px] aspect-webshop-logo"
            >
          </button>
        </template>
        <template v-else>
          <button
            class="h-[38px] block w-full text-orange text-base md:text-xl text-center"
            @click="goToWebshop()"
          >
            <p class="truncate">
              {{ webshop.name }}
            </p>
          </button>
        </template>

        <div class="flex flex-col justify-center items-center grow text-center text-xs md:text-base">
          <div>
            {{ cashbackText }}
          </div>
          <div
            v-if="webshop.maxCashback.maxCashbackInfo"
            class="text-xs md:text-sm"
          >
            {{ webshop.maxCashback.maxCashbackInfo }}
          </div>
        </div>

        <button
          v-if="webshop.rectangle"
          class="no-underline"
          @click="goToWebshop()"
        >
          <img
            class="aspect-[300/250]"
            :src="webshop.rectangle.url"
            alt=""
          >
        </button>

        <div class="w-full">
          <button
            class="btn-blue-open"
            @click="goToWebshop()"
          >
            {{ __(`advertiser.button.${webshop.buttonType}`) }}

            <span class="material-symbols-outlined -mr-2">
              chevron_right
            </span>
          </button>
        </div>

        <div class="flex justify-between w-full">
          <Link
            class="flex items-center text-xs border border-gray-300 rounded-full py-0.5 px-3 no-underline text-gray-900"
            :href="`${route('frontend.webshop.show', webshop.slug)}#reviews`"
          >
            <span class="material-symbols-outlined fill text-orange mr-1">grade</span>
            {{ webshop.averageReviewRating }}
          </Link>
          <div class="flex space-x-2 text-xs text-blue">
            <div
              class="group flex items-center cursor-pointer"
              @click="toggleFavorite"
            >
              <span
                v-if="savingFavorite"
                class="material-symbols-outlined animate-spin"
              >
                autorenew
              </span>
              <span
                v-else
                class="material-symbols-outlined cursor-pointer"
                :class="{'fill': isFavorite}"
              >
                favorite
              </span>
            </div>

            <Link
              class="group flex items-center no-underline"
              :href="route('frontend.webshop.show', webshop.slug)"
            >
              <span class="material-symbols-outlined group-hover:font-bold">info</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else-if="showMediaType === 'care_price'">
    <div class="col-span-full	flex items-center h-full bg-white rounded shadow p-4">
      <div class="flex justify-center items-start lg:items-center h-full w-1/2 lg:w-1/4 mr-3">
        <template v-if="webshop.logo">
          <button @click="goToWebshop()">
            <img
              :src="webshop.logo.url"
              :alt="webshop.name"
              :title="webshop.name"
              class="h-[38px] aspect-webshop-logo"
            >
          </button>
        </template>
        <template v-else>
          <button
            class="h-[38px] block w-full text-orange text-base md:text-xl text-center"
            @click="goToWebshop()"
          >
            <p class="truncate">
              {{ webshop.name }}
            </p>
          </button>
        </template>
      </div>

      <div class="flex flex-col lg:flex-row w-1/2 lg:w-3/4 lg:justify-between">
        <div class="flex items-center whitespace-pre-wrap lg:w-1/4 text-xs md:text-base mb-1.5 lg:mb-0">
          {{ __('webshop.block.care_premium') }}:
          <span
            v-if="!webshop?.advertiser?.communication_value_money"
            class="font-bold"
          >
            {{ __('webshop.block.variable') }}

          </span>
          <span
            v-else
            class="font-bold"
          >
            {{ webshop.advertiser.communication_value_money }}
          </span>
        </div>
        <div class="flex items-center lg:w-2/4 mb-3 lg:mb-0">
          <div
            v-if="cashbackText"
            class="text-xs md:text-base"
          >
            {{ __('webshop.block.earn') }}: <span class="font-bold">{{ cashbackText }}</span>
          </div>
        </div>
        <div class="flex items-center lg:w-1/4">
          <div class="w-full">
            <BlueSmallButton
              classes="text-sm md:text-base"
              @click="goToWebshop()"
            >
              {{ __(`advertiser.button.${webshop.buttonType}`) }}
            </BlueSmallButton>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else-if="showMediaType === 'care_video'">
    <div class="flex flex-col items-center h-full bg-white rounded shadow p-2 md:p-4">
      <div class="flex-grow flex flex-col items-center space-y-2 md:space-y-4 w-full">
        <template v-if="webshop.logo">
          <button @click="goToWebshop()">
            <img
              :src="webshop.logo.url"
              :alt="webshop.name"
              :title="webshop.name"
              class="h-[38px] aspect-webshop-logo"
            >
          </button>
        </template>
        <template v-else>
          <button
            class="h-[38px] block w-full text-orange text-base md:text-xl text-center"
            @click="goToWebshop()"
          >
            <p class="truncate">
              {{ webshop.name }}
            </p>
          </button>
        </template>

        <div class="flex flex-col items-center grow text-center text-xs md:text-base">
          <div v-if="webshop?.advertiser?.youtube_token">
            <iframe
              class="w-full aspect-video"
              type="text/html"
              :src="`https://www.youtube.com/embed/${webshop.advertiser.youtube_token}?autoplay=0`"
              frameborder="0"
            />
          </div>
        </div>

        <div
          v-if="cashbackText"
          class="flex flex-col justify-center items-center grow text-center text-xs md:text-base"
        >
          <div class="font-bold">
            {{ __('webshop.block.earn') }}:
          </div>
          <div>
            {{ cashbackText }}
          </div>
          <div
            v-if="webshop.maxCashback.maxCashbackInfo"
            class="text-xs md:text-sm"
          >
            {{ webshop.maxCashback.maxCashbackInfo }}
          </div>
        </div>

        <div class="w-full">
          <button
            class="btn-blue-open"
            @click="goToWebshop()"
          >
            {{ __(`advertiser.button.${webshop.buttonType}`) }}

            <span class="material-symbols-outlined -mr-2">
              chevron_right
            </span>
          </button>
        </div>

        <div class="flex justify-between w-full">
          <Link
            class="flex items-center text-xs border border-gray-300 rounded-full py-0.5 px-3 no-underline text-gray-900"
            :href="`${route('frontend.webshop.show', webshop.slug)}#reviews`"
          >
            <span class="material-symbols-outlined fill text-orange mr-1">grade</span>
            {{ webshop.averageReviewRating }}
          </Link>
          <div class="flex space-x-2 text-xs text-blue">
            <div
              class="group flex items-center cursor-pointer"
              @click="toggleFavorite"
            >
              <span
                v-if="savingFavorite"
                class="material-symbols-outlined animate-spin"
              >
                autorenew
              </span>
              <span
                v-else
                class="material-symbols-outlined cursor-pointer"
                :class="{'fill': isFavorite}"
              >
                favorite
              </span>
            </div>

            <Link
              class="group flex items-center no-underline"
              :href="route('frontend.webshop.show', webshop.slug)"
            >
              <span class="material-symbols-outlined group-hover:font-bold">info</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-else>
    <div class="flex flex-col items-center h-full bg-white rounded shadow relative p-2 md:p-4">
      <div
        v-if="canRemoveAsFavorite"
        class="absolute -top-1 -right-1 flex bg-blue rounded-full p-1 text-white cursor-pointer"
        :title="__('webshop.block.delete-favorite')"
        @click.prevent="deleteFavorite"
      >
        <span class="material-symbols-outlined md-16">
          delete
        </span>
      </div>

      <div class="flex-grow flex flex-col items-center space-y-2 md:space-y-4 w-full">
        <template v-if="webshop.logo">
          <button @click="goToWebshop()">
            <img
              :src="webshop.logo.url"
              :alt="webshop.name"
              :title="webshop.name"
              class="h-[38px] aspect-webshop-logo"
            >
          </button>
        </template>
        <template v-else>
          <button
            class="h-[38px] block w-full text-orange text-base md:text-xl text-center"
            @click="goToWebshop()"
          >
            <p class="truncate">
              {{ webshop.name }}
            </p>
          </button>
        </template>

        <div class="flex flex-col justify-center items-center grow text-center text-xs md:text-base">
          <div>
            {{ cashbackText }}
          </div>
          <div
            v-if="webshop.maxCashback.maxCashbackInfo"
            class="text-xs md:text-sm"
          >
            {{ webshop.maxCashback.maxCashbackInfo }}
          </div>
        </div>

        <div class="w-full">
          <button
            class="btn-blue-open"
            @click="goToWebshop()"
          >
            {{ __(`advertiser.button.${webshop.buttonType}`) }}

            <span class="material-symbols-outlined -mr-2">
              chevron_right
            </span>
          </button>
        </div>

        <Link
          class="text-xs text-blue"
          :href="route('frontend.webshop.show', webshop.slug)"
        >
          {{ __('webshop.block.details') }}
        </Link>
      </div>
    </div>
  </template>
</template>

<script>
import axios from 'axios';
import BlueSmallButton from '../../Components/Buttons/BlueSmall.vue';

export default {
  components: {
    BlueSmallButton,
  },

  props: {
    webshop: {
      type: Object,
      required: true,
    },

    canRemoveAsFavorite: {
      type: Boolean,
      required: false,
      default: false,
    },

    showMediaType: {
      type: String,
      required: false,
      default: null,
      validator(type) {
        return ['logo', 'rectangle', 'logo_only', 'billboard', 'billboard_only', 'care_price', 'care_video'].includes(type);
      },
    },
  },

  emits: ['unfavorited'],

  data(props) {
    return {
      savingFavorite: false,
      isFavorite: props.webshop.isFavorite,
    };
  },

  computed: {
    cashbackText() {
      if (!this.webshop.maxCashback.maxCashback || this.webshop.maxCashback.maxCashback.length === 0) {
        return '';
      }

      return this.__cashback(this.webshop.usePoints, this.webshop.maxCashback.hasMultipleCashbacks ? 'cashback.max.multiple' : 'cashback.max.single', {value: this.webshop.maxCashback.maxCashback});
    },
  },

  methods: {
    goToWebshop() {
      if (this.$page.props.auth.member) {
        const clickoutUrl = this.webshop.clickoutUrl ?? this.route('frontend.clickout.webshop', {webshop: this.webshop.slug, origin: 'website'});
        window.open(clickoutUrl, '_blank');
        return;
      }

      this.$parent.$parent.$refs.loginModal.doOpenModal();
    },

    deleteFavorite() {
      if (!this.canRemoveAsFavorite || this.webshop.isFavorite === null) {
        return;
      }

      this.$emit('unfavorited', this.webshop.id);

      axios.post(this.route('frontend.api.webshop.favorite', {webshop: this.webshop.id}), {
        attach: !this.webshop.isFavorite,
      });
    },

    toggleFavorite() {
      if (!this.$page.props.auth.member) {
        this.$parent.$parent.$refs.loginModal.doOpenModal();
        return;
      }

      this.savingFavorite = true;

      axios.post(this.route('frontend.api.webshop.favorite', {webshop: this.webshop.id}), {
        attach: !this.isFavorite,
      })
        .then(() => {
          this.isFavorite = !this.isFavorite;
          this.savingFavorite = false;
        })
        .catch(() => {
          this.savingFavorite = false;
        });
    },
  },
};
</script>
