<template>
  <Head :title="__('account.unlock-member.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"
    :current-page="__('account.unlock-member.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.unlock-member.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/password-settings.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            class="h-full"
            @submit.prevent="form.post(route('frontend.unlock-member.store'), {
              onSuccess: () => form.reset('password'),
            })"
          >
            <div class="flex flex-col">
              <div class="flex flex-col md:flex-row md:items-center">
                <label class="self-start mt-2">
                  {{ __('general.password_confirmation') }}:
                </label>
                <Password v-model="form.password">
                  <template #errors>
                    <p
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.password }}
                    </p>
                  </template>
                </Password>
              </div>

              <div class="flex mb-4">
                <div class="hidden md:block md:w-2/6" />
                <div class="w-full md:w-4/6">
                  <Link
                    :href="route('frontend.password-forgot')"
                    class="text-orange text-sm underline hover:no-underline mb-2"
                  >
                    {{ __('account.unlock-member.forgot-password') }}
                  </Link>
                </div>
              </div>

              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    :disabled="form.processing"
                  >
                    {{ __('account.unlock-member.button') }}
                  </Blue>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../Components/Form/Password.vue';
import Blue from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Password,
    Blue,
  },

  setup() {
    const form = useForm({
      password: '',
    });

    return {
      form,
    };
  },
};
</script>
