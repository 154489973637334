<template>
  <div class="h-full flex flex-col pt-8">
    <div class="grow mb-8">
      <div class="container lg:w-2/5 md:w-3/5">
        <div v-show="isFirstQuestion">
          <div class="mb-6">
            {{ __('mailing-survey.index.introduction-greeting', {name: member.first_name}) }}<br>
            <br>
            {{ __('mailing-survey.index.introduction-text') }}
          </div>
        </div>

        <ProfileQuestionsFactory
          v-model="form.questions[currentQuestion.id]"
          :question="currentQuestion"
          title-classes="text-2xl mb-4"
          @validation-error="validationError(currentQuestion.id, $event)"
        />

        <div
          v-show="validationErrorMessages[currentQuestion.id]"
          class="mt-2 text-orange text-sm text-left"
        >
          *{{ validationErrorMessages[currentQuestion.id] }}
        </div>

        <div class="flex items-center justify-between mt-4">
          <div class="h-fit">
            <button
              v-show="!isFirstQuestion"
              type="button"
              class="text-xs text-blue hover:underline"
              @click="previousStep"
            >
              {{ __('mailing-survey.index.previous-question-button') }}
            </button>
          </div>

          <BlueButton
            classes="w-auto"
            :loading="updating.includes(currentQuestion.id)"
            :disabled="updating.includes(currentQuestion.id) || validationErrorMessages[currentQuestion.id]"
            @click="updateQuestion(currentQuestion.id, form.questions[currentQuestion.id])"
          >
            <span v-show="!isFinalQuestion">
              {{ __('mailing-survey.index.next-question-button') }}
            </span>
            <span v-show="isFinalQuestion">
              {{ __('mailing-survey.index.continue-to-survey-button') }}
            </span>
          </BlueButton>
        </div>
      </div>
    </div>

    <div class="w-full bg-gray-200">
      <div
        class="bg-orange text-xs text-center py-0.5 leading-none"
        :class="progressPercentage > 0 ? 'text-white' : 'text-gray-900'"
        :style="`width: ${progressPercentage}%`"
      >
        {{ Math.floor(progressPercentage) }}%
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {useForm} from '@inertiajs/vue3';
import CleanLayout from '../../../Layout/Clean.vue';
import BlueButton from '../../../Components/Buttons/Blue.vue';
import ProfileQuestionsFactory from '../../../Components/ProfileQuestions/Factory.vue';

export default {
  components: {
    BlueButton, ProfileQuestionsFactory,
  },

  layout: CleanLayout,

  props: {
    clickout: {
      required: true,
      type: String,
    },

    member: {
      required: true,
      type: Object,
    },

    questions: {
      required: true,
      type: Array,
    },
  },

  setup(props) {
    const questions = props.questions.reduce((accumulator, question) => {
      const memberAnswers = question.memberAnswers.map((memberAnswer) => memberAnswer.profile_answer_id);
      let answers = question.answers.filter((answer) => memberAnswers.includes(answer.id)).map((answer) => answer.id);
      accumulator[question.id] = answers || null;
      return accumulator;
    }, {});

    const form = useForm({questions});

    return {
      form,
    };
  },

  data() {
    return {
      currentStep: 0,
      updating: [],
      validationErrorMessages: {},
    };
  },

  computed: {
    isFirstQuestion() {
      return this.currentStep === 0;
    },

    isFinalQuestion() {
      return this.currentStep === (this.questions.length - 1);
    },

    currentQuestion() {
      return this.questions[this.currentStep];
    },

    progressPercentage() {
      if (this.currentStep === 0) {
        return 0;
      }

      const percentage = (this.currentStep / this.questions.length) * 100;
      return percentage > 100 ? 100 : percentage;
    },
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => this.nextStep(questionId));
    },

    previousStep() {
      if (this.isFirstQuestion) {
        return;
      }

      this.currentStep--;
    },

    nextStep(questionId) {
      if (this.isFinalQuestion) {
        return this.$inertia.post(this.route('frontend.mailing.survey.store', [this.clickout]));
      }

      this.currentStep++;
      this.updating.splice(this.updating.indexOf(questionId), 1);
    },
  },
};
</script>
