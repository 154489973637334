<template>
  <div class="mb-4 md:mb-6">
    <section class="container flex flex-col items-center mt-12">
      <h1 class="text-3xl font-bold mb-2 md:mb-4">
        {{ __('faq.title') }}
      </h1>
      <p class="text-base mb-4 md:mb-8">
        {{ __('faq.sub_title') }}
      </p>

      <div class="relative w-full mb-6 max-w-md">
        <div
          class="h-4 md:h-[4.5rem]"
          @focusout="showSearch = false"
        >
          <div class="absolute w-full">
            <div
              :class="showSearch ? 'rounded-3xl' : 'rounded-full'"
            >
              <div class="w-full">
                <form
                  @submit.prevent="submitSearch"
                >
                  <input
                    v-model="searchQuery"
                    class="no-default-style py-0 pl-6 pr-1.5 w-full placeholder-gray-light border-0 focus:ring-0"
                    type="text"
                    name="q"
                    :placeholder="__('faq.search.placeholder')"
                    autocomplete="off"
                    @focus="showSearch = searchedItems.length > 0"
                  >
                </form>
                <div
                  class="absolute inset-y-0 right-2 w-7 h-7 mt-1.5  flex justify-center items-center rounded-full"
                  :class="searchQuery ? 'cursor-pointer' : ''"
                  @click="submitSearch"
                >
                  <span
                    v-if="!searchQuery"
                    class="material-symbols-outlined text-orange"
                  >
                    search</span>
                  <span
                    v-if="searchQuery"
                    class="material-symbols-outlined text-orange"
                  >
                    arrow_right_alt</span>
                </div>
              </div>

              <div
                v-if="showSearch"
                class="bg-white shadow"
              >
                <div
                  class="flex flex-col space-y-4 pt-6 pr-6 pb-4 pl-6 border-t border-blue-200"
                >
                  <a
                    v-for="(item, i) in searchedItems.slice(0, 5)"
                    :key="i"
                    class="hover:underline"
                    :href="route('frontend.faq.item', item.id)"
                    @mousedown="goTo(route('frontend.faq.item', item.id))"
                  >
                    {{ item.question }}
                  </a>
                </div>

                <div
                  v-if="searchedItems.length > 5"
                  class="bg-blue-200 py-3 px-6 overflow-hidden rounded-b-3xl"
                >
                  <Link
                    class="hover:underline"
                    :href="route('frontend.faq.search', {q: searchQuery})"
                    @mousedown="submitSearch"
                  >
                    {{ __('faq.search.show_all', {count: searchedItems.length - 5}) }}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {ref} from 'vue';
import {router} from '@inertiajs/vue3';

export default {
  props: {
    items: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const searchQuery = ref();
    const showSearch = ref(false);
    const searchedItems = [];

    return {searchQuery, showSearch, searchedItems};
  },

  watch: {
    searchQuery(query) {
      this.searchedItems = [];

      this.showSearch = false;
      if (query) {
        this.search(query);
      }
    },
  },

  methods: {
    search(query) {
      this.searchedItems = this.items.filter(item => item.question.toLowerCase().indexOf(query.toLowerCase()) >= 0);

      this.showSearch = this.searchedItems.length > 0;
    },

    goTo(href) {
      router.get(href);
    },

    submitSearch() {
      if (this.searchQuery) {
        this.goTo(this.route('frontend.faq.search', {q: this.searchQuery}));
      }
    },
  },
};
</script>
