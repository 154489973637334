<template>
  <Head :title="`${__('faq.title')} - ${category.name}`" />

  <Breadcrumbs
    :pages="[
      { name: __('faq.title'), href: route('frontend.faq') },
    ]"
    :current-page="category.name"
  />

  <SearchBar :items="searchItems" />

  <section class="container mb-12">
    <div class="flex flex-row md:flex-col flex-row-reverse justify-between bg-white shadow p-4">
      <div class="flex-grow flex flex-col">
        <h2 class="text-lg md:text-xl font-bold mb-1">
          {{ category.name }} ({{ category.items.length }})
        </h2>

        <ul class="container list-disc text-base space-y-3 mb-6">
          <li
            v-for="item in category.items"
            :key="item.id"
          >
            <Link
              :href="route('frontend.faq.item', {item: item.id})"
              class="underline hover:no-underline"
            >
              {{ item.question }}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import SearchBar from './Components/SearchBar.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    SearchBar,
    Breadcrumbs,
  },

  props: {
    searchItems: {
      type: Object,
      required: true,
    },

    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
