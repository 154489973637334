export function pushDataLayer(member) {
  setTimeout(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(function () {
      this.set('time', new Date());
    });

    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': window.location.pathname,
      'pageTitle': window.document.title,
      'visitorType': member ? 'member' : 'guest',
      'memberId': member ? member.id : null,
    });
  });
}

export default {
  directives: {
    'click-outside': {
      beforeMount: (element, binding) => {
        element.clickOutsideEvent = event => {
          if (!(element === event.target || element.contains(event.target))) {
            binding.value();
          }
        };

        document.addEventListener('click', element.clickOutsideEvent);
      },
      unmounted: (element) => {
        document.removeEventListener('click', element.clickOutsideEvent);
      },
    },
  },

  methods: {
    __(key, options) {
      return this.$t(key, options);
    },
    __cashback(usePoints, key, options) {
      if (usePoints && this.$t(`${key}.points`, '__translation_not_found__', {missingWarn: false, fallbackWarn: false}) !== '__translation_not_found__') {
        return this.$t(`${key}.points`, options);
      } else if (!usePoints && this.$t(`${key}.money`, '__translation_not_found__', {missingWarn: false, fallbackWarn: false}) !== '__translation_not_found__') {
        return this.$t(`${key}.money`, options);
      }
      return this.$t(key, options);
    },
    pushDataLayer,
  },
};
