<template>
  <Head :title="__('balance.cashback.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.overview.title'), href: route('frontend.balance') },
    ]"
    :current-page="__('balance.cashback.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('balance.cashback.title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <form
      class="w-full flex md:justify-end"
      @submit.prevent="filter"
    >
      <div class="flex w-1/2 md:w-1/4 mr-2.5">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('balance.filter.search') }}
          </span>
          <input
            v-model="form.q"
            type="text"
            :placeholder="__('balance.filter.search-placeholder')"
            @keyup="filter"
          >
        </label>
      </div>
      <div class="flex w-1/2 md:w-1/4">
        <label class="block w-full">
          <span class="text-2xs text-blue-600 font-bold mb-1.5">
            {{ __('balance.filter.status') }}
          </span>
          <select
            v-model="form.status"
            @change="filter"
          >
            <option :value="null">{{ __('balance.filter.statuses.all') }}</option>
            <option value="pending">{{ __('balance.filter.statuses.pending') }}</option>
            <option value="approved">{{ __('balance.filter.statuses.approved') }}</option>
            <option value="disapproved">{{ __('balance.filter.statuses.disapproved') }}</option>
          </select>
        </label>
      </div>
    </form>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4 divide-solid divide-y">
      <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm font-bold w-full">
        <div class="w-4/12 md:w-8/12">
          {{ __('balance.description') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.register_date') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.qoins') }}
        </div>
      </div>

      <div
        v-if="form.processing"
        class="flex justify-center p-4"
      >
        <span class="material-symbols-outlined animate-spin">autorenew</span>
      </div>

      <template v-else-if="cashbacks.meta.pagination.total > 0">
        <div
          v-for="cashback in cashbacks.data"
          :key="cashback.id"
          class="py-2"
        >
          <div class="flex w-full mb-1 text-sm md:text-base">
            <div class="w-4/12 md:w-8/12">
              {{ cashback.name }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ cashback.date }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ cashback.value }}
            </div>
          </div>
          <div
            class="flex items-center w-full text-xs"
            :class="{
              'text-green-500': cashback.status === 'approved',
              'text-red-500': cashback.status === 'disapproved',
              'text-gray-600': cashback.status === 'pending',
            }"
          >
            <span
              class="h-2 w-2 rounded-full mr-2"
              :class="{
                'bg-green-500': cashback.status === 'approved',
                'bg-red-500': cashback.status === 'disapproved',
                'bg-gray-600': cashback.status === 'pending',
              }"
            />

            {{ __(`balance.cashback.status.${cashback.status}`, {approvedDate: cashback.approvedDate, updatedDate: cashback.updatedDate}) }}
          </div>
        </div>
      </template>

      <p
        v-else
        class="text-sm md:text-base text-center py-2"
      >
        {{ __('balance.cashback.no-data') }}
      </p>

      <div
        v-if="cashbacks.meta.pagination.links.length > 3"
        class="pt-4"
      >
        <Pagination :pagination="cashbacks.meta.pagination" />
      </div>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Faq from './Components/Faq.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Pagination from '../../Components/Pagination.vue';

export default {
  components: {
    Faq,
    Breadcrumbs,
    Pagination,
  },

  props: {
    cashbacks: {
      type: Object,
      required: true,
    },

    filters: {
      type: Object,
      required: true,
    },

    faqItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data(props) {
    return {
      filterTimer: null,
      form: useForm({
        q: props.filters.q ?? null,
        status: props.filters.status ?? null,
      }),
    };
  },

  methods: {
    filter() {
      if (this.filterTimer) {
        clearTimeout(this.filterTimer);
        this.filterTimer = null;
      }
      this.filterTimer = setTimeout(() => {
        this.form.get(this.route('frontend.balance.cashbacks'), {
          preserveScroll: true,
          preserveState: true,
          only: ['cashbacks', 'filters'],
        });
      }, 800);
    },
  },
};
</script>
