<template>
  <Head :title="__('account.change-email.title')" />

  <Breadcrumbs
    :pages="[
      {name: __('account.overview.title'), href: route('frontend.account')},
      {name: __('account.profile.title'), href: route('frontend.account.profile')},
    ]"
    :current-page="__('account.change-email.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.change-email.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <img
              class="inline w-1/2 opacity-20"
              src="/qassa/images/mail.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <form
            class="space-y-4"
            @submit.prevent="form.post(route('frontend.account.change-email.store'), {
              onSuccess: () => form.reset('password'),
            })"
          >
            <div class="flex flex-col md:flex-row md:items-center">
              <label>
                {{ __('general.emailaddress') }}:
              </label>
              <div class="md:w-2/3">
                <input
                  v-model="form.email"
                  type="email"
                  autocomplete="off"
                  required
                >
                <p
                  v-if="form.errors.email"
                  class="text-orange text-xs italic mt-0.5"
                >
                  {{ form.errors.email }}
                </p>
              </div>
            </div>


            <div class="flex flex-col md:flex-row md:items-center place-items-start">
              <label>
                {{ __('general.password') }}:
              </label>

              <Password
                v-model="form.password"
                class="w-full"
              >
                <template #errors>
                  <p
                    v-if="form.errors.password"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.password }}
                  </p>
                </template>
              </Password>
            </div>

            <div class="flex justify-end">
              <div class="w-full md:w-auto">
                <Blue
                  :disabled="form.processing"
                >
                  {{ __('account.change-email.button') }}
                </Blue>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Password from '../../../Components/Form/Password.vue';
import Breadcrumbs from '../../../Components/Breadcrumbs.vue';
import Blue from '../../../Components/Buttons/Blue.vue';

export default {
  components: {
    Password,
    Breadcrumbs,
    Blue,
  },

  setup() {
    const form = useForm({
      email: '',
      password: '',
    });

    return {
      form,
    };
  },
};
</script>
