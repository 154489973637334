<template>
  <Head :title="__('payout.payout-options.bank.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.title'), href: route('frontend.balance') },
      { name: __('payout.overview.title'), href: route('frontend.payout') },
    ]"
    :current-page="__('payout.payout-options.bank.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('payout.payout-options.bank.title') }}
          </h1>
          <div class="hidden lg:flex flex-col">
            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/payout-bank.svg"
            >
          </div>
        </div>

        <div class="md:bg-white md:shadow rounded w-full lg:w-8/12 md:p-6">
          <p v-if="payoutOptions.length === 0">
            {{ __('payout.payout-options.bank.balance_insufficient', {value: payoutSteps}) }}
          </p>
          <form
            v-else
            @submit.prevent="form.post(route('frontend.payout.bank.store'))"
          >
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('payout.payout-options.bank.minimum', {steps: payoutSteps}) }}
                </label>
                <div class="md:w-2/3">
                  <select v-model="form.value">
                    <option
                      disabled
                      :value="null"
                    >
                      - {{ __('payout.payout-options.bank.make_choice') }} -
                    </option>
                    <option
                      v-for="(label, value) in payoutOptions"
                      :key="`payout-${value}`"
                      :value="value"
                    >
                      {{ label }}
                    </option>
                  </select>
                  <p
                    v-if="form.errors.value"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.value }}
                  </p>
                </div>
              </div>


              <div class="flex flex-col md:flex-row md:items-center">
                <label for="bankaccount">
                  <span>{{ __('payout.payout-options.bank.bank_accounts') }} (<Link :href="route('frontend.account.payout')">{{ __('payout.payout-options.bank.edit') }}</Link>)</span>
                </label>

                <div class="md:w-2/3">
                  <select v-model="form.account">
                    <option
                      :value="null"
                    >
                      - {{ __('payout.payout-options.bank.make_choice') }} -
                    </option>
                    <option
                      v-for="(bankAccount, i) in bankAccounts"
                      :key="`bank-account-${i}`"
                      :value="bankAccount.id"
                    >
                      {{ bankAccount.iban }}
                    </option>
                  </select>
                  <p
                    v-if="form.errors.account"
                    class="text-orange text-xs italic mt-0.5"
                  >
                    {{ form.errors.account }}
                  </p>
                </div>
              </div>

              <div class="flex flex-col md:flex-row md:items-center">
                <label>
                  {{ __('general.password_account') }}:
                </label>
                <Password v-model="form.password">
                  <template #errors>
                    <p
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.password }}
                    </p>
                  </template>
                </Password>
              </div>

              <div class="flex justify-end">
                <div class="w-full md:w-auto">
                  <Blue
                    :disabled="form.processing"
                  >
                    {{ __('payout.payout-options.bank.button') }}
                  </Blue>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Password from '../../Components/Form/Password.vue';
import Blue from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Password,
    Breadcrumbs,
    Blue,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    bankAccounts: {
      type: Object,
      required: true,
    },

    minimumPayoutValue: {
      type: String,
      required: true,
    },

    payoutOptions: {
      type: Object,
      required: true,
    },

    payoutSteps: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const form = useForm({
      account: props.bankAccounts[0] ? props.bankAccounts[0].id : null,
      value: null,
      password: '',
    });

    return {
      form,
    };
  },

};
</script>
