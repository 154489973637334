<template>
  <div
    class="no-style block cursor-pointer"
    @click="mailingClick(mailing)"
  >
    <div
      class="container bg-white flex flex-col py-2"
      :class="{ 'border border-orange': mailing.is_boosted }"
    >
      <div class="flex items-center justify-between w-full">
        <div
          class="md:text-lg truncate w-full font-bold"
          :class="{
            'text-orange': mailing.is_boosted,
          }"
        >
          {{ mailing.advertiser }}
        </div>
        <div
          v-if="mailing.is_boosted"
          class="whitespace-nowrap text-orange text-xs md:text-sm"
        >
          {{ __('mailings.mail.topshop-label') }}
        </div>
        <div
          v-else
          class="whitespace-nowrap text-gray-600 text-xs md:text-sm"
        >
          {{ mailing.sent_at }}
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="text-sm md:text-base truncate w-full">
          {{ mailing.subject }}
        </div>
        <div
          v-if="mailing.is_favorite_webshop"
          class="whitespace-nowrap ml-4"
        >
          <div
            class="flex items-center text-2xs text-blue font-bold bg-blue bg-opacity-10 py-0.5 px-2.5 pr-4 rounded"
            :class="{
              'font-bold': !isRead,
            }"
          >
            <img
              class="mr-2"
              src="/qassa/images/heart.svg"
            >
            {{ __('mailings.mail.myshop-label') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    mailing: {
      type: Object,
      required: true,
    },

    isRead: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    mailingClick(mailing) {
      if (mailing.route_outside_platform) {
        this.$parent.mailingSelected = mailing;
        this.$parent.$refs.modal.openModal();

        if (!this.isRead) {
          axios.post(this.route('frontend.api.mailing.is-read', {mailing: mailing.id}))
            .then(() => {
              this.$inertia.reload({
                preserveScroll: true,
                preserveState: true,
                only: ['unreadMailings', 'readMailings'],
              });
            });
        }

        return;
      }

      this.$inertia.get(this.route('frontend.mailings.show', mailing.id));
    },
  },
};
</script>
