<template>
  <section class="mb-6 mt-6 md:mb-12">
    <div class="container flex flex-col justify-center items-center mb-2">
      <h2 class="text-lg md:text-2xl font-bold">
        {{ __('index.guest.latest-earned-cashbacks') }}
      </h2>
    </div>

    <Splide
      :options="options"
      :extensions="extensions"
      :has-track="false"
    >
      <div class="container relative">
        <SplideTrack>
          <SplideSlide
            v-for="(cashback, i) in latestEarnedCashbacks"
            :key="`latest-earned-cashback-${i}`"
            class="flex flex-col justify-center items-center bg-white shadow rounded !my-0.5"
          >
            <button
              class="w-full"
              @click="goToWebshop(cashback.webshop)"
            >
              <div class="flex flex-col justify-center items-center p-2 md:p-4">
                <div class="flex flex-col items-center mb-1.5 md:mb-2.5">
                  <span class="font-bold">
                    {{ cashback.value.split(' ')[0] }}
                  </span>
                  <span class="text-xs md:text-sm">
                    {{ cashback.value.split(' ')[1] }}
                  </span>
                </div>

                <div class="flex flex-col md:flex-row items-center text-xs md:text-sm mb-1.5 md:mb-0">
                  <span>
                    {{ cashback.member.gender }}
                  </span>
                  <span class="hidden md:inline px-0.5">-</span>
                  <span>
                    {{ __('index.latest-earned-cashbacks.age', {age: cashback.member.age}) }}
                  </span>
                </div>

                <div class="md:order-first md:mb-2.5 text-center">
                  <template v-if="cashback.webshop.logo">
                    <img
                      :src="cashback.webshop.logo.url"
                      :alt="cashback.webshop.name"
                      :title="cashback.webshop.name"
                    >
                  </template>
                  <template v-else>
                    {{ cashback.webshop.name }}
                  </template>
                </div>
              </div>
            </button>
          </SplideSlide>
        </SplideTrack>
      </div>
    </Splide>
  </section>
</template>

<script>
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';

export default {

  components: {
    Splide, SplideSlide, SplideTrack,
  },

  props: {
    latestEarnedCashbacks: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    return {
      extensions: {AutoScroll},
      options: {
        arrows: false,
        type: props.latestEarnedCashbacks.length > 6 ? 'loop' : 'slide',
        drag: 'free',
        perPage: 6,
        perMove: 1,
        pagination: false,
        gap: '1rem',
        padding: 2,
        autoScroll: {
          speed: 0.5,
        },

        breakpoints: {
          640: {
            perPage: 3,
            type: props.latestEarnedCashbacks.length > 3 ? 'loop' : 'slide',
          },

          1024: {
            perPage: 4,
            type: props.latestEarnedCashbacks.length > 4 ? 'loop' : 'slide',
          },
        },
      },
    };
  },

  methods: {
    goToWebshop(webshop) {
      if (this.$page.props.auth.member) {
        window.open(this.route('frontend.clickout.webshop', {webshop: webshop.slug, origin: 'website'}), '_blank');
        return;
      }

      this.$parent.$parent.$refs.loginModal.doOpenModal();
    },
  },
};
</script>
