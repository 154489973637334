<template>
  <Head :title="__('passwords.reset-page.title')" />

  <Breadcrumbs
    :current-page="__('passwords.reset-page.title')"
  />

  <section>
    <div class="container mb-6">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-7">
        {{ __('passwords.reset-page.title') }}
      </h1>

      <div class="flex flex-row">
        <div class="md:bg-white md:shadow w-full lg:w-7/12 md:p-6">
          <p v-if="status">
            {{ status }}
            <br>
            <Link
              :href="route('frontend.index')"
              class="text-red underline hover:no-underline"
            >
              {{ __('passwords.reset-page.back-link') }}
            </Link>
          </p>
          <div v-else>
            <p class="mb-4">
              {{ __('passwords.reset-page.text') }}
            </p>

            <form @submit.prevent="form.post(route('frontend.password-reset.store'))">
              <div
                v-if="form.errors.email"
                class="text-orange"
              >
                {{ form.errors.email }}
              </div>

              <div class="flex flex-col space-y-4">
                <div class="flex flex-col md:flex-row md:items-start">
                  <label
                    for="password"
                    class="md:mt-2"
                  >
                    {{ __('general.new_password') }}:
                  </label>
                  <div class="md:w-2/3">
                    <Password v-model="form.password" />

                    <div
                      v-if="form.errors.password"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      <p class="mb-1">
                        {{ __('register.errors.password_requirements') }}
                      </p>
                      <ul class="list-disc space-y-1 ml-4">
                        <li
                          v-for="error in form.errors.password.split('|')"
                          :key="error"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="flex justify-end">
                  <BlueButton
                    :disabled="form.processing"
                  >
                    {{ __('passwords.reset-page.button') }}
                  </BlueButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Password from '../../Components/Form/Password.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Breadcrumbs,
    BlueButton,
    Password,
  },

  props: {
    token: {
      type: String,
      required: true,
    },

    email: {
      type: String,
      required: true,
    },

    status: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const form = useForm({
      token: props.token,
      email: props.email,
      password: '',
    });

    return {form};
  },
};
</script>
