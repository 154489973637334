<template>
  <section
    v-for="(webshopSuggestion, i) in webshopSuggestions"
    :key="`webshop_suggestion_${i}`"
    class="mb-6 md:mb-12"
  >
    <div class="container flex justify-center items-center">
      <template v-if="webshopSuggestion.title">
        <h2 class="text-lg md:text-2xl font-bold mb-2">
          {{ webshopSuggestion.title }}
        </h2>
      </template>
      <Link
        v-if="webshopSuggestion.url"
        :href="route('frontend.faq')"
        class="text-base underline hover:no-underline ml-4"
      >
        {{ __('index.webshop-suggestions.see-all') }}
      </Link>
    </div>

    <WebshopSimpleSwiper
      :webshops="webshopSuggestion.webshops"
      :block-type="webshopSuggestion.block_type"
    />
  </section>
</template>

<script>
import WebshopSimpleSwiper from './WebshopSimpleSwiper.vue';

export default {
  components: {WebshopSimpleSwiper},

  props: {
    webshopSuggestions: {
      type: Object,
      required: true,
    },
  },
};
</script>
