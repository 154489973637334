<template>
  <Head :title="__('balance.title')" />

  <Breadcrumbs
    :current-page="__('balance.title')"
  />

  <section class="mb-6 md:mb-4">
    <div class="container rounded">
      <div class="flex flex-col">
        <div class="flex flex-col lg:w-4/12">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('balance.overview.title') }}
          </h1>
        </div>

        <div class="lg:flex flex-col">
          <p class="text-2xl mb-5">
            {{ $page.props.auth.member.points_balance }} ({{ $page.props.auth.member.money_balance }})
          </p>
        </div>
      </div>

      <div class="w-full bg-white mb-6 rounded-l shadow p-4">
        <div class="flex flex-wrap mb-1 md:text-black text-2xl w-full">
          <span class="w-5/12 font-bold">
            {{ __('balance.overview.table-name') }}
          </span>
        </div>

        <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm w-full">
          <span class="w-7/12 font-bold">
            {{ __('balance.description') }}
          </span>
          <span class="w-5/12 font-bold text-right">
            {{ __('balance.qoins') }}
          </span>
        </div>

        <div
          class="divide-solid divide-y w-full"
        >
          <div
            v-for="item in balances"
            :key="item"
            class="flex py-2"
          >
            <div class="w-7/12 pl-3 md:pl-0">
              <Link
                v-if="item.detailsUrl"
                :href="item.detailsUrl"
              >
                {{ item.description }}
              </Link>
              <div v-else>
                {{ item.description }}
              </div>
            </div>
            <span class="w-5/12 pl-3 text-right">
              {{ item.value }}
            </span>
          </div>

          <div
            v-if="oldBalancePoints !== null"
            class="flex py-2"
          >
            <div class="w-7/12 pl-3 md:pl-0">
              {{ __('balance.overview.old-balance') }}
            </div>
            <span class="w-5/12 pl-3 text-right">
              {{ oldBalancePoints }}
            </span>
          </div>

          <div
            class="flex font-bold py-2"
          >
            <span class="w-7/12 pl-3 md:pl-0">
              {{ __('balance.overview.current-balance') }}
            </span>
            <span class="w-5/12 pl-3 text-right">
              {{ $page.props.auth.member.points_balance_points }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mb-2 md:mb-4">
    <div class="container">
      <div class="flex flex-row items-center w-full">
        <BlueButton
          :href="route('frontend.payout')"
        >
          {{ __('balance.overview.redeem-balance') }}
        </BlueButton>
      </div>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script>
import {ref} from 'vue';
import Faq from './Components/Faq.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Faq,
    Breadcrumbs,
    BlueButton,
  },

  props: {
    member: {
      type: Object,
      required: true,
    },

    balances: {
      type: Object,
      required: true,
    },

    oldBalancePoints: {
      type: String,
      required: false,
      default: null,
    },

    faqItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup() {
    return {
      payoutDetailsOpen: ref(false),
      selectedPayout: ref(null),
    };
  },
};
</script>

