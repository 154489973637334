<template>
  <Head :title="__('balance.games.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('balance.overview.title'), href: route('frontend.balance') },
    ]"
    :current-page="__('balance.games.title')"
  />

  <section class="container mb-6 md:mb-4">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('balance.games.title') }}
    </h1>
  </section>

  <section class="container mb-6">
    <div class="w-full bg-white mb-6 rounded-l shadow p-4 divide-solid divide-y">
      <div class="flex flex-wrap mt-2 mb-2 md:text-black text-xs md:text-sm font-bold w-full">
        <div class="w-4/12 md:w-8/12">
          {{ __('balance.description') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.date') }}
        </div>
        <div class="w-4/12 md:w-2/12 pl-3 text-right">
          {{ __('balance.qoins') }}
        </div>
      </div>

      <template v-if="games.meta.pagination.total > 0">
        <div
          v-for="game in games.data"
          :key="game.id"
          class="py-2"
        >
          <div class="flex w-full mb-1 text-sm md:text-base">
            <div class="w-4/12 md:w-8/12">
              {{ game.name }}

              <template v-if="game.scratch_numbers">
                -
                <ScratchNumbersModal
                  :title="__('balance.games.scratch-numbers-title', {date: game.date})"
                  :numbers="game.scratch_numbers"
                />
              </template>
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ game.date }}
            </div>
            <div class="w-4/12 md:w-2/12 pl-3 text-right">
              {{ game.value }}
            </div>
          </div>
        </div>
      </template>

      <p
        v-else
        class="text-sm md:text-base text-center py-2"
      >
        {{ __('balance.games.no-data') }}
      </p>

      <div
        v-if="games.meta.pagination.links.length > 3"
        class="pt-4"
      >
        <Pagination :pagination="games.meta.pagination" />
      </div>
    </div>
  </section>

  <Faq :faq-items="faqItems" />
</template>

<script setup>
import {defineProps, defineAsyncComponent} from 'vue';
import Faq from './Components/Faq.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import Pagination from '../../Components/Pagination.vue';
const ScratchNumbersModal = defineAsyncComponent(() => import('./Components/ScratchNumbersModal.vue'));

defineProps({
  games: {
    type: Object,
    required: true,
  },

  faqItems: {
    type: Array,
    required: false,
    default: () => [],
  },
});
</script>
