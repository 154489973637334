<template>
  <GameIntro
    :game="game"
    :games-url="gamesUrl"
  />

  <section class="container grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 md:mb-12">
    <div class="bg-white shadow rounded w-full p-4">
      <h2 class="font-bold mb-4">
        {{ __('games.show.scratch-winners.title') }}
      </h2>

      <div class="flex flex-wrap mt-4 mb-2 text-xs md:text-sm">
        <span class="w-3/12 font-bold">
          {{ __('games.show.winners.date') }}
        </span>
        <span class="w-5/12 font-bold pl-3">
          {{ __('games.show.scratch-winners.amount') }}
        </span>
        <span class="w-4/12 font-bold pl-3">
          {{ __('games.show.winners.name') }}
        </span>
      </div>

      <div class="divide-solid divide-y divide-gray-300 mb-3 md:mb-6 md:border-t md:border-gray-300">
        <template v-if="winners.length > 0">
          <div
            v-for="(highscore, i) in itemsForWinnersPage"
            :key="`highscore-${i}`"
            class="flex flex-wrap py-2"
          >
            <span class="w-3/12 font-bold text-xs leading-5 md:text-base">
              {{ highscore.date }}
            </span>
            <span class="w-5/12 text-xs leading-5 md:text-base pl-3">
              {{ highscore.scratch_prize }}
            </span>
            <span class="w-4/12 text-xs leading-5 md:text-base pl-3 truncate">
              {{ highscore.username }}
            </span>
          </div>
        </template>

        <div
          v-else
          class="flex flex-wrap py-2"
        >
          <span class="w-full text-center text-xs leading-5 md:text-base">
            {{ __('games.show.scratch-winners.no-results') }}
          </span>
        </div>
      </div>

      <DynamicPagination
        v-if="winnersNeedsPagination"
        :total-pages="totalHighscorePages"
        @paginate="(newPage) => winnersPage = newPage"
      />
    </div>

    <div class="bg-white shadow rounded w-full p-4">
      <h2 class="font-bold mb-4">
        {{ __('games.show.my-wins.title') }}
      </h2>

      <div class="flex flex-wrap mt-4 mb-2 text-xs md:text-sm">
        <span class="w-6/12 font-bold">
          {{ __('games.show.member-sessions.date') }}
        </span>
        <span class="w-6/12 font-bold pl-3">
          {{ __('games.show.my-wins.amount') }}
        </span>
      </div>

      <div class="divide-solid divide-y divide-gray-300 mb-3 md:mb-6 md:border-t md:border-gray-300">
        <template v-if="myWins.length > 0">
          <div
            v-for="(session, i) in itemsForMyWinsPage"
            :key="`session-${i}`"
            class="flex flex-wrap py-2"
          >
            <span class="w-6/12 text-xs font-bold leading-5 md:text-base">
              {{ session.date }}
            </span>
            <span class="w-6/12 text-xs leading-5 md:text-base pl-3 truncate">
              {{ session.scratch_prize }}

              <template v-if="session.scratch_numbers">
                -
                <ScratchNumbersModal
                  :title="__('balance.games.scratch-numbers-title', {date: session.date})"
                  :numbers="session.scratch_numbers"
                />
              </template>
            </span>
          </div>
        </template>

        <div
          v-else
          class="flex flex-wrap py-2"
        >
          <span class="w-full text-center text-xs leading-5 md:text-base">
            {{ __('games.show.my-wins.no-results') }}
          </span>
        </div>
      </div>

      <DynamicPagination
        v-if="myWinsNeedsPagination"
        :total-pages="totalMyWinPages"
        @paginate="(newPage) => myWinsPage = newPage"
      />
    </div>
  </section>
</template>

<script>
import GameIntro from './Components/Intro.vue';
import DynamicPagination from '../../../Components/DynamicPagination.vue';
import ScratchNumbersModal from '../../Balance/Components/ScratchNumbersModal.vue';

export default {
  components: {
    GameIntro, DynamicPagination, ScratchNumbersModal,
  },

  props: {
    game: {
      type: Object,
      required: true,
    },

    gamesUrl: {
      type: String,
      required: true,
    },

    winners: {
      type: Array,
      required: true,
    },

    myWins: {
      type: Array,
      required: true,
    },
  },

  data() {
    const perPage = 5;
    const totalHighscorePages = Math.ceil(this.winners.length / perPage);
    const totalMyWinPages = Math.ceil(this.myWins.length / perPage);

    return {
      winnersPage: 0,
      myWinsPage: 0,
      perPage,
      totalHighscorePages,
      totalMyWinPages,
      winnersNeedsPagination: totalHighscorePages > 1,
      myWinsNeedsPagination: totalMyWinPages > 1,
    };
  },

  computed: {
    itemsForWinnersPage() {
      const start = this.winnersPage * this.perPage;
      const end = start + this.perPage;

      return this.winners.slice(start, end);
    },

    itemsForMyWinsPage() {
      const start = this.myWinsPage * this.perPage;
      const end = start + this.perPage;

      return this.myWins.slice(start, end);
    },
  },
};
</script>
