<template>
  <Head :title="`${__('faq.title')} - ${__('faq.search.title')}`" />

  <Breadcrumbs
    :pages="[
      { name: __('faq.title'), href: route('frontend.faq') },
    ]"
    :current-page="__('faq.search.title')"
  />

  <SearchBar :items="searchItems" />

  <section class="container mb-12">
    <h2 class="text-2xl font-bold mb-2">
      {{ __('faq.search.title') }} ({{ items.length }})
    </h2>

    <div class="space-y-6">
      <Pagination
        :items="items"
        :per-page="5"
        :previous-text="__('pagination.previous')"
        :next-text="__('pagination.next')"
      >
        <template #item="scope">
          <div>
            <Link
              :href="scope.item.route"
              class="underline hover:no-underline"
            >
              {{ scope.item.question }}
            </Link>

            <div class="text-xs text-gray-light mt-1">
              {{ __('faq.category') }}: {{ scope.item.category }}
            </div>
          </div>
        </template>
      </Pagination>
    </div>
  </section>
</template>

<script>
import SearchBar from './Components/SearchBar.vue';
import Pagination from './Components/Pagination.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';

export default {
  components: {
    SearchBar,
    Pagination,
    Breadcrumbs,
  },

  props: {
    searchItems: {
      type: Object,
      required: true,
    },

    items: {
      type: Object,
      required: true,
    },
  },
};
</script>
