<template>
  <section class="bg-white md:bg-gray-100 pb-7 md:pb-0 pt-6 md:pt-12 mb-5 md:mb-12">
    <div class="container flex flex-col space-y-6 items-center">
      <h1 class="text-2xl font-bold">
        {{ __('index.guest.title') }}
      </h1>

      <div class="flex flex-col justify-center items-center w-full">
        <video
          controls
          poster="/qassa/images/video-poster.jpg"
          class="w-full md:w-7/12"
        >
          <source
            :src="`/qassa/videos/explain-${$page.props.language}.webm`"
            type="video/webm"
          >
          <source
            :src="`/qassa/videos/explain-${$page.props.language}.mp4`"
            type="video/mp4"
          >
        </video>
      </div>

      <div class="flex flex-col items-center w-full">
        <div class="md:w-auto text-center">
          <BlueButton :href="route('frontend.register')">
            {{ __('index.guest.register') }}
          </BlueButton>

          <Link
            :href="route('frontend.how-it-works')"
            class="block mt-4 text-base"
          >
            {{ __('index.guest.how-it-works-link') }}
          </Link>
        </div>
      </div>
    </div>
  </section>

  <LastEarnedCashbackSwiper :latest-earned-cashbacks="latestEarnedCashbacks" />

  <section
    v-if="famousWebshops.length > 0"
    class="container mb-6 md:mb-12"
  >
    <div class="flex justify-center items-center">
      <h2 class="text-lg md:text-2xl font-bold mb-6">
        {{ __('index.guest.top-shops', {count: famousWebshopsLimited.length}) }}
      </h2>
    </div>

    <div class="grid gap-2.5 md:gap-8 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      <template
        v-for="webshop in famousWebshopsLimited"
        :key="`famous-webshop-${webshop.slug}`"
      >
        <WebshopBlock :webshop="webshop" />
      </template>
    </div>
  </section>
</template>

<script>
import BlueButton from '../../Components/Buttons/Blue.vue';
import WebshopBlock from '../../Components/Webshop/Block.vue';
import LastEarnedCashbackSwiper from '../../Components/LastEarnedCashbackSwiper.vue';

export default {
  components: {
    WebshopBlock,
    BlueButton,
    LastEarnedCashbackSwiper,
  },

  props: {
    famousWebshops: {
      type: Object,
      required: true,
    },

    summedDonePayoutsAmount: {
      type: String,
      required: true,
    },

    startingBalance: {
      type: String,
      required: true,
    },

    tellAFriendBonus: {
      type: String,
      required: true,
    },

    emailClickValue: {
      type: String,
      required: true,
    },

    minimumPayoutValue: {
      type: String,
      required: true,
    },

    latestEarnedCashbacks: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    return {
      famousWebshopsLimited: props.famousWebshops.slice(0,10),
    };
  },
};
</script>
