/* https://fengyuanchen.github.io/cropperjs/examples/crop-a-round-image.html */
export function getCanvasResultForCroppedCanvas(croppedCanvas) {
  if (!croppedCanvas) {
    return;
  }

  const width = croppedCanvas.width;
  const height = croppedCanvas.height;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const context = canvas.getContext('2d');
  context.imageSmoothingEnabled = true;
  context.drawImage(croppedCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();

  return canvas;
}
