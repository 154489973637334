<template>
  <Head :title="__('content.how-it-works.title')" />

  <Breadcrumbs
    :current-page="__('content.how-it-works.title')"
  />

  <section class="container my-6 md:my-12">
    <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
      {{ __('content.how-it-works.title') }}
    </h1>

    <p class="mb-4">
      {{ __('content.how-it-works.video-explanation') }}
    </p>

    <video
      controls
      poster="/qassa/images/video-poster.jpg"
      class="w-full md:w-7/12 mb-6"
    >
      <source
        :src="`/qassa/videos/explain-${$page.props.language}.webm`"
        type="video/webm"
      >
      <source
        :src="`/qassa/videos/explain-${$page.props.language}.mp4`"
        type="video/mp4"
      >
    </video>

    <h2 class="font-bold text-lg mb-2">
      {{ __('content.how-it-works.content.title') }}
    </h2>
    <p class="mb-6">
      {{ __('content.how-it-works.content.text') }}
    </p>

    <div class="mb-6">
      <Splide
        :options="options"
        :has-track="false"
      >
        <div class="relative">
          <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev absolute z-10 -left-2 lg:-left-8 top-1/2 -translate-y-1/2 flex justify-center items-center h-8 w-8 bg-white shadow rounded-full cursor-pointer">
              <span class="material-symbols-outlined font-bold">
                chevron_left
              </span>
            </button>
            <button class="splide__arrow splide__arrow--next absolute z-10 -right-2 lg:-right-8 top-1/2 -translate-y-1/2 flex justify-center items-center h-8 w-8 bg-white shadow rounded-full cursor-pointer">
              <span class="material-symbols-outlined font-bold pl-0.5">
                chevron_right
              </span>
            </button>
          </div>

          <SplideTrack>
            <SplideSlide
              v-for="(slide, i) in $tm('content.how-it-works.slides')"
              :key="`slide-${i}`"
              class="flex flex-col justify-center items-center !my-0.5"
            >
              <div class="flex flex-col justify-center items-center text-center bg-white rounded shadow relative p-6 h-full w-full">
                <img
                  :src="`/qassa/images/how-it-works-${i}.svg`"
                  class="mb-4"
                >
                {{ $rt(slide) }}
              </div>
            </SplideSlide>
          </SplideTrack>
        </div>
      </Splide>
    </div>

    <h2 class="font-bold text-lg mb-2">
      {{ __('content.how-it-works.in-brief.title') }}
    </h2>
    <ul class="list-disc list-inside text-orange mb-6">
      <li
        v-for="(item, i) in $tm('content.how-it-works.in-brief.list')"
        :key="`in-brief-${i}`"
      >
        <span class="text-black">
          {{ $rt(item, { 'webshop_count': webshopsCount }) }}
        </span>
      </li>
    </ul>

    <h2 class="font-bold text-lg">
      {{ __('content.how-it-works.extra-information.title') }}
    </h2>
    <p class="mb-2">
      {{ __('content.how-it-works.extra-information.subtitle') }}
    </p>
    <ul class="list-disc list-inside text-orange mb-6">
      <li
        v-for="(item, i) in $tm('content.how-it-works.extra-information.list')"
        :key="`extra-information-${i}`"
      >
        <span class="text-black">
          {{ $rt(item, { 'webshop_count': webshopsCount }) }}
        </span>
      </li>
    </ul>

    <p>
      {{ __('content.how-it-works.outro') }}
    </p>
  </section>
</template>

<script>
import {Splide, SplideSlide, SplideTrack} from '@splidejs/vue-splide';

export default {
  components: {
    Splide, SplideSlide, SplideTrack,
  },

  props: {
    webshopsCount: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      options: {
        gap: '1rem',
        autoHeight: true,
        pagination: false,
        padding: { left: 10, right: 2 },
        rewind: true,
        mediaQuery: 'min',
        breakpoints: {
          640: {
            perPage: 1,
            arrows: true,
          },

          1024: {
            arrows: false,
            perPage: 4,
          },
        },
      },
    };
  },
};
</script>
