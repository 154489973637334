<template>
  <div class="flex flex-wrap mb-3 items-center">
    <VueRecaptcha
      ref="recaptcha"
      :sitekey="$page.props.recaptchaSiteKey"
      size="invisible"
      @verify="onCaptchaVerified"
      @expired="resetCaptcha"
    />

    <p
      v-if="form.errors.recaptcha"
      class="text-red text-xs italic"
    >
      {{ form.errors.recaptcha }}
    </p>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import {ref} from 'vue';

export default {
  components: {
    VueRecaptcha,
  },

  props: {
    form: {
      type: Object,
      required: true,
    },
  },

  emits: ['success'],

  data() {
    return {
      mutatedForm: ref(this.form),
    };
  },

  methods: {
    FormSubmit() {
      this.$refs.recaptcha.execute();
    },

    onCaptchaVerified(token) {
      this.resetCaptcha();
      this.mutatedForm.recaptcha = token;
      this.$emit('success', this.mutatedForm);
    },

    resetCaptcha() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

