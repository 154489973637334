<template>
  <Head :title="__('care-tip.create.title')" />

  <Breadcrumbs
    :pages="[
      {name: __('care-tip.index.title'), href: route('frontend.care-tips.index')},
    ]"
    :current-page="__('care-tip.create.title')"
  />

  <section class="container flex flex-col mb-6">
    <div class="flex flex-col text-center">
      <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
        {{ __('care-tip.create.title') }}
      </h1>
    </div>
    <div class="flex flex-col justify-center items-center w-full">
      <form
        class="w-full lg:w-3/5"
        @submit.prevent="form.post(route('frontend.care-tips.store'))"
      >
        <div class="mb-4">
          <label class="w-full text-sm block mb-1 md:mb-0 md:mt-2 md:font-bold">
            {{ __('care-tip.create.tip') }}:
          </label>
          <div class="w-full">
            <textarea
              v-model="form.text"
              class="h-48"
              maxlength="140"
              required
            />
          </div>
          <p
            v-if="form.errors.text"
            class="text-orange text-xs italic"
          >
            {{ form.errors.text }}
          </p>
          <div class="w-full text-center">
            {{ __('care-tip.create.max') }}:
          </div>
        </div>

        <div class="w-full md:w-auto">
          <BlueButton :disabled="form.processing">
            {{ __('care-tip.create.button') }}
          </BlueButton>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    Breadcrumbs,
    BlueButton,
  },

  setup() {
    const form = useForm({
      text: '',
    });

    return {
      form,
    };
  },
};
</script>

