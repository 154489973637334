<template>
  <Head :title="__('profile-questions.open.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('profile-questions.index.title'), href: route('frontend.profile-questions.index') },
    ]"
    :current-page="__('profile-questions.open.title')"
  />

  <section class="container md:w-3/4 lg:w-1/2 flex flex-col mb-6">
    <div class="flex flex-col">
      <h1 class="text-2xl md:text-3xl font-bold">
        {{ __('profile-questions.open.title') }}
      </h1>
    </div>
  </section>

  <section class="container md:w-3/4 lg:w-1/2 flex flex-col mb-6 space-y-4">
    <div
      v-if="questions.length === 0"
      class="p-4"
    >
      <p class="text-sm md:text-base text-center py-2">
        {{ __('profile-questions.open.no-data') }}
      </p>
    </div>
    <div v-else-if="activeQuestion">
      <ProfileQuestionsFactory
        v-model="memberAnswers[activeQuestion.id]"
        :question="activeQuestion"
        @validation-error="validationError(activeQuestion.id, $event)"
      />

      <div
        v-show="validationErrorMessages[activeQuestion.id]"
        class="mt-2 text-orange text-sm"
      >
        *{{ validationErrorMessages[activeQuestion.id] }}
      </div>

      <div class="flex items-center justify-between mt-4">
        <div>
          <div
            v-show="activeQuestionKey > 0"
            class="text-xs text-blue hover:underline cursor-pointer"
            @click="activeQuestionKey--"
          >
            {{ __('profile-questions.questions.previous') }}
          </div>
        </div>
        <div>
          <BlueButton
            :classes="memberAnswers[activeQuestion.id].length === 0 ? 'w-auto opacity-40' : 'w-auto'"
            :disabled="memberAnswers[activeQuestion.id].length === 0 || updating.includes(activeQuestion.id) || validationErrorMessages[activeQuestion.id]"
            :loading="updating.includes(activeQuestion.id)"
            @click="updateQuestion(activeQuestion.id, memberAnswers[activeQuestion.id])"
          >
            {{ __('profile-questions.questions.next') }}
          </BlueButton>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-4"
    >
      <p class="text-sm md:text-base text-center py-2">
        {{ __('profile-questions.open.done') }}
      </p>

      <div
        class="text-blue hover:underline cursor-pointer"
        @click="activeQuestionKey--"
      >
        {{ __('profile-questions.questions.previous') }}
      </div>
    </div>
  </section>

  <section
    v-if="questions.length > 0"
    class="md:container md:w-3/4 lg:w-1/2 flex flex-col mb-6"
  >
    <div class="w-full bg-gray-200">
      <div
        class="bg-orange text-2xs text-center py-1.5 leading-none"
        :class="progressPercentage > 0 ? 'text-white' : 'text-gray-900'"
        :style="`width: ${progressPercentage}%`"
      >
        {{ Math.round(progressPercentage) }}%
      </div>
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';
import axios from 'axios';
import ProfileQuestionsFactory from '../../Components/ProfileQuestions/Factory.vue';
import Breadcrumbs from '../../Components/Breadcrumbs.vue';
import BlueButton from '../../Components/Buttons/Blue.vue';

export default {
  components: {
    ProfileQuestionsFactory,
    Breadcrumbs,
    BlueButton,
  },

  props: {
    questions: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    let memberAnswers = [];
    props.questions.forEach(question => {
      memberAnswers[question.id] = [];
    });

    return {
      activeQuestionKey: ref(0),
      memberAnswers: ref(memberAnswers),
      updating: ref([]),
      validationErrorMessages: ref({}),
    };
  },

  computed: {
    activeQuestion() {
      return this.questions[this.activeQuestionKey] ?? null;
    },

    progressPercentage() {
      if (this.activeQuestionKey === 0) {
        return 0;
      }

      const percentage = (this.activeQuestionKey / this.questions.length) * 100;

      return percentage > 100 ? 100 : percentage;
    },
  },

  methods: {
    validationError(questionId, message) {
      this.validationErrorMessages[questionId] = message;
    },

    updateQuestion(questionId, answers) {
      this.updating.push(questionId);

      axios.post(this.route('frontend.api.profile-questions.update-question-answer', {profileQuestion: questionId}), {
        answers: answers,
      })
        .then(() => {
          this.activeQuestionKey++;
          this.updating.splice(this.updating.indexOf(questionId), 1);
        });
    },
  },
};
</script>

